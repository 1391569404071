import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem, MenuItemCommandEvent } from 'primeng/api';

import { GlobalsService, isAssigned } from './data';

import { ThemeService } from './theme.service';

@Component({
    selector: 'app-without-menu',
    templateUrl: './app-without-menu.component.html',
    host: {
        class: 'flex flex-column overflow-hidden h-full',
    },
})
export class AppWithoutMenuComponent implements OnInit {
    endItems!: MenuItem[];
    isDarkMode: boolean;

    constructor(private readonly _globalsSrv: GlobalsService, private readonly _themeSrv: ThemeService, private readonly _router: Router) {
        this.isDarkMode = _themeSrv.isDarkMode;
    }

    ngOnInit() {
        this._themeSrv.switchTheme(this.isDarkMode);

        this.loadMenuItems();
    }

    loadMenuItems() {
        this.endItems = [
            // { label: this.userConnected.name, icon: 'pi pi-fw pi-user-edit' },
            this.languagesItems,
            { icon: 'pi pi-fw pi-sun', command: () => this.toggleDarkMode(), visible: this.isDarkMode },
            { icon: 'pi pi-fw pi-moon', command: () => this.toggleDarkMode(), visible: !this.isDarkMode },
        ];
    }

    private setLanguage(lang: string) {
        this._globalsSrv.language = lang;
        this.loadMenuItems();
    }

    get languagesItems(): MenuItem {
        const lang = this._globalsSrv.language;
        return {
            label: lang.toUpperCase(),
            items: [
                {
                    label: 'English',
                    disabled: lang === 'en',
                    command: () => this.setLanguage('en'),
                },
                {
                    label: 'Español',
                    disabled: lang === 'es',
                    command: () => this.setLanguage('es'),
                },
                {
                    label: 'Português',
                    disabled: lang === 'pt',
                    command: () => this.setLanguage('pt'),
                },
                // {
                //     label: 'Français',
                //     disabled: lang === 'fr',
                //     command: () => this.setLanguage('fr'),
                // },
            ],
        };
    }

    toggleDarkMode() {
        this.isDarkMode = !this.isDarkMode;

        this._themeSrv.switchTheme(this.isDarkMode);
        this.loadMenuItems();
    }

    onStartMenuClick($e: MenuItemCommandEvent, routerLink: any) {
        if (isAssigned($e.item)) {
            $e.item!.styleClass = 'idb-menuitem-active';
            this._router.navigate(routerLink);
        }
    }
}
