import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import {
    EditorBaseComponent,
    ChangedEvent,
    MetaService,
    UserAccountInformationDTO,
    UserContextRoleDTO,
    UserContextRoleInformationDTO,
    isAssigned,
} from '../../data';
import { cloneDeep } from 'lodash-es';

export interface ExternalInfoChangedEvent extends ChangedEvent {}

const ComponentName = 'user-context-role-external-info-editor';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: `./user-context-role-external-info-editor.component.html`,
    styleUrls: [`./user-context-role-external-info-editor.component.scss`],
    host: {
        class: 'grid gap-0',
    },
})
export class UserContextRoleExternalInfoEditorComponent extends EditorBaseComponent {
    @Input()
    isNewUser = false;

    @Input()
    canEdit = false;
    get cannotEdit(): boolean {
        return !this.canEdit;
    }

    @Input()
    accountInfo: UserAccountInformationDTO = {};

    private _contextRole: UserContextRoleDTO = { userRoleContextId: -1 };
    private _contextRoleO: UserContextRoleDTO = { userRoleContextId: -1 };
    @Input()
    set contextRole(cr: UserContextRoleDTO | undefined) {
        this._contextRole = cr ?? { userRoleContextId: -1 };
        this._contextRoleO = cloneDeep(this._contextRole);
        this.emitOnChanged();
    }
    get contextRole(): UserContextRoleDTO {
        return this._contextRole;
    }

    get contextRoleInfo(): UserContextRoleInformationDTO {
        return this._contextRole.information ?? { userRoleContextId: this._contextRole.userRoleContextId };
    }

    constructor(translateSrv: TranslateService, toastr: ToastrService, metaSrv: MetaService, location: Location) {
        super(translateSrv, toastr, metaSrv, location);

        this._translateKey = ComponentName;
    }

    get isFirstNameValid(): boolean {
        return isAssigned(this._contextRole.information?.firstName) && this._contextRole.information?.firstName !== '';
    }

    get isMiddleNameValid(): boolean {
        return true;
    }

    get isLastNameValid(): boolean {
        return isAssigned(this._contextRole.information?.lastName) && this._contextRole.information?.lastName !== '';
    }

    get isPrimaryPhoneValid(): boolean {
        return true;
    }

    get isMobilePhoneValid(): boolean {
        return true;
    }

    get isFaxValid(): boolean {
        return true;
    }

    override get isValid(): boolean {
        return (
            super.isValid &&
            this.isFirstNameValid &&
            this.isMiddleNameValid &&
            this.isLastNameValid &&
            this.isPrimaryPhoneValid &&
            this.isMobilePhoneValid &&
            this.isFaxValid
        );
    }

    override get hasChanged(): boolean {
        return (
            super.hasChanged ||
            this._contextRole.information?.firstName !== this._contextRoleO.information?.firstName ||
            this._contextRole.information?.middleName !== this._contextRoleO.information?.middleName ||
            this._contextRole.information?.lastName !== this._contextRoleO.information?.lastName ||
            this._contextRole.information?.primaryPhone !== this._contextRoleO.information?.primaryPhone ||
            this._contextRole.information?.mobilePhone !== this._contextRoleO.information?.mobilePhone ||
            this._contextRole.information?.fax !== this._contextRoleO.information?.fax
        );
    }
}
