// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .idb-history-table {
  height: 50rem;
  max-height: 50rem;
}
::ng-deep .idb-history-table > idb-data-table > p-table table {
  min-width: 20rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user-history/user-history-view-dialog.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,iBAAA;AAAR;AAIgB;EACI,2BAAA;AAFpB","sourcesContent":["::ng-deep {\r\n    .idb-history-table {\r\n        height: 50rem;\r\n        max-height: 50rem;\r\n\r\n        > idb-data-table {\r\n            > p-table {\r\n                table {\r\n                    min-width: 20rem !important;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
