import { clone, cloneDeep } from 'lodash-es';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import {
    ReadContextRoleScopeRequestDTO,
    ReadContextRoleScopeResponseDTO,
    ReadUserContextRoleInternalRequestRequestDTO,
    ReadUserContextRoleInternalRequestResponseDTO,
    ReadUserRequestDTO,
    ReadUserResponseDTO,
    ResendUserContextRoleRequestRequestDTO,
    ResendUserContextRoleRequestResponseDTO,
    ResponseDTO,
    SaveUserContextRoleRequestDTO,
    SaveUserContextRoleExternalRequestRequestDTO,
    SaveUserContextRoleExternalRequestResponseDTO,
    SaveUserContextRoleResponseDTO,
    TypeScopeDTO,
    UserAccountInformationDTO,
    UserContextRoleDTO,
    UserContextRoleScopeDTO,
    UserContextRoleScopeFieldDTO,
    ReadUserContextRoleExternalRequestResponseDTO,
    ReadUserContextRoleExternalRequestRequestDTO,
    SaveUserContextRoleInternalRequestResponseDTO,
    SaveUserContextRoleInternalRequestRequestDTO,
    SaveUserContextRoleProfileRequestDTO,
    SaveUserContextRoleProfileResponseDTO,
} from './dtos';
import { AnyKey } from './view';
import { ContextRoleService, GlobalsService, UnknownErrorCallingAPIMessage, UsersService } from './services';
import { Observable } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

export declare type Nullable<T = void> = T | null | undefined;

export type IDBTableStyleType =
    | {
          [klass: string]: any;
      }
    | null
    | undefined;
export const IDBTableStyle: IDBTableStyleType = { 'min-width': '50rem', 'table-layout': 'fixed' };

export type IDBGetStringType = (item: any) => string;
export const IDBGetString: IDBGetStringType = (item: any) => '';

export interface ChangedEvent {
    hasChanged: boolean;
    isValidated: boolean;
}

export function isAssigned(value: any): boolean {
    return value !== undefined && value !== null;
}

export function isEmpty(val: string | undefined): boolean {
    return !isAssigned(val) || val === '';
}

export function blobToString(blob: Blob): string {
    const url = URL.createObjectURL(blob);
    const xmlRequest = new XMLHttpRequest();
    xmlRequest.open('GET', url, false);
    xmlRequest.send();
    URL.revokeObjectURL(url);
    return xmlRequest.responseText;
}

export const DefaultDateFormat = 'MM/dd/yyyy hh:mm a';
export const DateFormatYearFirst = 'yyyy/MM/dd hh:mm a';

export function userContextRoleScopeToTypeScope(
    contextRole: UserContextRoleDTO,
    scope: UserContextRoleScopeDTO | undefined,
    changeOnly = false,
    withSuffix = true
): TypeScopeDTO | undefined {
    if (isAssigned(scope) && (!changeOnly || scope?.hasChanged === true || scope?.isDeleted === true)) {
        const suffix = withSuffix ? 'Value' : '';
        const approvedProfiles: string[] = [];
        const deniedProfiles: string[] = [];
        const res: TypeScopeDTO = {
            isActive: scope?.isDeleted !== true,
        };
        let fi = 1;

        contextRole.scopeFields?.forEach((sf) => {
            const sfce = sf.canEditProfileColumnAction === true;

            if (!sfce && sf.fieldType === 'text' && fi <= 4) {
                const fks = `${sf.fieldMapping}${suffix}` as AnyKey;
                const fkd = `field${fi}Value` as AnyKey;
                const fv = (scope as any)[fks];

                if (isAssigned(fv) && fv !== '') {
                    (res as any)[fkd] = fv;
                }

                fi++;
            } else if (sfce && sf.fieldType === 'switch') {
                const fks = sf.fieldMapping as AnyKey;
                const sfv = (((scope as any)[fks] ?? '') as string).toUpperCase();

                if (isAssigned(sf.fieldCd)) {
                    if (sfv.startsWith('APPROVED')) {
                        approvedProfiles.push(sf.fieldCd ?? '');
                    } else if (sfv.startsWith('DENIED')) {
                        deniedProfiles.push(sf.fieldCd ?? '');
                    }
                }
            }
        });

        res.approvedProfiles = approvedProfiles.join();
        res.deniedProfiles = deniedProfiles.join();

        return res;
    }

    return undefined;
}

export function userContextRoleScopesToTypeScopes(
    contextRole: UserContextRoleDTO,
    scopes: UserContextRoleScopeDTO[] | undefined,
    changesOnly = false,
    withSuffix = true
): TypeScopeDTO[] | undefined {
    if (isAssigned(scopes) && (scopes?.length ?? 0) > 0) {
        const res: TypeScopeDTO[] = [];

        scopes?.forEach((s) => {
            const scope = userContextRoleScopeToTypeScope(contextRole, s, changesOnly, withSuffix);

            if (isAssigned(scope)) {
                res.push(scope!);
            }
        });

        return res;
    }

    return undefined;
}

export function firstOrDefault<T>(a: T[] | undefined): T | undefined {
    const l = a?.length ?? 0;
    if (isAssigned(a) && l > 0) {
        return a![0];
    }

    return undefined;
}

export function lastOrDefault<T>(a: T[] | undefined): T | undefined {
    const l = a?.length ?? 0;
    if (isAssigned(a) && l > 0) {
        return a![l - 1];
    }

    return undefined;
}

export function generateScopes(contextRole: UserContextRoleDTO, newScopes: UserContextRoleScopeDTO[] | undefined): TypeScopeDTO[] | undefined {
    const scopes: UserContextRoleScopeDTO[] = [];

    if (isAssigned(newScopes) && (newScopes?.length ?? 0) > 0) {
        newScopes
            ?.filter((s) => s.hasChanged === true)
            ?.forEach((s) => {
                scopes.push(s);
            });
    }

    const res = userContextRoleScopesToTypeScopes(contextRole, scopes);

    return (res?.length ?? 0) > 0 ? res : undefined;
}

export function getIsValidClass(isValid: boolean): string {
    return isValid ? '' : ' idb-invalid ng-invalid ng-dirty';
}

const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export function isEmailValid(email: string): boolean {
    return regexEmail.test(email);
}

export function emailPattern(): string {
    return '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
}

export function localStorageRemoveItemsWithPrefix(prefix: string): void {
    const keysToRemove = Object.keys(localStorage).filter((key) => key.startsWith(prefix));

    keysToRemove.forEach((key) => {
        localStorage.removeItem(key);
    });
}

export function moveFieldToFilter(scopes: TypeScopeDTO[] | undefined, clearFields = true): TypeScopeDTO[] | undefined {
    if (isAssigned(scopes)) {
        const result = cloneDeep(scopes!);

        result.forEach((s) => {
            s.filter1Value = s.field1Value;
            s.filter2Value = s.field2Value;
            s.filter3Value = s.field3Value;
            s.filter4Value = s.field4Value;
        });

        if (clearFields) {
            result.forEach((s) => {
                s.field1Value = undefined;
                s.field2Value = undefined;
                s.field3Value = undefined;
                s.field4Value = undefined;
            });
        }

        return result;
    }

    return scopes;
}

export function showErrorMessage(toastr: ToastrService, response: ResponseDTO, getMessage?: (message?: string) => string): boolean {
    if (!isEmpty(response.errorMessage)) {
        if (response.errorMessage?.startsWith('WARN:')) {
            if (isAssigned(getMessage)) {
                toastr.error(getMessage!(response.errorMessage));
            } else {
                toastr.error(response.errorMessage?.substring(5)?.trim());
            }
        } else {
            if (isAssigned(getMessage)) {
                toastr.error(getMessage!(response.errorMessage));
            } else {
                toastr.error(response.errorMessage);
            }
            return true;
        }
    }

    return false;
}

export function filterAssignedData<T>(data: T): T {
    if (typeof data !== 'object' || data === null) {
        return data;
    }

    if (Array.isArray(data)) {
        return data.map((item) => filterAssignedData(item)) as any;
    }

    return Object.keys(data)
        .filter((key) => {
            const keyv = key as keyof typeof data;
            return data[keyv] !== null && data[keyv] !== undefined;
        })
        .reduce((obj, key) => {
            const keyv = key as keyof typeof data;
            obj[key] = filterAssignedData(data[keyv]);
            return obj;
        }, {} as any) as T;
}

export function joinWithAnd(items?: string[], separator = ', ', andLabel = 'and'): string {
    const l = items?.length ?? 0;

    if (l === 1) {
        return items![0];
    } else if (l === 2) {
        return `${items![0]} ${andLabel} ${items![1]}`;
    } else if (l >= 3) {
        const inits = clone(items!);
        const last = inits.pop();

        return `${inits.join(separator)} ${andLabel} ${last}`;
    }

    return '';
}

export function updateFields(
    contextRole: UserContextRoleDTO,
    oldScopeFields: UserContextRoleScopeFieldDTO[],
    newScopeFields: UserContextRoleScopeFieldDTO[],
    oldScopes: UserContextRoleScopeDTO[],
    newScopes: UserContextRoleScopeDTO[]
): void {
    const tmpSF = cloneDeep(oldScopeFields);
    newScopeFields?.forEach((sf) => {
        const sfcd = sf.fieldCd?.toLowerCase();
        let crsf = oldScopeFields.find((e) => e.fieldCd?.toLowerCase() === sfcd);

        if (!isAssigned(crsf)) {
            let sfm = 0;
            tmpSF.forEach((e) => {
                const sfo = e.fieldOrder ?? -1;
                sfm = sfo > sfm ? sfo : sfm;
            });
            sfm++;

            crsf = {
                userRoleContextId: contextRole.userRoleContextId,
                contextCd: contextRole.contextCd,
                roleCd: contextRole.roleCd,
                fieldCd: sf.fieldCd,
                fieldName: sf.fieldName,
                fieldType: sf.fieldType,
                fieldMapping: `field${sfm}`,
                fieldOrder: sfm,
                canEditProfileColumnAction: sf.canEditProfileColumnAction,
                isRequired: sf.isRequired,
                canAddMultipleScopes: sf.canAddMultipleScopes,
                atLeastOneApprovalIsRequired: sf.atLeastOneApprovalIsRequired,
            };

            tmpSF.push(crsf);
        }
    });

    contextRole.scopeFields = tmpSF;

    newScopes?.forEach((s) => {
        let ncrs: UserContextRoleScopeDTO = {
            userRoleContextId: contextRole.userRoleContextId,
            contextCd: contextRole.contextCd,
            roleCd: contextRole.roleCd,
            canEditScopeRowAction: true,
            canDeleteScope: true,
            //            hasChanged: true,
        };

        newScopeFields?.forEach((sf) => {
            const sfcd = sf.fieldCd?.toLowerCase();
            let crsf = tmpSF.find((e) => e.fieldCd?.toLowerCase() === sfcd);
            (ncrs as any)[crsf?.fieldMapping as AnyKey] = (s as any)[sf.fieldMapping as AnyKey];
            (ncrs as any)[(crsf?.fieldMapping + 'Value') as AnyKey] = (s as any)[(sf.fieldMapping + 'Value') as AnyKey];
            (ncrs as any)[(crsf?.fieldMapping + 'Id') as AnyKey] = (s as any)[(sf.fieldMapping + 'Id') as AnyKey];
            (ncrs as any)[(crsf?.fieldMapping + 'CanApprove') as AnyKey] = (s as any)[(sf.fieldMapping + 'CanApprove') as AnyKey];
            (ncrs as any)[(crsf?.fieldMapping + 'HasAccessDetails') as AnyKey] = (s as any)[(sf.fieldMapping + 'HasAccessDetails') as AnyKey];
            (ncrs as any)[(crsf?.fieldMapping + 'DisableAutoUpdates') as AnyKey] = (s as any)[(sf.fieldMapping + 'DisableAutoUpdates') as AnyKey];
            (ncrs as any)[(crsf?.fieldMapping + 'DisableAutoUpdatesLabel') as AnyKey] = (s as any)[(sf.fieldMapping + 'DisableAutoUpdatesLabel') as AnyKey];
        });

        oldScopes.push(ncrs);
    });
}

export function doSaveContextRole(
    toastr: ToastrService,
    usersSrv: UsersService,
    onLoading: (isLoading: boolean) => void,
    onFinish: (response: SaveUserContextRoleResponseDTO) => void,
    accountInfo?: UserAccountInformationDTO,
    contextRole?: UserContextRoleDTO,
    scopes?: UserContextRoleScopeDTO[],
    request: SaveUserContextRoleRequestDTO = {}
) {
    const req: SaveUserContextRoleRequestDTO = {};

    req.contextCd = request.contextCd ?? contextRole?.contextCd;
    req.roleCd = request.roleCd ?? contextRole?.roleCd;
    req.userEmail = request.userEmail ?? contextRole?.information?.userEmail;
    req.canCreateAccounts = request.canCreateAccounts ?? contextRole?.information?.canCreateAccounts;
    req.canReceiveApprovalNotifications = request.canReceiveApprovalNotifications ?? contextRole?.information?.canReceiveApprovalNotifications;
    req.firstName = request.firstName ?? contextRole?.information?.firstName;
    req.middleName = request.middleName ?? contextRole?.information?.middleName;
    req.lastName = request.lastName ?? contextRole?.information?.lastName;
    req.preferredLanguageCd = request.preferredLanguageCd ?? contextRole?.information?.preferredLanguageCd;
    req.fromDt = request.fromDt ?? contextRole?.information?.roleGrantedFromDate;
    req.toDt = request.toDt ?? contextRole?.information?.roleGrantedToDate;

    if (accountInfo?.isInternal !== true) {
        req.secondaryEmail = request.secondaryEmail ?? contextRole?.information?.secondaryEmail;
        req.primaryPhone = request.primaryPhone ?? contextRole?.information?.primaryPhone;
        req.mobilePhone = request.mobilePhone ?? contextRole?.information?.mobilePhone;
        req.fax = request.fax ?? contextRole?.information?.fax;
    }

    req.scopes = userContextRoleScopesToTypeScopes(contextRole ?? { userRoleContextId: -1 }, scopes ?? contextRole?.scopes, true);

    req.isInternal = request.isInternal ?? accountInfo?.isInternal;

    onLoading(true);

    usersSrv.saveUserContextRole(req).subscribe({
        next: (response) => {
            onLoading(false);

            if (
                !showErrorMessage(
                    toastr,
                    response,
                    () =>
                        'The record successfully saved to database but failed to update B2C account. Support was already notified. Please try to update account later using refresh icon.'
                )
            ) {
                toastr.success('Changes saved successfully!');

                onFinish(response);
            }
        },
        error: (error) => {
            onLoading(false);
            toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
        },
    });
}

export function doSaveUserContextRoleRequest(
    toastr: ToastrService,
    usersSrv: UsersService,
    callExternal: boolean,
    onLoading: (isLoading: boolean) => void,
    onFinish: (response: SaveUserContextRoleExternalRequestResponseDTO | SaveUserContextRoleInternalRequestResponseDTO) => void,
    accountInfo?: UserAccountInformationDTO,
    contextRole?: UserContextRoleDTO,
    showToaster: boolean = true,
    request: SaveUserContextRoleExternalRequestRequestDTO | SaveUserContextRoleInternalRequestRequestDTO = {}
) {
    const req: SaveUserContextRoleExternalRequestRequestDTO | SaveUserContextRoleInternalRequestRequestDTO = {};

    req.contextCd = request.contextCd ?? contextRole?.contextCd;
    req.roleCd = request.roleCd ?? contextRole?.roleCd;
    req.userEmail = request.userEmail ?? contextRole?.information?.userEmail;
    req.firstName = request.firstName ?? contextRole?.information?.firstName;
    req.middleName = request.middleName ?? contextRole?.information?.middleName;
    req.lastName = request.lastName ?? contextRole?.information?.lastName;
    req.preferredLanguageCd = request.preferredLanguageCd ?? contextRole?.information?.preferredLanguageCd;

    if (accountInfo?.isInternal !== true) {
        req.secondaryEmail = request.secondaryEmail ?? contextRole?.information?.secondaryEmail;
        req.primaryPhone = request.primaryPhone ?? contextRole?.information?.primaryPhone;
        req.mobilePhone = request.mobilePhone ?? contextRole?.information?.mobilePhone;
        req.fax = request.fax ?? contextRole?.information?.fax;
    }

    const scps = cloneDeep(contextRole?.scopes);
    scps?.forEach((s) => {
        s.hasChanged = true;
    });
    req.scopes = userContextRoleScopesToTypeScopes(contextRole ?? { userRoleContextId: -1 }, scps, true);

    if (isAssigned((request as any)?.deleteRole)) {
        (req as any).deleteRole = (request as any).deleteRole;
    }

    onLoading(true);

    const obs: Observable<SaveUserContextRoleExternalRequestResponseDTO> | Observable<SaveUserContextRoleInternalRequestResponseDTO> = callExternal
        ? usersSrv.saveUserContextRoleExternalRequest(req)
        : usersSrv.saveUserContextRoleInternalRequest(req);

    obs.subscribe({
        next: (response) => {
            onLoading(false);

            if (!showErrorMessage(toastr, response)) {
                if (showToaster) {
                    toastr.success('Changes saved successfully!');
                }

                onFinish(response);
            }
        },
        error: (error) => {
            onLoading(false);
            toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
        },
    });
}

export function doReadContextRoleScope(
    toastr: ToastrService,
    globalsSrv: GlobalsService,
    contextRoleSrv: ContextRoleService,
    onLoading: (isLoading: boolean) => void,
    onFinish: (response: ReadContextRoleScopeResponseDTO) => void,
    contextRole?: UserContextRoleDTO,
    scopes?: TypeScopeDTO[],
    usage?: string
) {
    const request: ReadContextRoleScopeRequestDTO = {
        userEmail: contextRole?.information?.userEmail,
        scope: moveFieldToFilter(scopes) ?? [],
        usage: usage,
        contextCd: contextRole?.contextCd,
        roleCd: contextRole?.roleCd,
        languageCd: globalsSrv.language,
    };

    onLoading(true);

    contextRoleSrv.readContextRoleScope(request).subscribe({
        next: (response) => {
            onLoading(false);

            if (!showErrorMessage(toastr, response)) {
                onFinish(response);
            }
        },
        error: (error) => {
            onLoading(false);
            toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
        },
    });
}

export function reloadPage(router: Router) {
    const url = router.url;
    const tempUrl = `/blank`;

    router.navigateByUrl(tempUrl, { skipLocationChange: true }).then(() => {
        router.navigate([url]);
    });
}

export function serializeToBase64(obj: any): string {
    const jsonString = JSON.stringify(obj);
    const base64String = btoa(jsonString);
    return base64String;
}

export function deserializeFromBase64(base64String: string | undefined): any {
    if (!isEmpty(base64String)) {
        const jsonString = atob(base64String!);
        const obj = JSON.parse(jsonString);
        return obj;
    }

    return undefined;
}

export function toISODateString(date: Date | undefined): string | undefined {
    if (isAssigned(date)) {
        const dstr = date!.toISOString();

        return dstr.substring(0, 10);
    }

    return undefined;
}

const NumberExpr = /^[0-9]$/;
export function isNumber(key: string): boolean {
    return NumberExpr.test(key);
}

export function toLinesHtml(str?: string, tag = 'span'): string | undefined {
    if (!isEmpty(str)) {
        const lines = str!.split('\n');
        const newLines = lines.map((line) => `<${tag}>${line}</${tag}>`);

        return newLines.join();
    }

    return str;
}

export function doReadUserContextRoleExternalRequest(
    toastr: ToastrService,
    userSrv: UsersService,
    requestKey: string,
    onLoading: (isLoading: boolean) => void,
    onFinish: (response: ReadUserContextRoleExternalRequestResponseDTO) => void,
    onError?: (errorMessage: string) => void
): void {
    onLoading(true);

    const request: ReadUserContextRoleExternalRequestRequestDTO = {
        requestKey: requestKey,
    };

    userSrv.readUserContextRoleExternalRequest(request).subscribe({
        next: (response) => {
            onLoading(false);

            if (isAssigned(onError) && !isEmpty(response.errorMessage)) {
                onError!(response.errorMessage!);
            } else if (!showErrorMessage(toastr, response)) {
                onFinish(response);
            }
        },
        error: (error) => {
            onLoading(false);

            toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
        },
    });
}

export function doReadUserContextRoleInternalRequest(
    toastr: ToastrService,
    globalsSrv: GlobalsService,
    userSrv: UsersService,
    userEmail: string,
    userRoleContextId: number | undefined,
    onLoading: (isLoading: boolean) => void,
    onFinish: (response: ReadUserContextRoleInternalRequestResponseDTO) => void,
    onError?: (errorMessage: string) => void
): void {
    onLoading(true);

    const request: ReadUserContextRoleInternalRequestRequestDTO = {
        userEmail: userEmail,
        userRoleContextId: userRoleContextId,
        languageCd: globalsSrv.language,
    };

    userSrv.readUserContextRoleInternalRequest(request).subscribe({
        next: (response) => {
            onLoading(false);

            if (isAssigned(onError) && !isEmpty(response.errorMessage)) {
                onError!(response.errorMessage!);
            } else if (!showErrorMessage(toastr, response)) {
                onFinish(response);
            }
        },
        error: (error) => {
            onLoading(false);

            toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
        },
    });
}

export function doResendEmailValidationLink(
    toastr: ToastrService,
    userSrv: UsersService,
    userEmail: string,
    requestKey: string,
    onLoading: (isLoading: boolean) => void,
    onFinish: (response: ResendUserContextRoleRequestResponseDTO) => void,
    onError?: (errorMessage: string) => void
): void {
    onLoading(true);

    const request: ResendUserContextRoleRequestRequestDTO = {
        requestKey: requestKey,
        userEmail: userEmail,
    };

    userSrv.resendUserContextRoleRequest(request).subscribe({
        next: (response) => {
            onLoading(false);

            if (isAssigned(onError) && !isEmpty(response.errorMessage)) {
                onError!(response.errorMessage!);
            } else if (!showErrorMessage(toastr, response)) {
                onFinish(response);
            }
        },
        error: (error) => {
            onLoading(false);

            toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
        },
    });
}

export function doReadUser(
    toastr: ToastrService,
    globalsSrv: GlobalsService,
    userSrv: UsersService,
    userEmail: string,
    contextCd: string | undefined,
    roleCd: string | undefined,
    onLoading: (isLoading: boolean) => void,
    onFinish: (response: ReadUserResponseDTO) => void,
    onError?: (errorMessage: string) => void
) {
    onLoading(true);

    const request: ReadUserRequestDTO = {
        userEmail: userEmail,
        languageCd: globalsSrv.language,
    };

    if (!isEmpty(roleCd)) request.roleCd = roleCd;
    if (!isEmpty(contextCd)) request.contextCd = contextCd;

    userSrv.readUser(request).subscribe({
        next: (response) => {
            onLoading(false);

            if (isAssigned(onError) && !isEmpty(response.errorMessage)) {
                onError!(response.errorMessage!);
            } else if (!showErrorMessage(toastr, response)) {
                onFinish(response);
            }
        },
        error: (error) => {
            onLoading(false);

            toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
        },
    });
}

export function doSaveUserContextRoleProfile(
    toastr: ToastrService,
    contextRoleSrv: ContextRoleService,
    request: SaveUserContextRoleProfileRequestDTO,
    onLoading: (loading: boolean) => void,
    onFinish: (response: SaveUserContextRoleProfileResponseDTO) => void
) {
    onLoading(true);

    contextRoleSrv.saveUserContextRoleProfile(request).subscribe({
        next: (response) => {
            onLoading(false);

            if (!showErrorMessage(toastr, response)) {
                toastr.success('Profile was saved successfylly!');
                onFinish(response);
            }
        },
        error: (error) => {
            onLoading(false);

            toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
        },
    });
}

export function getDeletedScopes(
    scopeFields?: UserContextRoleScopeFieldDTO[],
    existingScopes?: UserContextRoleScopeDTO[],
    newScopes?: UserContextRoleScopeDTO[]
): UserContextRoleScopeDTO[] {
    const textFields = scopeFields?.filter((sf) => sf.fieldType?.toLowerCase() === 'text') ?? [];
    let scopes: UserContextRoleScopeDTO[] = [];

    existingScopes?.filter((sE) => {
        const sN = newScopes?.find((ss2) => {
            let sfc = 0;

            textFields.forEach((sf) => {
                const sfk = `${sf.fieldMapping}Value` as AnyKey;
                if ((sE as any)[sfk] === (ss2 as any)[sfk]) {
                    sfc++;
                }
            });

            return sfc === textFields.length;
        });

        if (!isAssigned(sN)) {
            const s1c = cloneDeep(sE);
            s1c.isDeleted = true;
            scopes.push(s1c);
        }
    });

    return scopes;
}

export interface ConfirmActionOptions {
    header: string;
    message: string;
    acceptLabel: string;
    cancelLabel: string;
    icon?: string;
    acceptIcon?: string;
    cancelIcon?: string;
    acceptButtonStyle?: string;
    cancelButtonStyle?: string;
    onAccept: () => void;
    onCancel?: () => void;
}

export function confirmAction(confirmSrv: ConfirmationService, options: ConfirmActionOptions) {
    confirmSrv.confirm({
        header: options.header,
        icon: options.icon ?? 'pi pi-question-circle',
        message: options.message,
        // Setting Accept and Cancel options inverted, so the buttons can be shown inverted on screen.
        rejectLabel: options.acceptLabel,
        rejectIcon: (options.acceptIcon ?? 'pi pi-check') + ' mr-1',
        rejectButtonStyleClass: options.acceptButtonStyle ?? 'p-button-success',
        acceptLabel: options.cancelLabel,
        acceptIcon: (options.cancelIcon ?? 'pi pi-times') + ' mr-1',
        acceptButtonStyleClass: options.cancelButtonStyle ?? 'p-button-danger',
        defaultFocus: 'accept',
        closeOnEscape: false,
        blockScroll: true,
        reject: () => options.onAccept(),
        accept: () => {
            if (isAssigned(options.onCancel)) options.onCancel!();
        },
    });
}
