import { UserContextRoleIdDTO } from './user-context-role-id-dto';

export interface UserContextRoleScopeFieldDTO extends UserContextRoleIdDTO {
    fieldCd?: string;
    fieldName?: string;
    fieldType?: string;
    fieldOrder?: number;
    fieldMapping?: string;
    canEditProfileColumnAction?: boolean;
    isRequired?: boolean;
    canAddMultipleScopes?: boolean;
    atLeastOneApprovalIsRequired?: boolean;
}
