import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { clone } from 'lodash-es';

import {
    AnyKey,
    CRSC_CT_DropDown,
    CRSC_CT_MultiChoice,
    CRSC_CT_Table,
    ColumnViewDef,
    ColumnsViewDef,
    ContextRoleScopeComponentDTO,
    ContextRoleScopeComponentItemDTO,
    TranslateBaseComponent,
    isAssigned,
} from '../../data';

const ComponentName = 'context-role-scope-picker-field';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: './context-role-scope-picker-field.component.html',
    styleUrls: ['./context-role-scope-picker-field.component.scss'],
})
export class ContextRoleScopePickerFieldComponent extends TranslateBaseComponent {
    private _originalItems: ContextRoleScopeComponentItemDTO[] = [];
    private _items: ContextRoleScopeComponentItemDTO[] = [];
    private _allItem: ContextRoleScopeComponentItemDTO;

    columns: ColumnsViewDef = new ColumnsViewDef([]);
    selectedItem?: ContextRoleScopeComponentItemDTO;

    constructor(translateSrv: TranslateService) {
        super(translateSrv);
        this._translateKey = ComponentName;

        this.selectedItem = undefined;
        this._allItem = { fieldValue: 'all', fieldDisplayValue: this.translate('all') };
    }

    private _component: ContextRoleScopeComponentDTO | undefined;
    @Input() set component(value: ContextRoleScopeComponentDTO | undefined) {
        this._component = value;
        this._originalItems = this._component?.items ?? [];
        this._items = clone(this._originalItems);
        this._selectedValue = [];
        this._isValidated = false;

        if (this.isTable) {
            this.checkValidated();
        }

        if (this.allOptionAvailable) {
            const allItem: ContextRoleScopeComponentItemDTO[] = [this._allItem];
            this._items = allItem.concat(this._items);
            this.selectedItem = this._allItem;
            this.onDropdownChange({ value: this._allItem });
        }

        this.generateColumns();
    }

    private _selectedValue: ContextRoleScopeComponentItemDTO[] = [];
    @Output() onSelectedValueChange = new EventEmitter<ContextRoleScopeComponentItemDTO[]>();
    get selectedValue(): ContextRoleScopeComponentItemDTO[] {
        return this._selectedValue;
    }

    private _isValidated: boolean = false;
    @Output() onValidatedChange = new EventEmitter<boolean>();
    get isValidated(): boolean {
        return this._isValidated;
    }

    get isReadOnly(): boolean {
        return this._component?.isReadOnly === true;
    }

    get items(): ContextRoleScopeComponentItemDTO[] {
        return this._items;
    }

    get componentLabelRequired(): string {
        return `${this._component?.componentLabel ?? ''}${this.isRequired ? ' *' : ''}`;
    }

    get componentLabel(): string {
        return this._component?.componentLabel ?? '';
    }

    get componentPlaceHolder(): string {
        if (this.isMultiChoice) {
            if (this._selectedValue.indexOf(this._allItem) >= 0) {
                return this.translate(`allSelected.placeHolder`, { value: this.componentLabel });
            }

            if (this._selectedValue.length > 0) {
                return this.translate(`selectAdditional.placeHolder`, { value: this.componentLabel });
            }
        }

        if (this.isDisabled) {
            return this.translate(`selectingNotAllowed.placeHolder`, { value: this.componentLabel });
        }

        return this.translate(`selectThe.placeHolder`, { value: this.componentLabel });
    }

    get currentComponentValue(): string {
        return this._component?.currentComponentValue ?? '';
    }

    get currentComponentDisplayValue(): string {
        return this._component?.currentComponentDisplayValue ?? this.currentComponentValue;
    }

    get componentType(): string {
        return this._component?.componentType?.toLowerCase() ?? '';
    }

    get showFilter(): boolean {
        return (this._items?.length ?? 0) > 20;
    }

    get isDropDown(): boolean {
        return !this.isReadOnly && this.componentType === CRSC_CT_DropDown;
    }

    get isMultiChoice(): boolean {
        return !this.isReadOnly && this.componentType === CRSC_CT_MultiChoice;
    }

    get isTable(): boolean {
        return this.componentType === CRSC_CT_Table;
    }

    get isRequired(): boolean {
        return this._component?.isRequired ?? false;
    }

    get validClass(): string {
        return 'p-dropdown-sm' + (this.isRequired && this._selectedValue.length == 0 ? ' ng-invalid ng-dirty' : '');
    }

    get allOptionSelected(): boolean {
        return this._selectedValue.indexOf(this._allItem) > -1;
    }

    get allOptionAvailable(): boolean {
        return this._component?.dropdownAllOptionAvailable === true;
    }

    get showMultiChoiceSelectedValue(): boolean {
        return !this.isReadOnly && this.isMultiChoice && this._selectedValue.length > 0 && !this.allOptionSelected;
    }

    get isDisabled(): boolean {
        return false;
    }

    generateColumns() {
        const columns: ColumnViewDef[] = [];

        if (this.isTable) {
            const colNames = this._component?.tableFields ?? [];

            if (colNames.length > 0) {
                this._component?.items?.forEach((it) => {
                    const values = it.fieldValue?.split('|') ?? [];
                    const labels = it.fieldDisplayValue?.split('|') ?? [];

                    colNames.forEach((col, ind) => {
                        if (ind < values.length) {
                            const vkn = `${col.fieldName}Value` as AnyKey;
                            (it as any)[vkn] = values[ind];
                        }

                        if (ind < labels.length) {
                            const lkn = `${col.fieldName}Label` as AnyKey;
                            (it as any)[lkn] = labels[ind];
                        }
                    });
                });

                colNames.forEach((cn) => {
                    cn.fieldName = `${cn.fieldName}Label`;
                    const column: ColumnViewDef = { name: cn.fieldName, header: cn.fieldLabel };

                    columns.push(column);
                });
            }
        }

        this.columns = new ColumnsViewDef(columns);
    }

    checkValidated() {
        const prev = this._isValidated;
        this._isValidated = this.isTable ? true : this._component?.isRequired === true ? this._selectedValue.length > 0 : true;

        if (prev !== this._isValidated) {
            this.onValidatedChange.emit(this._isValidated);
        }
    }

    sortItems() {
        this._items.sort((a, b) => {
            if (a === this._allItem) return -1;

            if (b === this._allItem) return 1;

            return (a.fieldValue ?? '').localeCompare(b.fieldValue ?? '');
        });
    }

    cloneItems() {
        this._items = clone(this._originalItems);

        if (this.allOptionAvailable) {
            this._items = [this._allItem].concat(this._items);
        }
    }

    onDropdownChange($event: any) {
        const value: ContextRoleScopeComponentItemDTO = $event.value;

        if (this.isDropDown) {
            this._selectedValue = [value];
        } else if (this.isMultiChoice) {
            if (value === this._allItem) {
                this._selectedValue = [this._allItem];
                this.cloneItems();
            } else {
                let index = this._selectedValue.indexOf(this._allItem);
                if (index > -1) {
                    this._selectedValue.splice(index, 1);
                }

                index = this._selectedValue.indexOf(value);
                if (index === -1) {
                    this._selectedValue.push(value);
                }

                index = this._items.indexOf(value);
                this._items.splice(index, 1);
            }
        }

        this.checkValidated();

        this.onSelectedValueChange.emit(this._selectedValue);
    }

    onRemoveItem(item: ContextRoleScopeComponentItemDTO) {
        this.selectedItem = item;

        let index = this._selectedValue.indexOf(item);
        if (index < 0) return;

        if (item === this._allItem) {
            this.cloneItems();
            this._selectedValue = [];
            this.selectedItem = this._allItem;
        } else {
            this._selectedValue.splice(index, 1);

            index = this._items.indexOf(item);
            if (index === -1) {
                this._items.push(item);

                this.sortItems();
            }

            if (this.allOptionAvailable && this._selectedValue.length === 0) {
                this.selectedItem = this._allItem;
            }
        }

        this.checkValidated();

        this.onSelectedValueChange.emit(this._selectedValue);
    }
}
