import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MsalService } from '@azure/msal-angular';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    ReadSapRequestDTO,
    ReadSapResponseDTO,
    ReadUserApproversRequestDTO,
    ReadUserApproversResponseDTO,
    ReadUserContextRoleInternalRequestRequestDTO,
    ReadUserContextRoleInternalRequestResponseDTO,
    ReadUserHistoryRequestDTO,
    ReadUserHistoryResponseDTO,
    ReadUserRequestDTO,
    ReadUserResponseDTO,
    ResendActivationEmailRequestDTO,
    ResendActivationEmailResponseDTO,
    ResendUserContextRoleRequestRequestDTO,
    ResendUserContextRoleRequestResponseDTO,
    SaveEmailValidationRequestDTO,
    SaveEmailValidationResponseDTO,
    SaveUserContextRoleRequestDTO,
    SaveUserContextRoleExternalRequestRequestDTO,
    SaveUserContextRoleExternalRequestResponseDTO,
    SaveUserContextRoleResponseDTO,
    UpdateB2CRequestDTO,
    UpdateB2CResponseDTO,
    ReadUserContextRoleExternalRequestRequestDTO,
    ReadUserContextRoleExternalRequestResponseDTO,
    SaveUserContextRoleInternalRequestRequestDTO,
    SaveUserContextRoleInternalRequestResponseDTO,
} from '../dtos';
import { BaseService } from './base.service';
import { filterAssignedData } from '../utils';
import { GlobalsService } from './globals.service';

@Injectable()
export class UsersService extends BaseService {
    private _usersUrl: string;

    constructor(private readonly _http: HttpClient, globalsSrv: GlobalsService, authSrv: MsalService) {
        super(globalsSrv, authSrv);

        this._usersUrl = `${this.apiUrl}/Users`;
    }

    getSapUsers(request: ReadSapRequestDTO): Observable<ReadSapResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<ReadSapResponseDTO>(`${this._usersUrl}/sap`, options).pipe(catchError(this.onHttpError));
    }

    readUserApprovers(request: ReadUserApproversRequestDTO): Observable<ReadUserApproversResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<ReadUserApproversResponseDTO>(`${this._usersUrl}/user-approvers`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    readUserHistory(request: ReadUserHistoryRequestDTO): Observable<ReadUserHistoryResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<ReadUserHistoryResponseDTO>(`${this._usersUrl}/user-history`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    saveUserContextRole(request: SaveUserContextRoleRequestDTO): Observable<SaveUserContextRoleResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<SaveUserContextRoleResponseDTO>(`${this._usersUrl}/user-context-role`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    readUserContextRoleExternalRequest(request: ReadUserContextRoleExternalRequestRequestDTO): Observable<ReadUserContextRoleExternalRequestResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .get<ReadUserContextRoleExternalRequestResponseDTO>(`${this._usersUrl}/user-context-role-external-request`, options)
            .pipe(catchError(this.onHttpError));
    }

    readUserContextRoleInternalRequest(request: ReadUserContextRoleInternalRequestRequestDTO): Observable<ReadUserContextRoleInternalRequestResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .get<ReadUserContextRoleInternalRequestResponseDTO>(`${this._usersUrl}/user-context-role-internal-request`, options)
            .pipe(catchError(this.onHttpError));
    }

    saveUserContextRoleExternalRequest(request: SaveUserContextRoleExternalRequestRequestDTO): Observable<SaveUserContextRoleExternalRequestResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<SaveUserContextRoleExternalRequestResponseDTO>(`${this._usersUrl}/user-context-role-external-request`, filterAssignedData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    saveUserContextRoleInternalRequest(request: SaveUserContextRoleInternalRequestRequestDTO): Observable<SaveUserContextRoleInternalRequestResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<SaveUserContextRoleInternalRequestResponseDTO>(`${this._usersUrl}/user-context-role-internal-request`, filterAssignedData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    resendUserContextRoleRequest(request: ResendUserContextRoleRequestRequestDTO): Observable<ResendUserContextRoleRequestResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<ResendUserContextRoleRequestResponseDTO>(`${this._usersUrl}/user-context-role-request/resend`, filterAssignedData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    saveEmailValidation(request: SaveEmailValidationRequestDTO): Observable<SaveEmailValidationResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<SaveEmailValidationResponseDTO>(`${this._usersUrl}/user-context-role-request/email-validation`, filterAssignedData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    updateB2C(request: UpdateB2CRequestDTO): Observable<UpdateB2CResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http.post<UpdateB2CResponseDTO>(`${this._usersUrl}/update-b2c`, this.fillRequestData(request), options).pipe(catchError(this.onHttpError));
    }

    readUser(request: ReadUserRequestDTO): Observable<ReadUserResponseDTO> {
        const userEmail = request.userEmail;
        delete request.userEmail;

        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        let res = this._http.get<ReadUserResponseDTO>(`${this._usersUrl}/user-information/${userEmail}`, options).pipe(catchError(this.onHttpError));
        request.userEmail = userEmail;

        return res;
    }

    resendActivationEmail(request: ResendActivationEmailRequestDTO): Observable<ResendActivationEmailResponseDTO> {
        const userEmail = request.userEmail;
        delete request.userEmail;

        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        let res = this._http
            .get<ResendActivationEmailResponseDTO>(`${this._usersUrl}/user-information/${userEmail}/resend`, options)
            .pipe(catchError(this.onHttpError));
        request.userEmail = userEmail;

        return res;
    }
}
