// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .idb-component-chip {
  margin-right: 0.5rem !important;
}
::ng-deep .idb-component-table {
  height: 15rem;
  max-height: 20rem;
}
::ng-deep .idb-component-table > idb-data-table > p-table > table {
  min-width: 20rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/context-role-scope-picker/context-role-scope-picker-field.component.scss"],"names":[],"mappings":"AACI;EACI,+BAAA;AAAR;AAGI;EACI,aAAA;EACA,iBAAA;AADR;AAKgB;EACI,2BAAA;AAHpB","sourcesContent":["::ng-deep {\r\n    .idb-component-chip {\r\n        margin-right: 0.5rem !important;\r\n    }\r\n\r\n    .idb-component-table {\r\n        height: 15rem;\r\n        max-height: 20rem;\r\n\r\n        > idb-data-table {\r\n            > p-table {\r\n                > table {\r\n                    min-width: 20rem !important;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
