import { PerformedByRequestDTO } from './performed-by-request-dto';

export interface ReadUserRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
    roleCd?: string;
    contextCd?: string;
    languageCd?: string;
}

export interface ResendActivationEmailRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
}
