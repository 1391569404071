import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { ChangedEvent, MetaService, ReadSapRequestDTO, SapUserDTO, UnknownErrorCallingAPIMessage, UsersService, isAssigned } from '../../data';
import { UserContextRoleAccountEditorBase } from './user-context-role-account-editor-base.component';

export interface InternalAccountChangedEvent extends ChangedEvent {
    userEmailExists: boolean;
    userEmail: string;
    userFullName?: string;
    preferredLanguageCd?: string;
}

const ComponentName = 'user-context-role-internal-account-editor';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: `./user-context-role-internal-account-editor.component.html`,
    styleUrls: [`./user-context-role-internal-account-editor.component.scss`],
    host: {
        class: 'grid gap-0',
    },
})
export class UserContextRoleInternalAccountEditorComponent extends UserContextRoleAccountEditorBase {
    sapUsers: any[] = [];

    isLoadingSap = false;
    sapUserEmail: string | undefined;

    constructor(private readonly _userSrv: UsersService, translateSrv: TranslateService, toastr: ToastrService, metaSrv: MetaService, location: Location) {
        super(translateSrv, toastr, metaSrv, location);

        this._translateKey = ComponentName;

        this._isInternal = true;
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this.isLoadingSap || super.isLoadingInternal;
    }

    get isEmailValidated(): boolean {
        return this.isNewUser ? this.isEmailValid && !this.userEmailExists : this.isEmailValid;
    }

    get isSecondaryEmailValid(): boolean {
        return true;
    }

    override get isValid(): boolean {
        return this.isEmailValidated && this.isSecondaryEmailValid;
    }

    private readSap(value: string) {
        this.isLoadingSap = true;
        this.emitOnLoading();

        const request: ReadSapRequestDTO = {};

        if (isAssigned(this.sapUserEmail)) {
            request.userEmailOrName = this.sapUserEmail;
        }

        if (isAssigned(value)) {
            request.userEmailOrName = value;
        }

        this._userSrv.getSapUsers(request).subscribe({
            next: (response) => {
                this.isLoadingSap = false;
                this.sapUsers = response.sapUsers ?? [];
                this.emitOnLoading();
            },
            error: (error) => {
                this.isLoadingSap = false;
                this.toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
                this.emitOnLoading();
            },
        });
    }

    private setSapUser(sapUser: SapUserDTO | undefined = undefined) {
        this.accountInfo.userEmail = sapUser?.userEmail;
        this.accountInfo.userFullName = sapUser?.userFullName;
        this.accountInfo.firstName = sapUser?.firstName;
        this.accountInfo.lastName = sapUser?.lastName;
        this.accountInfo.preferredLanguageCd = sapUser?.preferredLanguageCd;

        if (!isAssigned(this.contextRole.information)) {
            this.contextRole.information = { userRoleContextId: this.contextRole.userRoleContextId };
        }

        this.contextRole.information!.userEmail = sapUser?.userEmail;
        this.contextRole.information!.userFullName = sapUser?.userFullName;
        this.contextRole.information!.firstName = sapUser?.firstName;
        this.contextRole.information!.lastName = sapUser?.lastName;
        this.contextRole.information!.preferredLanguageCd = sapUser?.preferredLanguageCd;
    }

    onSearchSapUser($e: any) {
        this.readSap($e.query);
    }

    onSelectSapUser($e: any) {
        const sapUser = $e.value as SapUserDTO;

        this.setSapUser(sapUser);

        this.checkUserEmail(this.toastr, this._userSrv);
    }

    onClearSapUser($e: any) {
        this.userEmailExists = false;

        this.setSapUser();

        this.emitOnChanged();
    }
}
