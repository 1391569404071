import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MsalService } from '@azure/msal-angular';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ReadAccessPermissionsRequestDTO, ReadAccessPermissionsResponseDTO, ReadMetaRequestDTO, ReadMetaResponseDTO } from '../dtos';
import { BaseService, UnknownErrorCallingAPIMessage } from './base.service';
import { GlobalsService } from './globals.service';
import { isEmpty } from '../utils';

@Injectable()
export class MetaService extends BaseService {
    private _metaUrl: string;

    constructor(private readonly _http: HttpClient, globalsSrv: GlobalsService, authSrv: MsalService) {
        super(globalsSrv, authSrv);

        this._metaUrl = `${this.apiUrl}/Meta`;
    }

    readMeta(request: ReadMetaRequestDTO): Observable<ReadMetaResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<ReadMetaResponseDTO>(this._metaUrl, options).pipe(catchError(this.onHttpError));
    }

    readPermisions(): Observable<string> {
        const request: ReadAccessPermissionsRequestDTO = {};
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<ReadAccessPermissionsResponseDTO>(`${this._metaUrl}/permissions`, options).pipe(
            map((response) => {
                if (isEmpty(response.errorMessage)) {
                    this.globalsSrv.setPermissions(response.permissions);
                    return '';
                } else {
                    return response.errorMessage!;
                }
            }),
            catchError((error) => {
                return of(UnknownErrorCallingAPIMessage);
            })
        );
    }
}
