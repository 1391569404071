export const ESTranslation = {
    save: 'Guardar',
    cancel: 'Cancelar',
    close: 'Cerrar',
    confirm: 'Confirmar',
    filterByLabel: 'Filtrar por',
    paginatorLabel: 'Página {currentPage} de {totalPages} ({totalRecords} entradas)',
    from: 'Desde',
    to: 'Hasta',
    indefinite: 'Indefinido',
    syncB2CNeeded: 'Se necesita sincronización de la cuenta B2C',
    email: 'Correo electrónico',
    secondaryEmail: 'Correo electrónico secundario',
    name: 'Nombre',
    userType: 'Tipo de usuario',
    preferredLanguage: 'Idioma preferido',
    primaryPhone: 'Teléfono',
    mobilePhone: 'Teléfono móvil',
    fax: 'Fax',
    external: 'Externo',
    internal: 'Interno',
    viewHistory: 'Ver Historial',
    viewAccountHistory: 'Historial de la Cuenta',
    viewRoleHistory: 'Historial del Rol',
    viewProfileHistory: 'Historial del Perfil',
    accessDetails: 'Detalles de Acceso',
    emailSentSusccess: '¡El correo de validación fue enviado con éxito! ',
    emailResentError: '¡El correo de validación no se puede enviar! ',
    eaps: {
        title: 'Sistema de Provisionamiento de Cuentas de Extranet',
        'with-menu': {
            tabMyWork: {
                label: 'Aprobaciones',
            },
            tabUserDirectory: {
                label: 'Directorio de Usuarios',
            },
            tabReports: {
                label: 'Reportes',
            },
            accountMenu: {
                label: '{{ loggedUser.userName }} a nombre de {{ currentUser.userName }} {{{ currentUser.userEmail }}}',
            },
            myAccount: {
                label: 'Mi Cuenta',
            },
            loginAs: {
                label: 'Iniciar Sesión Como',
                notification: 'Usuario actual cambiado a {{ userName }} ({{ userEmail }})',
            },
            returnUser: {
                label: 'Volver a {{ userName }}',
            },
        },
    },
    profile: {
        status: {
            pending: 'Pendiente',
            recertify: 'Recertificar',
            approve: 'Aprobar',
            approved: 'Aprobado',
            approveAll: 'Aprobar Todo',
            deny: 'Denegar',
            denied: 'Denegado',
            denyAll: 'Denegar Todo',
        },
        disableAutoUpdate: {
            disabled: 'Auto Update Desactivado desde {{ source }}. Haga clic para activar',
            enabled: 'Auto Update Activado desde {{ source }}. Haga clic para desactivar',
            confirm: 'Este perfil se actualiza automáticamente desde {{ source }}.<br/>¿Quiere desactivar Auto Update y DENEGAR permanentemente el perfil?',
        },
        profileStatus: 'Estado',
        profileName: 'Perfil',
        allProfiles: 'Todos los Perfiles',
        viewDetails: 'Detalles del Perfil',
        viewApprovers: 'Aprobadores del Perfil',
        approverName: 'Actualizado Por',
        statusModifiedDate: 'En',
    },
    'account-information-scopes': {
        confirm: {
            yes: 'Sí, Proceder',
            no: 'No, Cancelar',
            denyProfile: {
                header: 'Denegar PERFIL',
                message: '¿Desea denegar el PERFIL?',
                confirm: 'Por favor confirme que desea DENEGAR el último perfil activo y desactivar permanentemente la cuenta',
            },
            denyProfiles: {
                header: 'Denegar PERFILES',
                message: '¿Te gustaría tomar acción sobre múltiples perfiles a la vez?',
            },
            approveProfiles: {
                header: 'Aprobar PERFILES',
                message: '¿Te gustaría tomar acción sobre múltiples perfiles a la vez?',
            },
            denyScope: {
                header: 'Denegar ALCANCE',
                message: '¿Te gustaría tomar acción sobre múltiples alcances a la vez?',
            },
            approveScope: {
                header: 'Aprobar ALCANCE',
                message: '¿Te gustaría tomar acción sobre múltiples alcances a la vez?',
            },
            denyAll: {
                header: 'Denegar Todos los PERFILES y ALCANCES',
                message: '¿Te gustaría tomar acción sobre todos los perfiles y todos los alcances a la vez?',
            },
            approveAll: {
                header: 'Aprobar Todos los PERFILES y ALCANCES',
                message: '¿Te gustaría tomar acción sobre todos los perfiles y todos los alcances a la vez?',
            },
        },
    },
    'account-information': {
        header: 'Cuenta de Usuario',
        canSyncB2C: 'Sincronizar B2C',
        userStatus: 'Estado',
        userRoles: 'Seleccionar Rol',
        roleName: 'Rol',
        roleStatus: 'Estado',
        roleAttributes: 'Atributos del Rol',
        roleTimespan: 'Período del Rol',
        roleIdbApps: 'Aplicaciones IDB',
        missingAccountActivation: 'Re-enviar Enlace de Activación de Cuenta',
        addRoleButton: {
            label: 'Agregar Nuevo Rol',
        },
        addRequestRoleButton: {
            label: 'Solicitar Nuevo Rol',
        },
        editRoleButton: {
            label: 'Editar Rol',
        },
        deleteRoleButton: {
            label: 'Eliminar Rol',
            title: 'El rol {{ roleName }} será eliminado permanentemente. Si este es el único rol activo, la cuenta será desactivada, lo que resultará en la pérdida de acceso a algunas aplicaciones del BID. ¿Está seguro de que desea continuar?',
        },
        viewIdbAppsButton: {
            showMore: 'Mostrar Más...',
            showLess: 'Mostrar Menos...',
        },
        role: {
            scopes: {
                scopesProfiles: 'Alcances y Autorizaciones de Perfil',
                affiliationsProfiles: 'Afiliaciones y Autorizaciones de Perfil',
                profiles: 'Autorizaciones de Perfil',
            },
        },
    },
    'context-role-scope-picker': {
        cancelButtonLabel: 'Cancelar',
        scope: {
            header: 'Agregar Alcance',
            addButtonLabel: 'Agregar Nuevo Alcance',
        },
        affiliation: {
            header: 'Agregar Afiliación',
            addButtonLabel: 'Agregar Nueva Afiliación',
        },
        exception: {
            header: 'Agregar Excepción',
            addButtonLabel: 'Agregar Nueva Excepción',
        },
    },
    'context-role-scope-picker-field': {
        all: 'Todos',
        remove: 'Eliminar',
        allSelected: {
            placeHolder: 'Todos los {{ value }} seleccionados',
        },
        selectAdditional: {
            placeHolder: 'Seleccionar {{ value }} adicionales',
        },
        selectingNotAllowed: {
            placeHolder: 'No se permite seleccionar {{ value }}',
        },
        selectThe: {
            placeHolder: 'Seleccionar el {{ value }}',
        },
    },
    'external-account-request': {
        title: 'Solicitud de Cuenta de Usuario Externo',
        nextButton: {
            label: 'Siguiente',
        },
    },
    'external-account-request-confirm': {
        header: 'Términos y Condiciones de la Extranet',
        acceptCheckbox: {
            label: 'Aceptar términos y condiciones',
        },
        acceptButton: {
            label: 'Aceptar',
        },
        declineButton: {
            label: 'Volver',
        },
    },
    'external-account-request-created': {
        title: 'Solicitud de Cuenta de Usuario Externo',
        sendVerification: {
            label: 'Enviar Correo de Verificación a AAA@BBB.CCC',
        },
        changeEmail: {
            label: 'Cambiar Dirección de Correo Electrónico',
        },
    },
    'external-account-request-resend-link': {
        header: 'Reenviar Enlace de Verificación',
        userEmail: {
            label: 'Correo Electrónico',
        },
        sendButton: {
            label: 'Enviar Correo',
        },
    },
    'external-account-request-role-details-editor': {
        and: 'y',
        preferredLanguage: {
            label: 'Idioma Preferido',
            placeholder: 'Seleccionar el Idioma Preferido',
        },
        relationship: {
            label: 'Mi Relación con el Banco Es',
            placeholder: 'Seleccionar la Relación',
        },
        scopesTableLabel: 'Seleccionar {{ value }}',
    },
    'external-verify-email': {
        title: 'Solicitud de Cuenta de Usuario Externo',
        footer: 'Gracias, Equipo del BID.',
        responseEmpty: 'Lo sentimos, tu acción generó un error y el soporte del sistema ha sido notificado. Por favor, inténtalo más tarde.',
        referenceNum: 'Referencia:',
    },
    'user-context-role-editor': {
        title: {
            roleName: 'Editar Rol {{ value }}',
            addNewRole: 'Agregar Nuevo Rol',
            addNewInternalUser: 'Agregar Nuevo Usuario IDB',
            addNewExternalUser: 'Agregar Nuevo Usuario Externo',
        },
        noCreateUserPermission: 'No tiene permisos para crear un nuevo usuario',
    },
    'user-context-role-details-editor': {
        preferredLanguage: {
            label: 'Idioma Preferido',
            placeholder: 'Seleccione el Idioma Preferido',
        },
        role: {
            title: 'Información del Rol',
            label: 'Rol',
            placeholder: 'Seleccione el Rol',
            grantedFrom: 'Rol Otorgado Desde',
            grantedTo: 'Rol Otorgado Hasta',
            canCreateAccount: 'Permitir al Usuario Crear Cuentas (Champion)',
            canRecertify: 'El Usuario Puede Recibir Notificaciones Relacionadas con Aprobaciones',
        },
        internal: {
            delete: {
                header: 'Eliminar Alcances Existente',
                button: 'Eliminar Alcance',
                undoButton: 'Deshacer Eliminación de Alcance',
                warning: 'Solo los alcances opcionales con todos los perfiles denegados están disponibles para eliminación',
            },
            add: {
                header: 'Editar Alcance',
                headerProfile: 'Editar Autorización de Alcance y Perfil',
                warning: 'Por favor, apruebe al menos un perfil para poder crear una nueva cuenta',
            },
            addScope: {
                header: 'Editar Alcance',
                headerProfile: 'Editar Autorización de Alcance y Perfil',
                button: 'Agregar Alcance',
                warning: 'Por favor, apruebe al menos un perfil para poder crear una nueva cuenta',
            },
        },
        external: {
            delete: {
                header: 'Eliminar Afiliaciones Existentes',
                button: 'Eliminar Afiliación',
                undoButton: 'Deshacer Eliminación de Afiliación',
                warning: 'Solo las afiliaciones opcionales con todos los perfiles denegados están disponibles para eliminación',
            },
            add: {
                header: 'Editar Afiliación',
                headerProfile: 'Editar Autorización de Afiliación y Perfil',
                warning: 'Por favor, apruebe al menos un perfil para poder crear una nueva cuenta',
            },
            addScope: {
                header: 'Editar Afiliación',
                headerProfile: 'Editar Autorización de Afiliación y Perfil',
                button: 'Agregar Afiliación',
                warning: 'Por favor, apruebe al menos un perfil para poder crear una nueva cuenta',
            },
        },
    },
    'user-context-role-external-account-editor': {
        title: 'Información de la Cuenta',
        email: 'Correo electrónico',
        retypeEmail: 'Confirmar Correo Electrónico',
        secondaryEmail: 'Correo electrónico secundario',
    },
    'user-context-role-external-info-editor': {
        personalTitle: 'Información Personal',
        contactTitle: 'Información de Contacto',
        firstName: 'Nombre',
        middleName: 'Segundo Nombre',
        lastName: 'Apellido',
        phone: 'Teléfono',
        mobile: 'Móvil',
        fax: 'Fax',
    },
    'user-context-role-internal-account-editor': {
        title: 'Información de la Cuenta',
        searchUser: 'Buscar Usuario',
    },
    'user-context-role-internal-info-editor': {
        title: 'Información de la Cuenta IDB',
        email: 'Correo Electrónico',
        name: 'Nombre',
    },
    'my-work': {
        myWorkFilter: {
            label: 'Seleccionar un filtro de trabajo',
        },
        filterText: {
            label: 'Texto de Búsqueda',
        },
        clearAllFilters: {
            label: 'Limpiar todos los filtros',
        },
        columnsTypeMore: {
            label: 'Mostrar Más',
        },
        columnsTypeLess: {
            label: 'Mostrar Menos',
        },
        export: {
            label: 'Exportar',
            xslx: {
                label: 'Exportar a XLSX',
            },
            csv: {
                label: 'Exportar a CSV',
            },
        },
        columns: {
            userEmail: {
                header: 'Correo Electrónico',
                details: {
                    tooltip: 'Detalles de la Cuenta',
                },
            },
            userRoleName: {
                header: 'Rol',
                details: {
                    tooltip: 'Detalles del Rol',
                },
            },
            userProfileName: {
                header: 'Perfil',
                details: {
                    tooltip: 'Detalles del Perfil',
                },
                approvers: {
                    tooltip: 'Aprobadores del Perfil',
                },
                history: {
                    tooltip: 'Historial del Perfil',
                },
            },
            userApprovalStatus: {
                header: 'Estado de Aprobación',
                approve: {
                    tooltip: 'Aprobar',
                },
                deny: {
                    tooltip: 'Denegar',
                },
            },
            userFullName: {
                header: 'Nombre',
            },
            userStatus: {
                header: 'Estado de Usuario',
            },
            userContextName: {
                header: 'Contexto',
            },
            userRoleStatus: {
                header: 'Estado del Rol',
            },
            userAssociationValues: {
                header: 'Alcance',
            },
            userAssociationType: {
                header: 'Tipo de Alcance',
            },
            aging: {
                header: 'Antigüedad',
            },
            approverEmail: {
                header: 'Aprobador/Asignado a',
            },
            approverRoleName: {
                header: 'Rol del Aprobador/Asignado a',
            },
            userApprovalActionDt: {
                header: 'Fecha de Aprobación',
            },
            userType: {
                header: 'Tipo de Usuario',
            },
            requestDt: {
                header: 'Fecha de Solicitud',
            },
            requestId: {
                header: 'N.° de Solicitud',
            },
        },
    },
    'user-directory': {
        filterText: {
            label: 'Nombre o Correo Electrónico',
        },
        clearAllFilters: {
            label: 'Limpiar todos los filtros',
        },
        columnsTypeMore: {
            label: 'Mostrar Más',
        },
        columnsTypeLess: {
            label: 'Mostrar Menos',
        },
        export: {
            label: 'Exportar',
            xslx: {
                label: 'Exportar a XLSX',
            },
            csv: {
                label: 'Exportar a CSV',
            },
        },
        addNewUser: {
            label: 'Agregar Nuevo Usuario',
            internal: {
                label: 'Nuevo Usuario Interno',
            },
            external: {
                label: 'Nuevo Usuario Externo',
            },
            externalForm: {
                label: 'Solicitud de Nueva Cuenta de Usuario Externo',
            },
        },
        columns: {
            userEmail: {
                header: 'Correo Electrónico',
                details: {
                    tooltip: 'Detalles de la Cuenta',
                },
            },
            userFullName: {
                header: 'Nombre',
            },
            userStatus: {
                header: 'Estado de Usuario',
            },
            userRoleNames: {
                header: 'Roles Activos',
            },
            userPendingEmailValidationRoleNames: {
                header: 'Roles Pendientes de Validación de Correo Electrónico',
            },
            userScopeValues: {
                header: 'Alcance',
            },
            userScopeType: {
                header: 'Tipo de Alcance',
            },
            userActiveProfileNames: {
                header: 'Perfiles Activos',
            },
            userPendingProfileNames: {
                header: 'Perfiles Pendientes',
            },
            userDeniedProfileNames: {
                header: 'Perfiles Denegados',
            },
            userLastUpdatedDt: {
                header: 'Última Fecha de Actualización',
            },
            userIdbApps: {
                header: 'Aplicaciones IDB',
            },
            userType: {
                header: 'Tipo de Usuario',
            },
            userIsReadOnly: {
                header: 'Solo Lectura',
            },
            userIsApprover: {
                header: 'Es Aprobador',
            },
            canEdit: {
                header: 'Puede Crear Cuentas',
            },
        },
    },
    reports: {
        reportFilter: {
            label: 'Seleccionar Reporte',
        },
        roles: {
            label: 'Roles',
        },
        roleApprovers: {
            label: 'Aprobadores de Rol, Perfiles Elegibles y Aplicaciones',
        },
        contexts: {
            label: 'Contextos',
        },
        userRoleDataSets: {
            label: 'Conjuntos de Datos de Rol',
        },
    },
    'user-role-datasets': {
        userRoleDatasetsFilter: {
            label: 'Seleccionar un Rol',
        },
    },
    'user-approver-roles': {
        columns: {
            roleName: {
                header: 'Rol',
            },
            contextName: {
                header: 'Contexto',
            },
            profileName: {
                header: 'Perfil',
            },
            approverRoleName: {
                header: 'Nombre del Rol de Aprobador',
            },
            approverRoleLevel: {
                header: 'Nivel de Aprobación',
            },
            idbApps: {
                header: 'Aplicaciones IDB',
            },
        },
    },
    'user-contexts': {
        columns: {
            contextCode: {
                header: 'Código de Contexto',
            },
            contextName: {
                header: 'Nombre de Contexto',
            },
            contextDescription: {
                header: 'Descripción de Contexto',
            },
            contextProfiles: {
                header: 'Perfiles de Contexto',
            },
            requestReason: {
                header: 'Motivo de la Solicitud',
            },
            requestExpirationDays: {
                header: 'Días de Expiración de la Solicitud',
            },
            redirectToApplicationName: {
                header: 'Redirigir al Nombre de Aplicación',
            },
            redirectUrl: {
                header: 'URL de Redirección',
            },
            allowManualUserCreation: {
                header: 'Permitir Creación Manual de Usuario',
            },
        },
    },
    'user-roles': {
        columns: {
            roleName: {
                header: 'Rol',
            },
            contextName: {
                header: 'Contexto',
            },
            requiredFields: {
                header: 'Campos Obligatorios',
            },
            optionalFields: {
                header: 'Campos Opcionales',
            },
            isReadOnly: {
                header: 'Solo Lectura',
            },
            isApprover: {
                header: 'Es Aprobador',
            },
            isInternal: {
                header: 'Es Interno',
            },
            allowMultipleScopes: {
                header: 'Permitir Múltiples Alcances o Afiliaciones',
            },
        },
    },
    'user-history-view-dialog': {
        header: 'Historial del Usuario',
        columns: {
            action: {
                header: 'Acción',
            },
            actionPerformedBy: {
                header: 'Acción Realizada Por',
            },
            actionPerformedDt: {
                header: 'Acción Realizada En',
            },
        },
    },
    'user-context-role-profile-access-details-dialog': {
        header: 'Detalles de Acceso',
    },
    'user-context-role-scope-profile': {
        approved: {
            permissions: 'Permisos',
        },
        denied: {
            reason: 'Motivo de Denegación',
            enableUpdares: 'Permitir que el perfil se actualice automáticamente por {{ processName }}',
            disableUpdares: 'Desactivar actualizaciones automáticas por {{ processName }} y mantener el perfil DENEGADO',
        },
        pending: {
            keepPendingTill: 'Mantener en mi bandeja de trabajo hasta',
            pendingAssignedTo: '{{ toName }} ({{ toEmail }}) se comprometió a aprobar esta solicitud pendiente el {{ from }}',
            isAssignedToMe: 'Reasignar la aprobación a mí',
        },
    },
    'login-as-user': {
        header: 'Iniciar Sesión Como',
        searchUser: 'Por Favor Seleccione un Usuario Activo para Iniciar Sesión Como',
        loginButton: 'Iniciar Sesión',
    },
};
