export interface ContextDTO {
    contextCode?: string;
    contextName?: string;
    contextDescription?: string;
    contextProfiles?: string;
    requestReason?: string;
    requestExpirationDays?: string;
    redirectToApplicationName?: string;
    redirectUrl?: string;
    allowExternalRequests?: string;
    allowManuelUserCreation?: string;
}
