import { ResponseDTO } from './response-dto';

export interface ResendUserContextRoleRequestResponseDTO extends ResponseDTO {
    requestKey?: string;
}

export interface EmailValidationResponseDTO {
    response?: string;
    languageCd?: string;
}

export interface SaveEmailValidationResponseDTO extends ResponseDTO {
    languageCd?: string;
    responses?: EmailValidationResponseDTO[];
}
