import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isAssigned } from '../../utils';

export interface SwitchChangedEvent {
    oldValue: any | undefined;
    newValue: any | undefined;
}

@Component({
    selector: 'idb-switch',
    templateUrl: './switch.component.html',
})
export class SwitchComponent {
    @Input() readonly = false;
    @Input() disabled = false;
    @Input() loading = false;

    @Input() trueValue: any | undefined = true;
    @Input() falseValue: any | undefined = false;
    @Input() unknownValue: any | undefined;

    @Input() trueTooltip = '';
    @Input() falseTooltip = '';

    @Input() value: any | undefined = true;
    @Output() valueChange = new EventEmitter<SwitchChangedEvent>();

    get trueTooltipDisplay(): string {
        return !this.disabled ? this.trueTooltip : '';
    }

    get falseTooltipDisplay(): string {
        return !this.disabled ? this.falseTooltip : '';
    }

    get isUnknown(): boolean {
        return this.value === this.unknownValue || !isAssigned(this.value);
    }

    get isTrue(): boolean {
        return this.value === this.trueValue;
    }

    get isFalse(): boolean {
        return this.value === this.falseValue;
    }

    onClick(value: any | undefined) {
        if (this.readonly || this.disabled || this.loading) return;

        if (value !== this.value) {
            const $event: SwitchChangedEvent = {
                oldValue: this.value,
                newValue: value,
            };

            this.value = value;

            this.valueChange.emit($event);
        }
    }
}
