import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
    BaseComponent,
    LoginAsUserDTO,
    MetaService,
    UnknownErrorCallingAPIMessage,
    UserDirectoryDTO,
    UserDirectoryRequestDTO,
    UserDirectoryResponseDTO,
    UserDirectoryService,
    isAssigned,
    isEmailValid,
    isEmpty,
} from '../../data';

const ComponentName = 'login-as-user';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: './login-as-user.component.html',
    styleUrls: ['./login-as-user.component.scss'],
    providers: [ConfirmationService],
    host: {
        class: 'flex flex-column',
        style: 'width: 35rem; max-width: 35rem; height: 12rem; max-height: 12rem;',
    },
})
export class LoginAsUserComponent extends BaseComponent {
    private _usersResp: UserDirectoryResponseDTO = {};
    private _requestSubs?: Subscription;

    isLoadingUsers = false;
    searchUserEmail: string | undefined;
    user?: LoginAsUserDTO;

    constructor(
        private readonly _userDirectorySrv: UserDirectoryService,
        private readonly _dialogCfg: DynamicDialogConfig,
        private readonly _dialogRef: DynamicDialogRef,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(toastr, metaSrv, location, translateSrv);
        this._translateKey = ComponentName;
    }

    get users(): UserDirectoryDTO[] {
        return this._usersResp.rows ?? [];
    }

    get isValidated(): boolean {
        return !isEmpty(this.user?.userEmail) && isEmailValid(this.user!.userEmail);
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this.isLoadingUsers;
    }

    protected override onDestroy(): void {
        if (isAssigned(this._requestSubs)) {
            this._requestSubs!.unsubscribe();
        }

        super.onDestroy();
    }

    onCancel() {
        this._dialogRef.close();
    }

    onSave() {
        this._dialogRef.close(this.user);
    }

    readUsers(value: string) {
        if (isAssigned(this._requestSubs)) {
            return;
        }

        this.isLoadingUsers = true;
        this.emitOnLoading();

        this._usersResp = {};

        const request: UserDirectoryRequestDTO = {
            languageCd: this.translateSrv.currentLang,
            filterText: value,
            userStatus: 'ACTIVE',
            pageSize: 10,
            pageNumber: 1,
        };

        this._requestSubs = this._userDirectorySrv.readUsers(request).subscribe({
            next: (response) => {
                this.isLoadingUsers = false;
                this.emitOnLoading();

                this.showErrorMessage(response);

                this._usersResp = response ?? {};

                this._requestSubs = undefined;
            },
            error: (error) => {
                this.isLoadingUsers = false;
                this.emitOnLoading();

                this.toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
            },
        });
    }

    onSearchUser($e: any) {
        this.readUsers($e.query);
    }

    onSelectUser($e: any) {
        const user = $e.value as UserDirectoryDTO;

        this.user = {
            userEmail: user.userEmail!,
            userName: user.userFullName!,
        };
    }

    onClearUser($e: any) {
        this.user = undefined;
    }
}

export function showLoginAsUser(dialogSrv: DialogService, onLogin: (user: LoginAsUserDTO) => void) {
    const ref = dialogSrv.open(LoginAsUserComponent, {
        showHeader: false,
        contentStyle: { overflow: 'hidden' },
    });

    ref.onClose.subscribe((value) => {
        if (isAssigned(value)) {
            onLogin(value);
        }
    });
}
