import { HttpErrorResponse, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

import { blobToString, filterAssignedData, isAssigned, isEmpty } from '../utils';

import { environment } from '../../../environments/environment';
import { PerformedByRequestDTO, RequestDTO } from '../dtos';
import { GlobalsService } from './globals.service';

export const UnknownErrorCallingAPIMessage = 'Unknow error calling backend API';

export class BaseService {
    private _apiUrl: string;

    constructor(private readonly _globalsSrv: GlobalsService, private readonly _authSrv: MsalService, apiUrl?: string) {
        this._apiUrl = apiUrl ?? environment.baseurl;
    }

    protected get globalsSrv(): GlobalsService {
        return this._globalsSrv;
    }

    protected get authSrv(): MsalService {
        return this._authSrv;
    }

    protected get apiUrl(): string {
        return this._apiUrl;
    }

    protected onHttpError(error: HttpErrorResponse) {
        let message = error.message;

        if (error.error instanceof ProgressEvent && error.error.type === 'error') {
            message = 'Error with the connection with the API';
        } else if (error.error instanceof Blob) {
            const value = blobToString(error.error);
            const obj = JSON.parse(value);
            message = obj.errorMessage ?? obj.title;
        } else if (isAssigned(error)) {
            message = error.error.errorMessage ?? error.error.title;
        }

        if (!isAssigned(message) || message === '') {
            message = error.statusText;
        }

        return throwError(() => new Error(message));
    }

    protected getHttpParams<TRequest extends RequestDTO>(request?: TRequest): HttpParams {
        if (isAssigned(request)) {
            return new HttpParams({
                fromObject: this.fillRequestData(request) as any,
                encoder: new HttpUrlEncodingCodec(),
            });
        }

        return new HttpParams({
            encoder: new HttpUrlEncodingCodec(),
        });
    }

    protected getHttpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        });
    }

    protected fillRequestData<TRequest extends PerformedByRequestDTO>(request?: TRequest): TRequest | undefined {
        if ((request as any).dontSendActionPerformedBy === true) {
            (request as any).dontSendActionPerformedBy = undefined;
        } else if (isEmpty(request!.actionPerformedBy)) {
            const currentUser = this._globalsSrv.currentUser;
            if (!isEmpty(currentUser?.userEmail)) {
                request!.actionPerformedBy = currentUser!.userEmail;
            }
        }

        return filterAssignedData(request);
    }
}
