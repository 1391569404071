export interface UserContextRoleProfileStatusPermissionDTO {
    permissionCd?: string;
    permissionName?: string;
    isSelected?: boolean;
}

export interface UserContextRoleProfileStatusDTO {
    profileCd?: string;
    profileName?: string;
    profileStatus?: string;
    profileStatusLabel?: string;
    approverName?: string;
    statusModifiedDate?: Date;
    deniedReason?: string;
    pendingRequestAssignedTillDt?: Date;
    pendingRequestAssignedTillDtLabel?: string;
    pendingAssignedToEmail?: string;
    pendingAssignedToName?: string;
    canEdit?: boolean;
    allowMultiplePermissions?: boolean;
    isAssignedToMe?: boolean;
    disableAutomaticUpdates?: boolean;
    processName?: string;

    permissions?: UserContextRoleProfileStatusPermissionDTO[];
}
