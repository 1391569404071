import { Component } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    host: {
        class: 'header container',
    },
})
export class AppHeaderComponent {
    title = 'Extranet Account Provisioning System';

    constructor() {}
}
