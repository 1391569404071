import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ContextRoleService, MetaService, SaveUserContextRoleProfileRequestDTO, isAssigned, isEmpty } from '../../data';
import { UserContextRoleScopeProfileBase } from './user-context-role-scope-profile-base';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
    selector: 'app-user-context-role-scope-profile-pending',
    templateUrl: './user-context-role-scope-profile-pending.component.html',
    styleUrls: ['./user-context-role-scope-profile-pending.component.scss'],
    providers: [ConfirmationService, DynamicDialogRef],
})
export class UserContextRoleScopeProfilePendingComponent extends UserContextRoleScopeProfileBase {
    hasAnyChanged = false;
    isAssignedToMe = false;
    assignedTillDate?: Date;

    constructor(
        confirmSrv: ConfirmationService,
        dialogSrv: DialogService,
        contextRoleSrv: ContextRoleService,
        dialogRef: DynamicDialogRef,
        dialogCfg: DynamicDialogConfig,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(confirmSrv, dialogSrv, contextRoleSrv, dialogRef, dialogCfg, translateSrv, toastr, metaSrv, location);

        this._translateKey = `${this._translateKey}.pending`;
    }

    get isSaveEnabled(): boolean {
        if (!this.isLoading && this.profile.canEdit === true && this.hasAnyChanged) {
            return this.isAssignedToMe ? isAssigned(this.assignedTillDate) : true;
        }

        return false;
    }

    get pendingAssignedToVisible(): boolean {
        return this.profile?.isAssignedToMe !== true && !isEmpty(this.profile.pendingAssignedToName);
    }

    get pendingAssignedTo(): string {
        if (this.pendingAssignedToVisible) {
            const prm = {
                from: this.profile.pendingRequestAssignedTillDtLabel ?? '',
                toName: this.profile.pendingAssignedToName ?? '',
                toEmail: this.profile.pendingAssignedToEmail ?? '',
            };

            return this.translate('pendingAssignedTo', prm);
        }

        return '';
    }

    override onReadProfilePermissions(): void {
        if (this.profile.canEdit === true) {
            this.isAssignedToMe = this.profile.isAssignedToMe === true;
            this.assignedTillDate = this.isAssignedToMe ? new Date(this.profile.pendingRequestAssignedTillDt as any) : undefined;
        } else {
            this.isAssignedToMe = false;
            this.assignedTillDate = undefined;
        }

        this.hasAnyChanged = false;
    }

    onIsAssignedToMeChamge($e: CheckboxChangeEvent) {
        if ($e.checked !== true) {
            this.assignedTillDate = undefined;
        }

        this.hasAnyChanged = true;
    }

    onTillDateChamge($e: any) {
        this.hasAnyChanged = true;
    }

    onSave() {
        const request: SaveUserContextRoleProfileRequestDTO = {
            profileStatus: 'pending',
        };

        if (this.isAssignedToMe) {
            request.keepInMyWorkbasketTill = this.assignedTillDate?.toISOString();
        }

        this.saveProfilePermissions(request);
    }
}
