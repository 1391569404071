import { Location } from '@angular/common';
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { cloneDeep } from 'lodash-es';

import {
    EditorBaseComponent,
    MetaService,
    ReadUserRequestDTO,
    UnknownErrorCallingAPIMessage,
    UserAccountInformationDTO,
    UserContextRoleDTO,
    UserContextRoleInformationDTO,
    UsersService,
    isAssigned,
    isEmailValid,
} from '../../data';

@Component({ selector: 'app-user-context-role-account-editor-base', template: '' })
export class UserContextRoleAccountEditorBase extends EditorBaseComponent {
    protected _isInternal = false;

    isLoadingCheckUserEmail = false;
    userEmailExists?: boolean;

    @Input()
    isNewUser = false;

    @Input()
    canEdit = false;
    get cannotEdit(): boolean {
        return !this.canEdit;
    }

    private _accountInfo: UserAccountInformationDTO = {};
    @Input()
    set accountInfo(ai: UserAccountInformationDTO | undefined) {
        this._accountInfo = ai ?? {};
        this._accountInfo.isInternal = this._isInternal;

        if (!isAssigned(this._contextRole.information)) {
            this._contextRole.information = { userRoleContextId: this._contextRole.userRoleContextId };
        }
        this._contextRole.information!.userEmail = this._contextRole.information?.userEmail ?? this._accountInfo.userEmail;

        this.emitOnChanged();
    }
    get accountInfo(): UserAccountInformationDTO {
        return this._accountInfo;
    }

    private _contextRole: UserContextRoleDTO = { userRoleContextId: -1 };
    private _contextRoleO: UserContextRoleDTO = { userRoleContextId: -1 };
    @Input()
    set contextRole(cr: UserContextRoleDTO | undefined) {
        this._contextRole = cr ?? { userRoleContextId: -1 };

        if (!isAssigned(this._contextRole.information)) {
            this._contextRole.information = { userRoleContextId: this._contextRole.userRoleContextId };
        }
        this._contextRole.information!.userEmail = this._contextRole.information?.userEmail ?? this._accountInfo.userEmail;

        this._contextRoleO = cloneDeep(this._contextRole);
        this.emitOnChanged();
    }
    get contextRole(): UserContextRoleDTO {
        return this._contextRole;
    }
    get contextRoleO(): UserContextRoleDTO {
        return this._contextRoleO;
    }

    get contextRoleInfo(): UserContextRoleInformationDTO {
        return this._contextRole.information ?? { userRoleContextId: this._contextRole.userRoleContextId };
    }

    constructor(translateSrv: TranslateService, toastr: ToastrService, metaSrv: MetaService, location: Location) {
        super(translateSrv, toastr, metaSrv, location);
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this.isLoadingCheckUserEmail;
    }

    get isEmailValid(): boolean {
        return (
            isAssigned(this.contextRole.information?.userEmail) &&
            this.contextRole.information?.userEmail !== '' &&
            isEmailValid(this.contextRole.information!.userEmail!)
        );
    }

    override get hasChanged(): boolean {
        return (
            this.contextRole.information?.userEmail !== this.contextRoleO.information?.userEmail ||
            this.contextRole.information?.secondaryEmail !== this.contextRoleO.information?.secondaryEmail
        );
    }

    protected checkUserEmail(toastr: ToastrService, userSrv: UsersService) {
        this.userEmailExists = undefined;
        this.emitOnLoading();
        this.emitOnChanged();

        if (this.isEmailValid) {
            this.isLoadingCheckUserEmail = true;
            this.emitOnLoading();

            const request: ReadUserRequestDTO = { userEmail: this._contextRole.information?.userEmail };

            userSrv.readUser(request).subscribe({
                next: (response) => {
                    this.isLoadingCheckUserEmail = false;

                    this.userEmailExists =
                        isAssigned(response?.accountInformation?.userEmail) &&
                        response?.accountInformation?.userEmail?.toLowerCase() === this._contextRole.information?.userEmail?.toLowerCase();

                    this.emitOnLoading();
                    this.emitOnChanged();
                },
                error: (error) => {
                    this.userEmailExists = undefined;
                    this.isLoadingCheckUserEmail = false;
                    this.emitOnLoading();

                    toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
                },
            });
        }
    }
}
