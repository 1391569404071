import { PerformedByRequestDTO } from './performed-by-request-dto';
import { TypeScopeDTO } from './type-scope-dto';

export interface ReadContextRoleScopeProfilesRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
    contextCd?: string;
    roleCd?: string;
    languageCd?: string;
    scope?: TypeScopeDTO[];
}
