import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ContextRoleService, MetaService, SaveUserContextRoleProfileRequestDTO, isAssigned, isEmpty } from '../../data';
import { UserContextRoleScopeProfileBase } from './user-context-role-scope-profile-base';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-context-role-scope-profile-denied',
    templateUrl: './user-context-role-scope-profile-denied.component.html',
    styleUrls: ['./user-context-role-scope-profile-denied.component.scss'],
    providers: [ConfirmationService, DynamicDialogRef],
})
export class UserContextRoleScopeProfileDeniedComponent extends UserContextRoleScopeProfileBase {
    private _denyDirectly: boolean;

    constructor(
        confirmSrv: ConfirmationService,
        dialogSrv: DialogService,
        contextRoleSrv: ContextRoleService,
        dialogRef: DynamicDialogRef,
        dialogCfg: DynamicDialogConfig,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(confirmSrv, dialogSrv, contextRoleSrv, dialogRef, dialogCfg, translateSrv, toastr, metaSrv, location);

        this._translateKey = `${this._translateKey}.denied`;

        this._denyDirectly = this.dialogCfg.data?.denyDirectly === true;
    }

    get isDenyValidated(): boolean {
        return !this.isLoading && (isAssigned(this.profile.disableAutomaticUpdates) ? true : !isEmpty(this.profile?.deniedReason));
    }

    override readProfilePermissions(): void {
        if (this._denyDirectly) {
            this.profile = { canEdit: true };
        } else {
            super.readProfilePermissions();
        }
    }

    getButtonIcon(val: boolean): string {
        return this.profile.disableAutomaticUpdates === val ? 'pi pi-check' : 'pi';
    }

    onSave() {
        const request: SaveUserContextRoleProfileRequestDTO = {
            profileStatus: 'denied',
            deactivationReason: this.profile.deniedReason,
            disableAutomaticUpdates: this.profile.disableAutomaticUpdates,
        };

        this.saveProfilePermissions(request);
    }
}
