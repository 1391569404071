import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { ChangedEvent } from '../utils';
import { MetaService } from '../services';

import { BaseComponent } from './base.component';

@Component({ selector: 'app-editor-base-componet', template: '' })
export class EditorBaseComponent extends BaseComponent {
    @Output()
    onChanged = new EventEmitter<ChangedEvent>();

    constructor(translateSrv: TranslateService, toastr: ToastrService, metaSrv: MetaService, location: Location) {
        super(toastr, metaSrv, location, translateSrv);
    }

    protected get changedEvent(): ChangedEvent {
        return { hasChanged: this.hasChanged, isValidated: this.isValid };
    }

    protected emitOnChanged() {
        const ce = this.changedEvent;
        this.onChanged.emit(ce);
    }

    get hasChanged(): boolean {
        return false;
    }

    get isValid(): boolean {
        return true;
    }

    onAnyChange($e: any) {
        this.emitOnChanged();
    }
}
