import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ContextRoleService, MetaService, SaveUserContextRoleProfileRequestDTO, confirmAction, isAssigned, isEmpty } from '../../data';
import { UserContextRoleScopeProfileBase } from './user-context-role-scope-profile-base';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-context-role-scope-profile-denied',
    templateUrl: './user-context-role-scope-profile-denied.component.html',
    styleUrls: ['./user-context-role-scope-profile-denied.component.scss'],
    providers: [ConfirmationService, DynamicDialogRef],
})
export class UserContextRoleScopeProfileDeniedComponent extends UserContextRoleScopeProfileBase {
    private _denyDirectly: boolean;
    private _disableAutoUpdateSource?: string;
    private _deniedReason?: string;

    constructor(
        confirmSrv: ConfirmationService,
        dialogSrv: DialogService,
        contextRoleSrv: ContextRoleService,
        dialogRef: DynamicDialogRef,
        dialogCfg: DynamicDialogConfig,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(confirmSrv, dialogSrv, contextRoleSrv, dialogRef, dialogCfg, translateSrv, toastr, metaSrv, location);

        this._translateKey = `${this._translateKey}.denied`;

        this._denyDirectly = this.dialogCfg.data?.denyDirectly === true;
        this._disableAutoUpdateSource = this.dialogCfg.data?.disableAutoUpdateSource;
    }

    get isDenyValidated(): boolean {
        return !this.isLoading && this._deniedReason !== this.profile.deniedReason && !isEmpty(this.profile?.deniedReason);
    }

    override readProfilePermissions(): void {
        if (this._denyDirectly) {
            this.profile = { canEdit: true };
        } else {
            super.readProfilePermissions();
        }
    }

    override onReadProfilePermissions(): void {
        super.onReadProfilePermissions();

        this._deniedReason = this.profile?.deniedReason;
    }

    onSave() {
        const request: SaveUserContextRoleProfileRequestDTO = {
            profileStatus: 'denied',
            deactivationReason: this.profile.deniedReason,
            disableAutomaticUpdate: true,
        };

        if (!isEmpty(this._disableAutoUpdateSource)) {
            confirmAction(this.confirmSrv, {
                header: this.translateDirect('account-information-scopes.confirm.denyProfile.header'),
                message: this.translateDirect('profile.disableAutoUpdate.confirm', { source: this._disableAutoUpdateSource }),
                acceptLabel: this.translateDirect('confirm'),
                cancelLabel: this.translateDirect('cancel'),
                onAccept: () => this.saveProfilePermissions(request),
                onCancel: () => this.onCancel(),
            });
        } else {
            this.saveProfilePermissions(request);
        }
    }
}
