import { PerformedByRequestDTO } from './performed-by-request-dto';

export interface ReadContextRoleScopePickerRequestBaseDTO extends PerformedByRequestDTO {
    userEmail?: string;
    contextCd?: string;
    roleCd?: string;
    userRoleContextId?: number;
    userRoleContextProfileId?: number;
    usage?: string;
    languageCd?: string;
}
