export interface UserContextRoleProfilePermissionValueDTO {
    field1?: string;
    field2?: string;
    field3?: string;
    field4?: string;
    field5?: string;
    field6?: string;
    field7?: string;
    field8?: string;
    field9?: string;
    field10?: string;
    field11?: string;
    field12?: string;
    field13?: string;
    field14?: string;
    field15?: string;
    field16?: string;
    field17?: string;
    field18?: string;
    field19?: string;
    field20?: string;

    field1Value?: string;
    field2Value?: string;
    field3Value?: string;
    field4Value?: string;
    field5Value?: string;
    field6Value?: string;
    field7Value?: string;
    field8Value?: string;
    field9Value?: string;
    field10Value?: string;
    field11Value?: string;
    field12Value?: string;
    field13Value?: string;
    field14Value?: string;
    field15Value?: string;
    field16Value?: string;
    field17Value?: string;
    field18Value?: string;
    field19Value?: string;
    field20Value?: string;

    permissionCd?: string;
}
