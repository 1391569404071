import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';

import { getIsValidClass, isAssigned, isEmpty } from '../utils';

import { ColumnViewDef } from '../view';

@Component({ selector: 'app-translate-base-componet', template: '' })
export class TranslateBaseComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    private _langSub?: Subscription;

    protected _translateKey = '';

    showComponent = true;

    constructor(private readonly _translateSrv: TranslateService) {}

    protected get translateSrv(): TranslateService {
        return this._translateSrv;
    }

    private _isRendered = false;
    get isRendered(): boolean {
        return this._isRendered;
    }

    protected checkIfRendered(): boolean {
        return true;
    }

    protected onInit(): void {}

    protected onDestroy(): void {}

    protected onViewInit(): void {}

    protected onViewChecked(): void {}

    protected onRendered(): void {}

    protected onLangChanged(): void {}

    private checkRendered() {
        if (!this._isRendered) {
            this._isRendered = this.checkIfRendered();
            if (this._isRendered) {
                this.onRendered();
            }
        }
    }

    ngOnInit(): void {
        this._langSub = this.translateSrv.onLangChange.subscribe(($e) => {
            this.showComponent = false;

            setTimeout(() => {
                this.showComponent = true;
                this.onLangChanged();
            }, 0);
        });

        this.onLangChanged();

        this.onInit();
    }

    ngOnDestroy(): void {
        this.onDestroy();

        this._langSub?.unsubscribe();
        this._langSub = undefined;
    }

    ngAfterViewInit(): void {
        this.checkRendered();

        this.onViewInit();
    }

    ngAfterViewChecked(): void {
        this.checkRendered();

        this.onViewChecked();
    }

    get lang(): string {
        return this._translateSrv.currentLang;
    }

    get isEN(): boolean {
        return this.lang === 'en';
    }

    get isES(): boolean {
        return this.lang === 'es';
    }

    get isPT(): boolean {
        return this.lang === 'pt';
    }

    get isFR(): boolean {
        return this.lang === 'fr';
    }

    translateKey(key: string): string {
        return `${this._translateKey}.${key}`;
    }

    translate(key: string, params?: any): string {
        const text = this._translateSrv.instant(this.translateKey(key), params);

        return text;
    }

    translateDirect(key: string, params?: any): string {
        const text = this._translateSrv.instant(key, params);

        return text;
    }

    translateColHeader(key: string): string {
        return this.translate(`columns.${key}.header`);
    }

    translateColTooltip(key: string): string {
        return this.translate(`columns.${key}.tooltip`);
    }

    translateColumns(columnDef: ColumnViewDef[]): void {
        columnDef.forEach((col) => {
            col.displayHeader = () => this.translateColHeader(col.name);
        });
    }

    isAssigned(obj: any): boolean {
        return isAssigned(obj);
    }

    isEmpty(val: string | undefined): boolean {
        return isEmpty(val);
    }

    getIsValidClass(isValid: boolean): string {
        return getIsValidClass(isValid);
    }
}
