import { Injectable, OnDestroy } from '@angular/core';

import { format, subDays } from 'date-fns';

import { deserializeFromBase64, isAssigned, localStorageRemoveItemsWithPrefix, serializeToBase64 } from '../utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginAsUserDTO } from '../dtos';
import { AnyKey } from '../view';

export class AccessPermissions {
    private _permissions: any = {};

    constructor(permissions?: string[]) {
        if (isAssigned(permissions)) {
            permissions?.forEach((p) => {
                const kn = p as AnyKey;
                (this._permissions as any)[kn] = true;
            });
        }
    }

    get workTab(): boolean {
        return this._permissions.workTab ?? false;
    }

    get directoryTab(): boolean {
        return this._permissions.directoryTab ?? false;
    }

    get reportsTab(): boolean {
        return this._permissions.reportsTab ?? false;
    }

    get createInternalUser(): boolean {
        return this._permissions.createInternalUser ?? false;
    }

    get createExternalUser(): boolean {
        return this._permissions.createExternalUser ?? false;
    }

    get createExternalRequest(): boolean {
        return this._permissions.createExternalRequest ?? false;
    }

    get loginAs(): boolean {
        return this._permissions.loginAs ?? false;
    }

    get sendAccountActivationLink(): boolean {
        return this._permissions.sendAccountActivationLink ?? false;
    }
}

@Injectable({ providedIn: 'root' })
export class GlobalsService implements OnDestroy {
    private readonly _appname = 'eaps';
    private readonly _date = new Date();
    private readonly _temporalTimetampPrefix: string;
    private readonly _temporalStateKeyPrefix: string;

    private _permissions = new AccessPermissions();

    private languageChangeSubject: BehaviorSubject<string>;
    languageChange$: Observable<string>;

    private currentUserChangeSubject: BehaviorSubject<LoginAsUserDTO | undefined>;
    currentUserChange$: Observable<LoginAsUserDTO | undefined>;

    constructor() {
        const dt = subDays(this._date, 1);

        this.clearBeforeToday();

        this._temporalTimetampPrefix = format(this._date, 'yyyyMMdd.HHmmss');
        this._temporalStateKeyPrefix = `${this._appname}.${this._temporalTimetampPrefix}`;

        this.languageChangeSubject = new BehaviorSubject<string>(this.language);
        this.languageChange$ = this.languageChangeSubject.asObservable();

        this.currentUserChangeSubject = new BehaviorSubject<LoginAsUserDTO | undefined>(undefined);
        this.currentUserChange$ = this.currentUserChangeSubject.asObservable();
    }

    ngOnDestroy(): void {
        this.clearBeforeToday();

        this.clearTemporal();
    }

    get language(): string {
        return this.getValue('language') ?? 'en';
    }
    set language(lang: string) {
        this.setValue('language', lang);
        this.languageChangeSubject.next(lang);
    }

    private clearBeforeToday(): void {
        const prefix = `${this._appname}.`;
        const dt = `${prefix}${format(this._date, 'yyyyMMdd')}`;
        const keysToRemove = Object.keys(localStorage)
            .filter((key) => key.startsWith(prefix))
            .filter((key) => {
                const k = key.substring(0, dt.length);

                return k < dt;
            });

        keysToRemove.forEach((key) => {
            localStorage.removeItem(key);
        });
    }

    clearTemporal(): void {
        localStorageRemoveItemsWithPrefix(this._temporalStateKeyPrefix);
    }

    private internalTemporalStateKey(keyName: string): string {
        return `${this._temporalTimetampPrefix}.${keyName}`;
    }

    temporalStateKey(keyName: string): string {
        return `${this._temporalStateKeyPrefix}.${keyName}`;
    }

    setTemporalValue(key: string, value: string | undefined): void {
        const ikey = this.internalTemporalStateKey(key);

        this.setValue(ikey, value);
    }

    getTemporalValue(key: string, nullIfEmpty = true): string | undefined {
        const ikey = this.internalTemporalStateKey(key);

        return this.getValue(ikey, nullIfEmpty);
    }

    setValue(key: string, value: string | undefined): void {
        const ikey = `${this._appname}.${key}`;

        if (isAssigned(value)) {
            localStorage.setItem(ikey, value!);
        } else {
            localStorage.removeItem(ikey);
        }
    }

    getValue(key: string, nullIfEmpty = true): string | undefined {
        const ikey = `${this._appname}.${key}`;
        const val = localStorage.getItem(ikey);

        return val === '' ? (nullIfEmpty ? undefined : val!) : val ?? undefined;
    }

    get accessToken(): string | undefined {
        return this.getValue('accessToken');
    }
    set accessToken(value: string | undefined) {
        this.setValue('accessToken', value);
    }

    get loggedUser(): LoginAsUserDTO | undefined {
        return deserializeFromBase64(this.getValue('loggedUser'));
    }
    set loggedUser(value: LoginAsUserDTO | undefined) {
        this.setValue('loggedUser', serializeToBase64(value));
    }

    get currentUser(): LoginAsUserDTO | undefined {
        const user = deserializeFromBase64(this.getTemporalValue('currentUser'));
        return user ?? this.loggedUser;
    }
    set currentUser(value: LoginAsUserDTO | undefined) {
        if (isAssigned(value)) {
            this.setTemporalValue('currentUser', serializeToBase64(value));
        } else {
            this.setTemporalValue('currentUser', undefined);
        }

        this.currentUserChangeSubject.next(value);
    }

    get permissions(): AccessPermissions {
        return this._permissions;
    }
    setPermissions(permissions?: string[]) {
        this._permissions = new AccessPermissions(permissions);
    }
}
