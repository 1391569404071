export interface DynamicTableFieldDTO {
    fieldCd?: string;
    fieldName?: string;
    fieldOrder?: number;
    fieldType?: string;
    fieldMapping?: string;
}

export interface DynamicTableValueDTO {
    field1?: string;
    field2?: string;
    field3?: string;
    field4?: string;
    field5?: string;
    field6?: string;
    field7?: string;
    field8?: string;
    field9?: string;
    field10?: string;
    field11?: string;
    field12?: string;
    field13?: string;
    field14?: string;
    field15?: string;
    field16?: string;
    field17?: string;
    field18?: string;
    field19?: string;
    field20?: string;
}

export interface DynamicTableBaseDTO<TField extends DynamicTableFieldDTO, TValue extends DynamicTableValueDTO> {
    fields?: DynamicTableFieldDTO[];
    values?: DynamicTableValueDTO[];
}
