import { DatePipe, Location } from '@angular/common';
import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
    BaseComponent,
    ColumnsViewDef,
    DefaultDateFormat,
    MetaService,
    OptionsMapDTO,
    ReadUserHistoryRequestDTO,
    UnknownErrorCallingAPIMessage,
    UserContextRoleDTO,
    UserHistoryRowDTO,
    UsersService,
    isAssigned,
} from '../../data';

const ComponentName = 'user-history-view-dialog';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: './user-history-view-dialog.component.html',
    styleUrls: ['./user-history-view-dialog.component.scss'],
    providers: [ConfirmationService],
    host: {
        class: 'flex flex-column',
        style: 'height: 50rem; max-height: 50rem;',
    },
})
export class UserHistoryViewDialogComponent extends BaseComponent {
    columns = new ColumnsViewDef([
        {
            name: 'action',
            width: '33%',
        },
        {
            name: 'actionPerformedBy',
            width: '33%',
        },
        {
            name: 'actionPerformedDt',
            displayValue: (history) => this._datePipe.transform(history.actionPerformedDt, DefaultDateFormat),
            width: '33%',
        },
    ]);

    requestSubs?: Subscription;
    isLoadingUserHistory = false;
    userHistory: UserHistoryRowDTO[] = [];
    userEmail?: string;
    contextRole: UserContextRoleDTO;
    profileCd?: string;
    userRoleContextId?: number;
    userRoleContextProfileId?: number;

    constructor(
        private readonly _userSrv: UsersService,
        private readonly _dialogCfg: DynamicDialogConfig,
        private readonly _dialogRef: DynamicDialogRef,
        private readonly _datePipe: DatePipe,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(toastr, metaSrv, location, translateSrv);
        this._translateKey = ComponentName;

        this.metaOptions = this._dialogCfg.data?.metaOptions;
        this.userEmail = this._dialogCfg.data?.userEmail;
        this.contextRole = this._dialogCfg.data?.contextRole ?? {};
        this.profileCd = this._dialogCfg.data?.profileCd;
        this.userRoleContextId = this._dialogCfg.data?.userRoleContextId;
        this.userRoleContextProfileId = this._dialogCfg.data?.userRoleContextProfileId;
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this.isLoadingUserHistory;
    }

    protected override onInit(): void {
        super.onInit();

        this.translateColumns(this.columns.columns);
    }

    protected override onDestroy(): void {
        if (isAssigned(this.requestSubs)) {
            this.requestSubs!.unsubscribe();
        }

        super.onDestroy();
    }

    protected override onLangChanged(): void {
        this.readUserHistory();
    }

    onCancel() {
        // this._dialogCfg.data?.doCancel();
        this._dialogRef.close();
    }

    readUserHistory() {
        if (isAssigned(this.requestSubs)) {
            return;
        }

        this.isLoadingUserHistory = true;
        this.emitOnLoading();

        this.userHistory = [];

        const request: ReadUserHistoryRequestDTO = {
            userEmail: this.userEmail ?? this.contextRole.information?.userEmail,
            roleCd: this.contextRole.roleCd,
            contextCd: this.contextRole.contextCd,
            profileCd: this.profileCd,
            userRoleContextId: this.userRoleContextId,
            userRoleContextProfileId: this.userRoleContextProfileId,
            languageCd: this.translateSrv.currentLang,
        };

        this.requestSubs = this._userSrv.readUserHistory(request).subscribe({
            next: (response) => {
                this.isLoadingUserHistory = false;
                this.emitOnLoading();

                this.userHistory = response.userHistory ?? [];

                this.showErrorMessage(response);

                this.requestSubs = undefined;
            },
            error: (error) => {
                this.isLoadingUserHistory = false;
                this.emitOnLoading();

                this.toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
            },
        });
    }
}

export function showProfileUserHistory(
    dialogSrv: DialogService,
    metaOptions: OptionsMapDTO,
    userEmail: string | undefined,
    profileCd: string | undefined,
    userRoleContextProfileId: number | undefined,
    contextRole: UserContextRoleDTO | undefined
) {
    dialogSrv.open(UserHistoryViewDialogComponent, {
        showHeader: false,
        width: '90%',
        // height: '50rem',
        contentStyle: { overflow: 'hidden' },
        data: {
            metaOptions: metaOptions,
            userEmail: userEmail ?? contextRole?.information?.userEmail,
            contextRole: contextRole,
            profileCd: profileCd?.toLowerCase(),
            userRoleContextId: (contextRole?.userRoleContextId ?? -1) > 0 ? contextRole?.userRoleContextId : undefined,
            userRoleContextProfileId: userRoleContextProfileId,
        },
    });
}
