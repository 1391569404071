import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AppWithoutMenuComponent } from './app-without-menu.component';
import { AppWithMenuComponent } from './app-with-menu.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const routes: Routes = [
    {
        // Needed for handling redirect after login
        path: 'auth',
        component: MsalRedirectComponent,
    },
    {
        path: '',
        component: AppWithMenuComponent,
        canActivate: [MsalGuard],
        loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    },
    {
        path: 'external',
        component: AppWithoutMenuComponent,
        loadChildren: () => import('./no-auth/no-auth.module').then((m) => m.NoAuthModule),
    },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
