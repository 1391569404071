import { ResponseDTO } from './response-dto';
import { UserContextRoleDTO } from './user-context-role-dto';

export interface ReadUserContextRoleExternalRequestResponseDTO extends ResponseDTO {
    userEmail?: string;
}

export interface ReadUserContextRoleInternalRequestResponseDTO extends ResponseDTO {
    contextRole?: UserContextRoleDTO;
}
