import { Component, Input, Output, EventEmitter, ViewChild, ContentChildren, QueryList, TemplateRef } from '@angular/core';

import { FilterMetadata, PrimeTemplate } from 'primeng/api';
import { Table } from 'primeng/table';

import { IDBGetString, IDBTableStyle, Nullable, isAssigned, isEmpty } from '../../utils';
import { ColumnViewDef, ColumnsViewDef } from '../../view/columns-view';
import { ActionDef, ActionDefBase, ActionItemDef } from '../../view';

export interface FiltersMetadata {
    [s: string]: FilterMetadata | FilterMetadata[] | undefined;
}

export interface LoadDataEvent {
    pageSize: number;
    pageIndex: number;
    filters: any;
    sortField?: string;
    sortOrder?: number;
}

class InternalActionDefBase {
    label?: string;
    styleClass?: string;

    constructor(action: ActionDefBase) {
        this.label = action.label;
        this.styleClass = action.styleClass;
    }
}

class InternalActionDef extends InternalActionDefBase {
    isVisible: () => boolean;
    run: () => void;
    tooltip: () => any;
    getIcon: () => any;

    constructor(action: ActionDef) {
        super(action);

        if (isAssigned(action.isVisible)) {
            this.isVisible = action.isVisible!;
        } else {
            this.isVisible = () => true;
        }

        if (isAssigned(action.run)) {
            this.run = action.run;
        } else {
            this.run = () => {};
        }

        if (isAssigned(action.tooltip)) {
            this.tooltip = action.tooltip!;
        } else {
            this.tooltip = () => undefined;
        }

        if (isAssigned(action.getIcon)) {
            this.getIcon = action.getIcon!;
        } else {
            this.getIcon = () => action.icon;
        }
    }
}

class InternalActionItemDef extends InternalActionDefBase {
    isVisible: (item: any) => boolean;
    run: (item: any) => void;
    tooltip: (item: any) => any;
    getIcon: (item: any) => any;

    constructor(action: ActionItemDef) {
        super(action);

        if (isAssigned(action.isVisible)) {
            this.isVisible = action.isVisible!;
        } else {
            this.isVisible = (item) => true;
        }

        if (isAssigned(action.run)) {
            this.run = action.run;
        } else {
            this.run = (item) => {};
        }

        if (isAssigned(action.tooltip)) {
            this.tooltip = action.tooltip!;
        } else {
            this.tooltip = (item) => '';
        }

        if (isAssigned(action.getIcon)) {
            this.getIcon = action.getIcon!;
        } else {
            this.getIcon = (item) => action.icon;
        }
    }
}

@Component({
    selector: 'idb-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent {
    private _itemActions?: InternalActionItemDef[] = undefined;
    private _headerActions?: InternalActionDef[] = undefined;
    private _footerActions?: InternalActionDef[] = undefined;

    @ViewChild('table', { static: false })
    private _table: Nullable<Table>;

    @ViewChild('defaultColumnHeader')
    private _defaultColumnHeader: Nullable<TemplateRef<any>>;

    @ViewChild('defaultColumnValue')
    private _defaultColumnValue: Nullable<TemplateRef<any>>;

    constructor() {}

    @ContentChildren(PrimeTemplate)
    private _templates: Nullable<QueryList<PrimeTemplate>>;

    @Input()
    columns: ColumnsViewDef = new ColumnsViewDef([]);

    @Input()
    filterByLabel = 'Filter by';

    @Input()
    paginatorLabel = 'Page {currentPage} of {totalPages} ({totalRecords} entries)';

    @Input()
    dataSource: any[] = [];

    @Input()
    loading = false;

    @Input()
    totalRecords = 0;

    @Input()
    pageLinks = 11;

    @Input()
    paginator = true;

    @Input()
    showHeader = true;

    @Input()
    scrollable = true;

    @Input()
    scrollHeightFlex = true;

    @Input()
    stateKey?: string;

    @Input()
    withFilterDelay = false;

    @Input()
    filterDelay = 2000;

    @Input()
    withFrozenColumns = false;

    @Input()
    tableStyle = IDBTableStyle;

    @Input()
    rowClass = IDBGetString;

    @Input()
    set itemActions(actions: ActionItemDef[] | undefined) {
        if (isAssigned(actions) && (actions?.length ?? 0) > 0) {
            let tmp: InternalActionItemDef[] = [];
            actions?.forEach((a) => {
                tmp.push(new InternalActionItemDef(a));
            });

            this._itemActions = tmp;
        } else {
            this._itemActions = undefined;
        }
    }
    get itemActions(): InternalActionItemDef[] {
        return this._itemActions ?? [];
    }
    get hasItemActions(): boolean {
        return isAssigned(this._itemActions) && (this._itemActions?.length ?? 0) > 0;
    }

    @Input()
    set headerActions(actions: ActionDef[] | undefined) {
        if (isAssigned(actions) && (actions?.length ?? 0) > 0) {
            let tmp: InternalActionDef[] = [];
            actions?.forEach((a) => {
                tmp.push(new InternalActionDef(a));
            });

            this._headerActions = tmp;
        } else {
            this._headerActions = undefined;
        }
    }
    get headerActions(): InternalActionDef[] {
        return this._headerActions ?? [];
    }
    get hasHeaderActions(): boolean {
        return isAssigned(this._headerActions) && (this._headerActions?.length ?? 0) > 0;
    }

    @Input()
    set footerActions(actions: ActionDef[] | undefined) {
        if (isAssigned(actions) && (actions?.length ?? 0) > 0) {
            let tmp: InternalActionDef[] = [];
            actions?.forEach((a) => {
                tmp.push(new InternalActionDef(a));
            });

            this._footerActions = tmp;
        } else {
            this._footerActions = undefined;
        }
    }
    get footerActions(): InternalActionDef[] {
        return this._footerActions ?? [];
    }
    get hasFooterActions(): boolean {
        return isAssigned(this._footerActions) && (this._footerActions?.length ?? 0) > 0;
    }

    get hasActions(): boolean {
        return this.hasItemActions || this.hasHeaderActions || this.hasFooterActions;
    }

    private _rowsPerPageOptions: number[] = [25, 50, 100, 200, 500];
    @Input()
    set rowsPerPageOptions(values: number[]) {
        if (isAssigned(values)) {
            this._rowsPerPageOptions = values;
            this._rowsPerPageOptions.sort((a, b) => a - b);
            this.onRowsChange(0);
        }
    }
    get rowsPerPageOptions() {
        return this._rowsPerPageOptions;
    }

    @Input()
    pageSize = 25;

    @Output()
    pageSizeChange = new EventEmitter<number>();
    onRowsChange($event: number) {
        if (this.pageSize !== $event) {
            const min = this.rowsPerPageOptions[0];

            this.pageSize = Math.max($event, min);
            this.pageSizeChange.emit(this.pageSize);
        }
    }

    @Input()
    pageIndex = 0;

    @Output()
    pageIndexChange = new EventEmitter<number>();
    firstRow = 0;

    onFirstChange($event: number) {
        this.firstRow = Math.max($event, 0);
        let pageIndex = this.firstRow / this.pageSize;

        if (pageIndex !== this.pageIndex) {
            this.pageIndex = pageIndex;
            this.pageIndexChange.emit(this.pageIndex);
        }
    }

    @Output()
    onLoadData = new EventEmitter<LoadDataEvent>();
    onLazyLoad($event: any) {
        this.onRowsChange($event.rows);
        this.onFirstChange($event.first);

        let filters = ($event ?? {}).filters ?? (this._table ?? {}).filters ?? {};

        const event: LoadDataEvent = {
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
            filters: filters,
            sortField: $event.sortField,
            sortOrder: $event.sortOrder,
        };

        this.onLoadData.emit(event);
    }

    get filters(): FiltersMetadata {
        return (this._table as Table).filters ?? {};
    }

    get sortField(): string {
        return (this._table as Table).sortField ?? '';
    }
    set sortField(value: string) {
        (this._table as Table).sortField = value;
    }

    get sortOrder(): number {
        return (this._table as Table).sortOrder;
    }
    set sortOrder(value: number) {
        (this._table as Table).sortOrder = value;
    }

    get frozenColumns(): ColumnViewDef[] {
        return this.withFrozenColumns ? this.columns.frozenColumns : [];
    }

    get unfrozenColumns(): ColumnViewDef[] {
        return this.withFrozenColumns ? this.columns.unfrozenColumns : this.columns.columns;
    }

    clearFilters() {
        if (isAssigned(this._table)) {
            let table = this._table as Table;
            table.sortOrder = 0;
            table.sortField = '';
            table.reset();
            table.clear();
        }
    }

    getColumnStyle(col: ColumnViewDef): any {
        if (!isEmpty(col.width)) return { width: col.width };

        return undefined;
    }

    getActionClass(action: ActionDefBase) {
        if (isAssigned(action.styleClass) && action.styleClass !== '')
            return `p-button-outlined p-button-raised idb-action-button p-button-${action.styleClass}`;
        return 'p-button-outlined p-button-raised p-button-info idb-action-button';
    }

    private findColumnTemplate(column: ColumnViewDef, suffix: string): Nullable<TemplateRef<any>> {
        if (isAssigned(this._templates)) {
            const tname = `${column.name}${suffix}`.toLowerCase();
            const templates = this._templates as QueryList<PrimeTemplate>;
            const template = templates.find((t) => t.getType().toLowerCase() === tname);

            if (isAssigned(template) && isAssigned(template?.template)) {
                return template?.template;
            }
        }

        return undefined;
    }

    getColumnValueTemplate(column: ColumnViewDef): TemplateRef<any> {
        const template = this.findColumnTemplate(column, 'Value');
        if (isAssigned(template)) {
            return template!;
        }

        return this._defaultColumnValue as TemplateRef<any>;
    }

    getColumnHeaderTemplate(column: ColumnViewDef): TemplateRef<any> {
        const template = this.findColumnTemplate(column, 'Header');
        if (isAssigned(template)) {
            return template!;
        }

        return this._defaultColumnHeader as TemplateRef<any>;
    }
}
