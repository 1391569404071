import { Component, Input } from '@angular/core';

import { isAssigned } from '../../utils';

@Component({
    selector: 'idb-data-field',
    templateUrl: './data-field.component.html',
    styleUrls: ['./data-field.component.scss'],
})
export class DataFieldComponent {
    @Input() labelClass = '';
    @Input() labelColClass = 'w-full';
    @Input() valueClass = '';
    @Input() valueColClass = 'w-full';

    @Input() label = '';
    @Input() value: any = undefined;

    get hasValue(): boolean {
        return isAssigned(this.value);
    }
}
