import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { GlobalsService, isAssigned } from './data';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private readonly _document: Document, private readonly _globalsSrv: GlobalsService) {
        this.switchTheme(this.isDarkMode);
    }

    get isDarkMode() {
        const val = this._globalsSrv.getValue('isDarkMode');
        let isDarkMode = environment.isDarkMode;

        if (isAssigned(val)) {
            isDarkMode = val?.toLowerCase() === 'true';
        }

        return isDarkMode;
    }

    switchTheme(isDarkMode: boolean) {
        const themeLink = this._document.getElementById('app-theme') as HTMLLinkElement;

        this._globalsSrv.setValue('isDarkMode', isDarkMode ? 'true' : 'false');

        if (themeLink) {
            themeLink.href = isDarkMode ? 'dark-mode.css' : 'light-mode.css';
        }
    }
}
