export const environment = {
    production: false,
    baseurl: 'https://api-np-d-eaps2.azurewebsites.net',
    redirecturl: 'https://eaps.myiadboperations-d.iadb.org/',
    postlogouturl: 'https://eaps.myiadboperations-d.iadb.org/',
    Name: 'eaps-a-np-d',
    tenant: 'idbgextt',
    tenantId: '1f87f52a-35d7-4344-9c79-095847954509',
    clientId: '8d8d727c-3f4a-456e-a818-b5a896a824a8',
    isDarkMode: false,
};
