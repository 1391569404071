import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MsalService } from '@azure/msal-angular';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    ReadContextRoleProfileApproverRolesRequestDTO,
    ReadContextRoleProfileApproverRolesResponseDTO,
    ReadContextRoleScopePickerRequestDTO,
    ReadContextRoleScopePickerResponseDTO,
    ReadContextRoleScopePickerValidationRequestDTO,
    ReadContextRoleScopePickerValidationResponseDTO,
    ReadContextRoleScopeProfilesRequestDTO,
    ReadContextRoleScopeProfilesResponseDTO,
    ReadContextRoleScopeRequestDTO,
    ReadContextRoleScopeResponseDTO,
    ReadUserContextRoleProfileAccessDetailsRequestDTO,
    ReadUserContextRoleProfileAccessDetailsResponseDTO,
    ReadUserContextRoleProfilePermissionsRequestDTO,
    ReadUserContextRoleProfilePermissionsResponseDTO,
    ReadUserContextRoleProfileRequestDTO,
    ReadUserContextRoleProfileResponseDTO,
    SaveUserContextRoleProfileRequestDTO,
    SaveUserContextRoleProfileResponseDTO,
    SaveUserContextRoleScopeProfileRequestDTO,
    SaveUserContextRoleScopeProfileResponseDTO,
} from '../dtos';
import { BaseService } from './base.service';
import { GlobalsService } from './globals.service';

@Injectable()
export class ContextRoleService extends BaseService {
    private _contextRoleUrl: string;

    constructor(private readonly _http: HttpClient, globalsSrv: GlobalsService, authSrv: MsalService) {
        super(globalsSrv, authSrv);

        this._contextRoleUrl = `${this.apiUrl}/ContextRole`;
    }

    readContextRoleScopePicker(request: ReadContextRoleScopePickerRequestDTO): Observable<ReadContextRoleScopePickerResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<ReadContextRoleScopePickerResponseDTO>(`${this._contextRoleUrl}/scope-picker`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    readContextRoleScopePickerValidation(request: ReadContextRoleScopePickerValidationRequestDTO): Observable<ReadContextRoleScopePickerValidationResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<ReadContextRoleScopePickerValidationResponseDTO>(`${this._contextRoleUrl}/scope-picker-validation`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    readContextRoleScopeProfiles(request: ReadContextRoleScopeProfilesRequestDTO): Observable<ReadContextRoleScopeProfilesResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<ReadContextRoleScopeProfilesResponseDTO>(`${this._contextRoleUrl}/scope-profiles`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    readContextRoleScope(request: ReadContextRoleScopeRequestDTO): Observable<ReadContextRoleScopeResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<ReadContextRoleScopeResponseDTO>(`${this._contextRoleUrl}/scopes`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    readContextRoleProfileApproverRoles(request: ReadContextRoleProfileApproverRolesRequestDTO): Observable<ReadContextRoleProfileApproverRolesResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<ReadContextRoleProfileApproverRolesResponseDTO>(`${this._contextRoleUrl}/profile-approver-roles`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    readUserContextRoleProfilePermissions(
        request: ReadUserContextRoleProfilePermissionsRequestDTO
    ): Observable<ReadUserContextRoleProfilePermissionsResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .get<ReadUserContextRoleProfilePermissionsResponseDTO>(`${this._contextRoleUrl}/profile-permissions`, options)
            .pipe(catchError(this.onHttpError));
    }

    readUserContextRoleProfileAccessDetails(
        request: ReadUserContextRoleProfileAccessDetailsRequestDTO
    ): Observable<ReadUserContextRoleProfileAccessDetailsResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .get<ReadUserContextRoleProfileAccessDetailsResponseDTO>(`${this._contextRoleUrl}/profile-access-details`, options)
            .pipe(catchError(this.onHttpError));
    }

    readUserContextRoleProfile(request: ReadUserContextRoleProfileRequestDTO): Observable<ReadUserContextRoleProfileResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<ReadUserContextRoleProfileResponseDTO>(`${this._contextRoleUrl}/profile`, options).pipe(catchError(this.onHttpError));
    }

    saveUserContextRoleProfile(request: SaveUserContextRoleProfileRequestDTO): Observable<SaveUserContextRoleProfileResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<SaveUserContextRoleProfileResponseDTO>(`${this._contextRoleUrl}/profile`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }

    saveUserContextRoleScopeProfile(request: SaveUserContextRoleScopeProfileRequestDTO): Observable<SaveUserContextRoleScopeProfileResponseDTO> {
        const options = {
            params: this.getHttpParams(),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .post<SaveUserContextRoleScopeProfileResponseDTO>(`${this._contextRoleUrl}/scopes-profiles`, this.fillRequestData(request), options)
            .pipe(catchError(this.onHttpError));
    }
}
