import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
    ActionDef,
    ActionItemDef,
    AnyKey,
    ColumnViewDef,
    ColumnsViewDef,
    ContextRoleService,
    EditorBaseComponent,
    IDBTableStyle,
    MetaService,
    SaveUserContextRoleProfileRequestDTO,
    SwitchChangedEvent,
    UserAccountInformationDTO,
    UserContextRoleDTO,
    UserContextRoleScopeDTO,
    UserContextRoleScopeFieldDTO,
    confirmAction,
    doSaveUserContextRoleProfile,
    isAssigned,
    isEmpty,
} from '../../data';
import { showProfileUserApprovers } from '../../pages/account-information/account-information-profile-approvers.component';
import { UserContextRoleScopeProfileDeniedConfirmComponent } from '../../pages/user-context-role-scope-profile/user-context-role-scope-profile-denied-confirm.component';
import { showProfileUserHistory } from '../../pages/user-history/user-history-view-dialog.component';
import { showProfileDetails } from '../../pages/user-context-role-scope-profile/user-context-role-scope-profile-tools';
import { showProfileAccessDetails } from 'src/app/pages/user-context-role-profile-access-details/user-context-role-profile-access-details-dialog.component';

export interface ScopeFieldChangedEvent {
    scope: UserContextRoleScopeDTO;
    fieldMapping: string;
    oldValue?: string;
    newValue?: string;
}

export interface ScopeChangedEvent {
    scope: UserContextRoleScopeDTO;
    profileIds?: number[];
}

export interface ScopesChangedEvent {
    scopes: ScopeChangedEvent[];
    profileStatus: string;
    deniedReason?: string;
}

export function confirmProfileActions(
    dialogSrv: DialogService,
    confirmSrv: ConfirmationService,
    deniedValue: string,
    askDenyReason: boolean,
    header: string,
    message: string,
    deniedReasonLabel: string,
    acceptLabel: string,
    cancelLabel: string,
    newValue: string,
    accountInfo: UserAccountInformationDTO,
    contextRole: UserContextRoleDTO,
    userRoleContextProfileId: number,
    disableAutoUpdateSource: string | undefined,
    onAccept: (deactivationReaspn: string | undefined) => void,
    onCancel: () => void = () => {}
) {
    const valueTmp = newValue.toUpperCase();

    if (askDenyReason && valueTmp.startsWith(deniedValue)) {
        if (userRoleContextProfileId <= 0) {
            let editScopeRef: DynamicDialogRef | undefined;
            const options = {
                header: header,
                width: '50%',
                height: '15.5rem',
                contentStyle: { overflow: 'hidden' },
                closeOnEscape: false,
                data: {
                    message: message,
                    deniedReasonLabel: deniedReasonLabel,
                    doAccept: (deniedReason: string | undefined) => {
                        if (!isEmpty(deniedReason)) {
                            onAccept(deniedReason);
                        }
                        editScopeRef?.close(deniedReason);
                    },
                    doCancel: () => {
                        onCancel();

                        editScopeRef?.close(undefined);
                    },
                },
            };

            editScopeRef = dialogSrv.open(UserContextRoleScopeProfileDeniedConfirmComponent, options);
        } else {
            const data = {
                denyDirectly: true,
                accountInfo: accountInfo,
                contextRole: contextRole,
                userRoleContextProfileId: userRoleContextProfileId,
                disableAutoUpdateSource: disableAutoUpdateSource,
            };
            showProfileDetails(
                dialogSrv,
                'denied',
                data,
                (dr) => onAccept(dr),
                () => onCancel()
            );
        }
    } else {
        confirmAction(confirmSrv, {
            header: header,
            message: message,
            acceptLabel: acceptLabel,
            cancelLabel: cancelLabel,
            onAccept: () => onAccept(undefined),
            onCancel: () => onCancel(),
        });
    }
}

const ComponentName = 'account-information-scopes';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: './account-information-scopes.component.html',
    styleUrls: ['./account-information-scopes.component.scss'],
    providers: [ConfirmationService, DialogService],
    host: {
        class: 'w-full',
    },
})
export class AccountInformationScopesComponent extends EditorBaseComponent {
    private _isSavingProfilePermissions = false;

    scopesColumns: ColumnsViewDef = new ColumnsViewDef([]);

    approvedValueD = 'APPROVED';
    deniedValueD = 'DENIED';
    pendingValueD = 'PENDING';
    recertifyValueD = 'RECERTIFY';

    @Input()
    accountInfo: UserAccountInformationDTO = {};

    private _contextRole: UserContextRoleDTO = { userRoleContextId: -1 };
    @Input()
    set contextRole(sf: UserContextRoleDTO | undefined) {
        this._contextRole = sf ?? { userRoleContextId: -1 };
        this._contextRole.showAllProfiles = (this._contextRole.showAllProfiles ?? true) === true;

        this.generateColumns();
    }
    get contextRole(): UserContextRoleDTO {
        return this._contextRole;
    }

    private _scopeFields: UserContextRoleScopeFieldDTO[] | undefined;
    @Input()
    set scopeFields(scopeFields: UserContextRoleScopeFieldDTO[] | undefined) {
        this._scopeFields = scopeFields;

        this.generateColumns();
    }
    get scopeFields(): UserContextRoleScopeFieldDTO[] {
        return this._scopeFields ?? this._contextRole.scopeFields ?? [];
    }

    private _scopes: UserContextRoleScopeDTO[] = [];
    @Input()
    set scopes(ss: UserContextRoleScopeDTO[] | undefined) {
        this._scopes = ss || [];
    }
    get scopes(): UserContextRoleScopeDTO[] {
        if (this.scopeFields.length > 0) {
            return this._scopes;
        }

        return [];
    }

    @Input()
    headerActions: ActionDef[] | undefined = undefined;

    @Input()
    itemActions: ActionItemDef[] | undefined = undefined;

    @Input()
    footerActions: ActionDef[] | undefined = undefined;

    @Input()
    readonlyPtofile = false;

    @Input()
    showProfileActions = true;

    @Input()
    showProfileMultiActions = true;

    @Input()
    askDenyReason = true;

    canEmmitSwitchChange = true;

    @Output()
    fieldChanged = new EventEmitter<ScopeFieldChangedEvent>();

    @Output()
    scopesChanged = new EventEmitter<ScopesChangedEvent>();

    @Input()
    parentHasChanges = false;

    @Input()
    tableStyle = IDBTableStyle;

    constructor(
        private readonly _confirmSrv: ConfirmationService,
        private readonly _dialogSrv: DialogService,
        private readonly _contextRoleSrv: ContextRoleService,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(translateSrv, toastr, metaSrv, location);

        this._translateKey = ComponentName;
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this._isSavingProfilePermissions;
    }

    private generateColumns() {
        const columns: ColumnViewDef[] = [];
        this.scopeFields.forEach((sf) => {
            if (isAssigned(sf.fieldMapping)) {
                const c: ColumnViewDef = {
                    name: sf.fieldMapping ?? '',
                    header: sf.fieldName + (sf.isRequired ? ' *' : ''),
                    data: sf,
                    width: sf.fieldType === 'text' ? '15rem' : '20rem',
                };

                columns.push(c);
            }
        });

        if (this.showProfileMultiActions === true && this._contextRole.showAllProfiles === true && (this.scopes?.length ?? 0) > 0) {
            const switchFields = this.scopeFields?.filter((sf) => sf.fieldType?.toLowerCase() === 'switch');
            if ((switchFields?.length ?? 0) > 1) {
                columns.push({ name: 'allProfiles', header: this.translateDirect('profile.allProfiles'), width: '10rem' });
            }
        }

        this.scopesColumns = new ColumnsViewDef(columns);
    }

    protected override onLangChanged(): void {
        super.onLangChanged();

        this.generateColumns();
    }

    isTextColumn(column: ColumnViewDef) {
        return column.data?.fieldType === 'text';
    }

    isSwitchColumn(column: ColumnViewDef) {
        return column.data?.fieldType === 'switch';
    }

    scopeRowClass(scope: UserContextRoleScopeDTO): string {
        return scope.isDeleted === true ? 'idb-scope-deleted' : '';
    }

    emmitScopesChanged(profileStatus: string, scopesChanged: ScopeChangedEvent[], deniedReason: string | undefined) {
        const crScopes = this.scopes;
        const scopes: UserContextRoleScopeDTO[] = [];

        crScopes.forEach((s) => {
            if (isAssigned(s) && (s.isDeleted === true || s.hasChanged === true)) {
                scopes.push(s);
            }
        });

        if (scopes.length > 0) {
            this.scopesChanged.emit({ scopes: scopesChanged, profileStatus: profileStatus, deniedReason: deniedReason });
        }
    }

    private setItemValue(column: ColumnViewDef, scope: UserContextRoleScopeDTO, value: any | undefined, deniedReason: string | undefined) {
        let ev: ScopeFieldChangedEvent = {
            scope: scope,
            fieldMapping: column.data?.fieldMapping,
            oldValue: (scope as any)[column.data?.fieldMapping],
            newValue: value,
        };

        if (ev.oldValue !== ev.newValue) {
            (scope as any)[`${column.data?.fieldMapping}Value`] = value;

            scope.hasChanged = true;

            if (this.canEmmitSwitchChange) {
                this.fieldChanged.emit(ev);

                this.emmitScopesChanged(value ?? '', [{ scope: scope, profileIds: [this.getItemValueId(column, scope)] }], deniedReason);
            }
        }
    }

    getItemValue(column: ColumnViewDef, scope: UserContextRoleScopeDTO): string {
        return (scope as any)[`${column.data?.fieldMapping}Value`];
    }

    getItemCanApprove(column: ColumnViewDef, scope: UserContextRoleScopeDTO): boolean | undefined {
        return (scope as any)[`${column.data?.fieldMapping}CanApprove`];
    }

    getItemHasAccessDetails(column: ColumnViewDef, scope: UserContextRoleScopeDTO): boolean | undefined {
        return (scope as any)[`${column.data?.fieldMapping}HasAccessDetails`];
    }

    getItemDisableAutoUpdate(column: ColumnViewDef, scope: UserContextRoleScopeDTO): boolean | undefined {
        return (scope as any)[`${column.data?.fieldMapping}DisableAutoUpdate`];
    }

    getItemDisableAutoUpdateSource(column: ColumnViewDef, scope: UserContextRoleScopeDTO): string | undefined {
        return (scope as any)[`${column.data?.fieldMapping}DisableAutoUpdateSource`];
    }

    getItemDisableAutoUpdateClass(column: ColumnViewDef, scope: UserContextRoleScopeDTO): string | undefined {
        const val = this.getItemDisableAutoUpdate(column, scope);
        return val === true ? 'p-button-secondary' : 'p-button-success text-green-300';
    }

    getItemDisableAutoUpdateTooltip(column: ColumnViewDef, scope: UserContextRoleScopeDTO): string | undefined {
        const val = this.getItemDisableAutoUpdate(column, scope);

        if (isAssigned(val)) {
            const source = this.getItemDisableAutoUpdateSource(column, scope);

            if (!isEmpty(source)) {
                const key = val === true ? 'profile.disableAutoUpdate.disabled' : 'profile.disableAutoUpdate.enabled';

                return this.translateDirect(key, { source: source });
            }
        }

        return '';
    }

    getItemDisplayValue(column: ColumnViewDef, scope: UserContextRoleScopeDTO): string {
        const fkey = column.data?.fieldMapping as AnyKey;
        const val = (scope as any)[fkey] as string;

        return val;
    }

    getItemValueId(column: ColumnViewDef, scope: UserContextRoleScopeDTO): any {
        return (scope as any)[column.data?.fieldMapping + 'Id'];
    }

    isItemValue(column: ColumnViewDef, scope: UserContextRoleScopeDTO): boolean {
        const iv = this.getItemValue(column, scope);

        return iv === this.approvedValueD || iv === this.deniedValueD || iv === this.pendingValueD || iv === this.recertifyValueD;
    }

    isEditableItemValue(column: ColumnViewDef, scope: UserContextRoleScopeDTO): boolean {
        const iv = this.getItemCanApprove(column, scope);

        return iv === true;
    }

    canEditItemValue(column: ColumnViewDef, scope: UserContextRoleScopeDTO): boolean {
        return scope.isDeleted !== true && scope.canEditScopeRowAction === true && this.isEditableItemValue(column, scope);
    }

    canViewRoleDetails(scope: UserContextRoleScopeDTO): boolean {
        return this._contextRole.information?.canViewRoleDetails === true && scope.isDeleted !== true;
    }

    getEditItemValue(column: ColumnViewDef, scope: UserContextRoleScopeDTO, t: string): string {
        return t === 'A' ? this.approvedValueD : t === 'D' ? this.deniedValueD : this.pendingValueD;
    }

    private applyOneScopeOneColumn(
        column: ColumnViewDef,
        scope: UserContextRoleScopeDTO,
        newValue: string,
        deniedReason: string | undefined,
        emitScopes = true
    ) {
        const scopesChanged: ScopeChangedEvent[] = [];
        const newTmp = newValue.toUpperCase();

        if (scope.isDeleted !== true && scope.canEditScopeRowAction === true) {
            const profileIds: number[] = [];

            const value: string = (this.getItemValue(column, scope) ?? '').toUpperCase();
            const canApprove = this.getItemCanApprove(column, scope);
            if (canApprove && value !== newTmp) {
                try {
                    this.canEmmitSwitchChange = false;
                    this.setItemValue(column, scope, newTmp, deniedReason);
                    profileIds.push(this.getItemValueId(column, scope));
                } finally {
                    this.canEmmitSwitchChange = true;
                }
            }

            if (emitScopes) {
                if (profileIds.length > 0) {
                    scopesChanged.push({ scope: scope, profileIds: profileIds });
                }

                this.emmitScopesChanged(newValue, scopesChanged, deniedReason);
            }
        }
    }

    private applyAllScopesOneColumn(column: ColumnViewDef, newValue: string, deniedReason: string | undefined) {
        const scopesChanged: ScopeChangedEvent[] = [];
        const newTmp = newValue.toUpperCase();

        this.scopes.forEach((scope) => {
            if (scope.isDeleted !== true && scope.canEditScopeRowAction === true) {
                const profileIds: number[] = [];

                const value: string = (this.getItemValue(column, scope) ?? '').toUpperCase();
                const canApprove = this.getItemCanApprove(column, scope);

                if (canApprove && value !== newTmp) {
                    try {
                        this.canEmmitSwitchChange = false;
                        this.setItemValue(column, scope, newTmp, deniedReason);
                        profileIds.push(this.getItemValueId(column, scope));
                    } finally {
                        this.canEmmitSwitchChange = true;
                    }
                }

                if (profileIds.length > 0) {
                    scopesChanged.push({ scope: scope, profileIds: profileIds });
                }
            }
        });

        this.emmitScopesChanged(newValue, scopesChanged, deniedReason);
    }

    private applyOneScopeAllColumns(scope: UserContextRoleScopeDTO, newValue: string, deniedReason: string | undefined) {
        const scopesChanged: ScopeChangedEvent[] = [];
        const profileIds: number[] = [];
        const newTmp = newValue.toUpperCase();

        this.scopesColumns.columns.forEach((column) => {
            if (column.name !== 'allProfiles') {
                const value: string = (this.getItemValue(column, scope) ?? '').toUpperCase();
                const canApprove = this.getItemCanApprove(column, scope);

                if (canApprove && value !== newTmp) {
                    try {
                        this.canEmmitSwitchChange = false;
                        this.setItemValue(column, scope, newTmp, deniedReason);
                        profileIds.push(this.getItemValueId(column, scope));
                    } finally {
                        this.canEmmitSwitchChange = true;
                    }
                }
            }
        });

        if (profileIds.length > 0) {
            scopesChanged.push({ scope: scope, profileIds: profileIds });
        }

        this.emmitScopesChanged(newValue, scopesChanged, deniedReason);
    }

    private applyAllScopesAllColumns(newValue: string, deniedReason: string | undefined) {
        const scopesChanged: ScopeChangedEvent[] = [];
        const newTmp = newValue.toUpperCase();

        this.scopes.forEach((scope) => {
            if (scope.isDeleted !== true && scope.canEditScopeRowAction === true) {
                const profileIds: number[] = [];

                this.scopesColumns.columns.forEach((column) => {
                    if (column.name !== 'allProfiles') {
                        const value: string = (this.getItemValue(column, scope) ?? '').toUpperCase();
                        const canApprove = this.getItemCanApprove(column, scope);

                        if (canApprove && value !== newTmp) {
                            try {
                                this.canEmmitSwitchChange = false;
                                this.setItemValue(column, scope, newTmp, deniedReason);
                                profileIds.push(this.getItemValueId(column, scope));
                            } finally {
                                this.canEmmitSwitchChange = true;
                            }
                        }
                    }
                });

                if (profileIds.length > 0) {
                    scopesChanged.push({ scope: scope, profileIds: profileIds });
                }
            }
        });

        this.emmitScopesChanged(newValue, scopesChanged, deniedReason);
    }

    private setSwitchValue(column: ColumnViewDef, scope: UserContextRoleScopeDTO, value: string) {
        this.canEmmitSwitchChange = false;
        this.setItemValue(column, scope, value, undefined);
        this.canEmmitSwitchChange = true;
        scope.hasChanged = false;
        this.scopesChanged.emit({ scopes: [], profileStatus: '' });
    }

    onScopeColumnChange(column: ColumnViewDef, scope: UserContextRoleScopeDTO, $e: SwitchChangedEvent) {
        if (column.name !== 'allProfiles') {
            const curValue = this.getItemValue(column, scope);

            if (curValue !== $e.newValue) {
                const tmpValue = $e.newValue?.toUpperCase() ?? '';

                if (tmpValue.startsWith(this.deniedValueD)) {
                    const userRoleContextProfileId = this.getItemValueId(column, scope);
                    const doRestore = () => this.setSwitchValue(column, scope, curValue);
                    const doDeny = () => {
                        if (this.askDenyReason) {
                            confirmProfileActions(
                                this._dialogSrv,
                                this._confirmSrv,
                                this.deniedValueD,
                                this.askDenyReason,
                                this.translate('confirm.denyProfile.header'),
                                this.translate('confirm.denyProfile.message'),
                                this.translateDirect('user-context-role-scope-profile.denied.reason'),
                                this.translate('confirm.yes'),
                                this.translate('confirm.no'),
                                $e.newValue ?? '',
                                this.accountInfo,
                                this._contextRole,
                                userRoleContextProfileId,
                                this.getItemDisableAutoUpdate(column, scope) !== true ? this.getItemDisableAutoUpdateSource(column, scope) : undefined,
                                (deniedReason) => {
                                    this.setSwitchValue(column, scope, this.deniedValueD);
                                },
                                () => {
                                    doRestore();
                                }
                            );
                        } else {
                            this.applyOneScopeOneColumn(column, scope, this.deniedValueD, undefined);
                        }
                    };

                    if (
                        isAssigned(this.accountInfo?.lastActiveUserRoleContextProfileId) &&
                        this.accountInfo?.lastActiveUserRoleContextProfileId === userRoleContextProfileId
                    ) {
                        confirmAction(this._confirmSrv, {
                            header: this.translate('confirm.denyProfile.header'),
                            message: this.translate('confirm.denyProfile.confirm'),
                            acceptLabel: this.translateDirect('confirm'),
                            cancelLabel: this.translateDirect('cancel'),
                            onAccept: () => doDeny(),
                            onCancel: () => doRestore(),
                        });
                    } else {
                        doDeny();
                    }
                } else {
                    this.applyOneScopeOneColumn(column, scope, this.approvedValueD, undefined);
                }
            }
        }
    }

    onScopeColumnDeny(column: ColumnViewDef) {
        if (column.name !== 'allProfiles') {
            confirmProfileActions(
                this._dialogSrv,
                this._confirmSrv,
                this.deniedValueD,
                this.askDenyReason,
                this.translate('confirm.denyProfiles.header'),
                this.translate('confirm.denyProfiles.message'),
                this.translateDirect('user-context-role-scope-profile.denied.reason'),
                this.translate('confirm.yes'),
                this.translate('confirm.no'),
                this.deniedValueD,
                this.accountInfo,
                this._contextRole,
                0,
                undefined,
                (deniedReason) => {
                    this.applyAllScopesOneColumn(column, this.deniedValueD, deniedReason);
                }
            );
        }
    }

    onScopeColumnApprove(column: ColumnViewDef) {
        if (column.name !== 'allProfiles') {
            confirmProfileActions(
                this._dialogSrv,
                this._confirmSrv,
                this.deniedValueD,
                this.askDenyReason,
                this.translate('confirm.approveProfiles.header'),
                this.translate('confirm.approveProfiles.message'),
                this.translateDirect('user-context-role-scope-profile.denied.reason'),
                this.translate('confirm.yes'),
                this.translate('confirm.no'),
                this.approvedValueD,
                this.accountInfo,
                this._contextRole,
                0,
                undefined,
                (deniedReason) => {
                    this.applyAllScopesOneColumn(column, this.approvedValueD, deniedReason);
                }
            );
        }
    }

    onScopeRowDeny(scope: UserContextRoleScopeDTO) {
        if (scope.isDeleted !== true && scope.canEditScopeRowAction === true) {
            confirmProfileActions(
                this._dialogSrv,
                this._confirmSrv,
                this.deniedValueD,
                this.askDenyReason,
                this.translate('confirm.denyScope.header'),
                this.translate('confirm.denyScope.message'),
                this.translateDirect('user-context-role-scope-profile.denied.reason'),
                this.translate('confirm.yes'),
                this.translate('confirm.no'),
                this.deniedValueD,
                this.accountInfo,
                this._contextRole,
                0,
                undefined,
                (deniedReason) => {
                    this.applyOneScopeAllColumns(scope, this.deniedValueD, deniedReason);
                }
            );
        }
    }

    onScopeRowApprove(scope: UserContextRoleScopeDTO) {
        if (scope.isDeleted !== true && scope.canEditScopeRowAction === true) {
            confirmProfileActions(
                this._dialogSrv,
                this._confirmSrv,
                this.deniedValueD,
                this.askDenyReason,
                this.translate('confirm.approveScope.header'),
                this.translate('confirm.approveScope.message'),
                this.translateDirect('user-context-role-scope-profile.denied.reason'),
                this.translate('confirm.yes'),
                this.translate('confirm.no'),
                this.approvedValueD,
                this.accountInfo,
                this._contextRole,
                0,
                undefined,
                (deniedReason) => {
                    this.applyOneScopeAllColumns(scope, this.approvedValueD, deniedReason);
                }
            );
        }
    }

    onScopeDeny() {
        confirmProfileActions(
            this._dialogSrv,
            this._confirmSrv,
            this.deniedValueD,
            this.askDenyReason,
            this.translate('confirm.denyAll.header'),
            this.translate('confirm.denyAll.message'),
            this.translateDirect('user-context-role-scope-profile.denied.reason'),
            this.translate('confirm.yes'),
            this.translate('confirm.no'),
            this.deniedValueD,
            this.accountInfo,
            this._contextRole,
            0,
            undefined,
            (deniedReason) => {
                this.applyAllScopesAllColumns(this.deniedValueD, deniedReason);
            }
        );
    }

    onScopeApprove() {
        confirmProfileActions(
            this._dialogSrv,
            this._confirmSrv,
            this.deniedValueD,
            this.askDenyReason,
            this.translate('confirm.approveAll.header'),
            this.translate('confirm.approveAll.message'),
            this.translateDirect('user-context-role-scope-profile.denied.reason'),
            this.translate('confirm.yes'),
            this.translate('confirm.no'),
            this.approvedValueD,
            this.accountInfo,
            this._contextRole,
            0,
            undefined,
            (deniedReason) => {
                this.applyAllScopesAllColumns(this.approvedValueD, deniedReason);
            }
        );
    }

    onProfileViewApprovers(column: ColumnViewDef, scope: UserContextRoleScopeDTO) {
        const profileId = this.getItemValueId(column, scope);
        showProfileUserApprovers(this._dialogSrv, column.data?.fieldCd, profileId, this.contextRole);
    }

    onProfileViewHistory(column: ColumnViewDef, scope: UserContextRoleScopeDTO) {
        const profileId = this.getItemValueId(column, scope);
        showProfileUserHistory(this._dialogSrv, this.metaOptions, this.accountInfo?.userEmail, column.data?.fieldCd, profileId, this.contextRole);
    }

    onProfileAccessDetails(column: ColumnViewDef, scope: UserContextRoleScopeDTO) {
        const profileId = this.getItemValueId(column, scope);
        showProfileAccessDetails(this._dialogSrv, this.metaOptions, profileId);
    }

    get hasScopesChanged(): boolean {
        let res = false;

        this.contextRole.scopes?.forEach((s) => {
            if (!res) {
                res = s.hasChanged === true || s.isDeleted === true;
            }
        });

        return res;
    }

    onContextRoleProfileEdit(column: ColumnViewDef, scope: UserContextRoleScopeDTO) {
        const profileStatus = (this.getItemValue(column, scope) as string)?.toLowerCase() ?? '';
        const profileId = (this.getItemValueId(column, scope) as number) ?? -1;
        const data = {
            accountInfo: this.accountInfo,
            contextRole: this.contextRole,
            profileCd: column.data?.fieldCd,
            userRoleContextProfileId: profileId,
        };

        showProfileDetails(this._dialogSrv, profileStatus, data, (deniedReason) => {
            this.setSwitchValue(column, scope, profileStatus);
        });
    }

    onProfileDAU(column: ColumnViewDef, scope: UserContextRoleScopeDTO) {
        const profileId = (this.getItemValueId(column, scope) as number) ?? -1;
        const disableAutoUpdate = this.getItemDisableAutoUpdate(column, scope);

        const request: SaveUserContextRoleProfileRequestDTO = {
            disableAutomaticUpdate: !(disableAutoUpdate ?? false),
            userRoleContextProfileId: profileId,
        };

        doSaveUserContextRoleProfile(
            this.toastr,
            this._contextRoleSrv,
            request,
            (loading) => {
                this._isSavingProfilePermissions = loading;
                this.emitOnLoading();
            },
            (response) => this.scopesChanged.emit({ scopes: [], profileStatus: '' })
        );
    }
}
