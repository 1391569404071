import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';

import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

import { MetaService, ContextRoleService, UsersService, WorkService, UserDirectoryService, ReportsService } from './services';
import { DateRangeComponent, DataTableFilterComponent, DataTableComponent, DataFieldComponent, SwitchComponent } from './components';

const SERVICES = [MetaService, ContextRoleService, UsersService, WorkService, UserDirectoryService, ReportsService];

const COMPONENTS = [DateRangeComponent, DataTableFilterComponent, DataTableComponent, DataFieldComponent, SwitchComponent, SwitchComponent];

const MODULES = [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    CalendarModule,
    TableModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    TooltipModule,
];

@NgModule({
    imports: [...MODULES],
    providers: [...SERVICES],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
})
export class DataModule {}
