import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ChangedEvent, EditorBaseComponent, MetaService, UserAccountInformationDTO, UserContextRoleDTO, UserContextRoleInformationDTO } from '../../data';
import { cloneDeep } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

export interface InternalInfoChangedEvent extends ChangedEvent {}

const ComponentName = 'user-context-role-internal-info-editor';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: './user-context-role-internal-info-editor.component.html',
    styleUrls: ['./user-context-role-internal-info-editor.component.scss'],
    host: {
        class: 'grid gap-0',
    },
})
export class UserContextRoleInternalInfoEditorComponent extends EditorBaseComponent {
    @Input()
    isNewUser = false;

    @Input()
    accountInfo: UserAccountInformationDTO = {};

    private _contextRole: UserContextRoleDTO = { userRoleContextId: -1 };
    private _contextRoleO: UserContextRoleDTO = { userRoleContextId: -1 };
    @Input()
    set contextRole(cr: UserContextRoleDTO | undefined) {
        this._contextRole = cr ?? { userRoleContextId: -1 };
        this._contextRoleO = cloneDeep(this._contextRole);
        this.emitOnChanged();
    }
    get contextRole(): UserContextRoleDTO {
        return this._contextRole;
    }

    get contextRoleInfo(): UserContextRoleInformationDTO {
        return this._contextRole.information ?? { userRoleContextId: this._contextRole.userRoleContextId };
    }

    get userEmail(): string {
        return this._contextRole.information?.userEmail ?? this.accountInfo.userEmail ?? '';
    }

    get userFullName(): string {
        return this._contextRole.information?.userFullName ?? this.accountInfo.userFullName ?? '';
    }

    constructor(translateSrv: TranslateService, toastr: ToastrService, metaSrv: MetaService, location: Location) {
        super(translateSrv, toastr, metaSrv, location);

        this._translateKey = ComponentName;
    }

    override get isValid(): boolean {
        return true;
    }
}
