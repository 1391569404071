import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MsalService } from '@azure/msal-angular';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    ReadUserRolesRequestDTO,
    ReadUserRolesResponseDTO,
    ReadUserApproverRoleResponseDTO,
    ReadUserApproverRoleRequestDTO,
    ReadContextRequestDTO,
    ReadContextResponseDTO,
    ReadContextRoleProfileDatasetResponseDTO,
    ReadContextRoleProfileDatasetRequestDTO,
} from '../dtos';

import { BaseService } from './base.service';
import { GlobalsService } from './globals.service';

@Injectable()
export class ReportsService extends BaseService {
    private readonly _reportsUrl: string;

    constructor(private readonly _http: HttpClient, globalsSrv: GlobalsService, authSrv: MsalService) {
        super(globalsSrv, authSrv);

        this._reportsUrl = `${this.apiUrl}/Reports`;
    }

    getRoles(request: ReadUserRolesRequestDTO): Observable<ReadUserRolesResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<ReadUserRolesResponseDTO>(this._reportsUrl + '/UserRoles', options).pipe(catchError(this.onHttpError));
    }

    getUserApproverRole(request: ReadUserApproverRoleRequestDTO): Observable<ReadUserApproverRoleResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<ReadUserApproverRoleResponseDTO>(this._reportsUrl + '/UserApproverRoles', options).pipe(catchError(this.onHttpError));
    }

    getContext(request: ReadContextRequestDTO): Observable<ReadContextResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<ReadContextResponseDTO>(this._reportsUrl + '/Contexts', options).pipe(catchError(this.onHttpError));
    }

    readContextRoleProfileDataset(request: ReadContextRoleProfileDatasetRequestDTO): Observable<ReadContextRoleProfileDatasetResponseDTO> {
        const options = {
            params: this.getHttpParams(request),
            headers: this.getHttpHeaders(),
        };

        return this._http
            .get<ReadContextRoleProfileDatasetResponseDTO>(this._reportsUrl + '/ReadContextRoleProfileDataset', options)
            .pipe(catchError(this.onHttpError));
    }
}
