import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { MsalService } from '@azure/msal-angular';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserDirectoryRequestDTO, UserDirectoryResponseDTO } from '../dtos';
import { BaseService } from './base.service';
import { GlobalsService } from './globals.service';
import { isAssigned, toISODateString } from '../utils';

@Injectable()
export class UserDirectoryService extends BaseService {
    private _userDirectoryUrl: string;

    constructor(private readonly _http: HttpClient, globalsSrv: GlobalsService, authSrv: MsalService) {
        super(globalsSrv, authSrv);

        this._userDirectoryUrl = `${this.apiUrl}/UserDirectory`;
    }

    readUsers(request: UserDirectoryRequestDTO): Observable<UserDirectoryResponseDTO> {
        const obj: any = { ...request };

        if (isAssigned(request.requestFromDt)) obj.requestFromDt = toISODateString(request.requestFromDt);
        if (isAssigned(request.requestToDt)) obj.requestToDt = toISODateString(request.requestToDt);

        const options = {
            params: this.getHttpParams(obj),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<UserDirectoryResponseDTO>(this._userDirectoryUrl, options).pipe(catchError(this.onHttpError));
    }

    exportUsers(format: string, request: UserDirectoryRequestDTO): Observable<HttpEvent<Blob>> {
        const obj: any = { ...request };

        if (isAssigned(request.requestFromDt)) obj.requestFromDt = toISODateString(request.requestFromDt);
        if (isAssigned(request.requestToDt)) obj.requestToDt = toISODateString(request.requestToDt);

        const params = this.getHttpParams(obj);
        const headers = this.getHttpHeaders();

        return this._http
            .get(`${this._userDirectoryUrl}/export/${format}`, {
                params: params,
                reportProgress: true,
                observe: 'events',
                responseType: 'blob',
                headers: headers,
            })
            .pipe(catchError(this.onHttpError));
    }
}
