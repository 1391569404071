import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { ColumnsViewDef, ReadUserApproversRequestDTO, UserApproverDTO, UserContextRoleDTO, UsersService, isAssigned } from '../../data';

@Component({
    selector: 'app-account-information-profile-approvers',
    templateUrl: './account-information-profile-approvers.component.html',
    styleUrls: ['./account-information-profile-approvers.component.scss'],
    providers: [ConfirmationService],
})
export class AccountInformationProfileApproversComponent implements OnInit, OnDestroy {
    columns = new ColumnsViewDef([
        {
            name: 'approverEmail',
            header: 'Approver Email',
            width: '33%',
        },
        {
            name: 'approverName',
            header: 'Approver Name',
            width: '33%',
        },
        {
            name: 'approverContextRoleName',
            header: 'Approver Context & Role Name',
            width: '33%',
        },
    ]);

    requestSubs?: Subscription;
    isLoadingUserApprovers = false;
    userApprovers: UserApproverDTO[] = [];
    contextRole: UserContextRoleDTO;
    profileCd: string;

    constructor(private readonly _toastr: ToastrService, private readonly _userSrv: UsersService, private readonly _dialogCfg: DynamicDialogConfig) {
        this.contextRole = this._dialogCfg.data?.contextRole ?? {};
        this.profileCd = this._dialogCfg.data?.profileCd ?? '';
    }

    get isLoading(): boolean {
        return this.isLoadingUserApprovers;
    }

    ngOnInit() {
        this.readUserApprovers();
    }

    ngOnDestroy(): void {
        if (isAssigned(this.requestSubs)) {
            this.requestSubs!.unsubscribe();
        }
    }

    readUserApprovers() {
        if (isAssigned(this.requestSubs)) {
            return;
        }

        this.isLoadingUserApprovers = true;

        this.userApprovers = [];

        let request: ReadUserApproversRequestDTO = {
            contextCd: this.contextRole.contextCd,
            roleCd: this.contextRole.roleCd,
            contextProfileCd: this.profileCd,
            userEmail: this.contextRole.information?.userEmail,
        };

        this._userSrv.readUserApprovers(request).subscribe({
            next: (response) => {
                this.isLoadingUserApprovers = false;
                this.userApprovers = response.userApprovers ?? [];

                if (isAssigned(response.errorMessage) && response.errorMessage !== '') {
                    this._toastr.error(response.errorMessage);
                }
            },
            error: (error) => {
                this.isLoadingUserApprovers = false;
                this._toastr.error(error.message ?? 'Unknow error calling backend API');
            },
        });
    }
}

export function showProfileUserApprovers(dialogSrv: DialogService, profileCd: string, contextRole: UserContextRoleDTO) {
    dialogSrv.open(AccountInformationProfileApproversComponent, {
        header: 'User Approvers',
        width: '90%',
        // height: '50rem',
        contentStyle: { overflow: 'hidden' },
        data: {
            userEmail: contextRole.information?.userEmail,
            contextRole: contextRole,
            profileCd: profileCd?.toLowerCase(),
        },
    });
}
