import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ChipModule } from 'primeng/chip';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';

import { AppHeaderComponent } from './app-header/app-header.component';
import { SaveCancelButtonsComponent } from './save-cancel-buttons/save-cancel-buttons.component';
import { ContextRoleScopePickerComponent } from './context-role-scope-picker/context-role-scope-picker.component';
import { ContextRoleScopePickerFieldComponent } from './context-role-scope-picker/context-role-scope-picker-field.component';
import { AccountInformationScopesComponent } from './account-information/account-information-scopes.component';
import { UserContextRoleInternalAccountEditorComponent } from './user-context-role/user-context-role-internal-account-editor.component';
import { UserContextRoleInternalInfoEditorComponent } from './user-context-role/user-context-role-internal-info-editor.component';
import { UserContextRoleExternalAccountEditorComponent } from './user-context-role/user-context-role-external-account-editor.component';
import { UserContextRoleExternalInfoEditorComponent } from './user-context-role/user-context-role-external-info-editor.component';
import { UserContextRoleDetailsEditorComponent } from './user-context-role/user-context-role-details-editor.component';
import { LoginAsUserComponent } from './login-as-user/login-as-user.component';

import { DataModule } from '../data';
import { InputTextModule } from 'primeng/inputtext';

const MODULES = [
    CommonModule,
    FormsModule,
    TranslateModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    AutoCompleteModule,
    ChipModule,
    CheckboxModule,
    DropdownModule,
    CalendarModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    DataModule,
];

const COMPONENTS = [
    AppHeaderComponent,
    SaveCancelButtonsComponent,
    ContextRoleScopePickerComponent,
    ContextRoleScopePickerFieldComponent,
    AccountInformationScopesComponent,
    UserContextRoleExternalAccountEditorComponent,
    UserContextRoleExternalInfoEditorComponent,
    UserContextRoleInternalAccountEditorComponent,
    UserContextRoleInternalInfoEditorComponent,
    UserContextRoleDetailsEditorComponent,
    LoginAsUserComponent,
];

@NgModule({
    imports: [...MODULES],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    providers: [],
})
export class IdbSharedModule {}
