export const PTTranslation = {
    save: 'Salvar',
    cancel: 'Cancelar',
    close: 'Fechar',
    confirm: 'Confirmar',
    filterByLabel: 'Filtrar por',
    paginatorLabel: 'Página {currentPage} de {totalPages} ({totalRecords} entradas)',
    from: 'De',
    to: 'Para',
    indefinite: 'Indefinido',
    syncB2CNeeded: 'A sincronização da conta B2C é necessária',
    email: 'Email',
    secondaryEmail: 'Email Secundário',
    name: 'Nome',
    userType: 'Tipo de Usuário',
    preferredLanguage: 'Idioma Preferido',
    primaryPhone: 'Telefone',
    mobilePhone: 'Telefone Móvel',
    fax: 'Fax',
    external: 'Externo',
    internal: 'Interno',
    viewHistory: 'Ver Histórico',
    viewAccountHistory: 'Histórico da Conta',
    viewRoleHistory: 'Histórico de Funções',
    viewProfileHistory: 'Histórico de Perfil',
    accessDetails: 'Detalhes de Acesso',
    emailSentSusccess: 'O e-mail de validação foi enviado com sucesso! ',
    emailResentError: 'O e-mail de validação não pode ser enviado! ',
    eaps: {
        title: 'Sistema de Provisão de Contas da Extranet',
        'with-menu': {
            tabMyWork: {
                label: 'Aprovações',
            },
            tabUserDirectory: {
                label: 'Diretório de Usuários',
            },
            tabReports: {
                label: 'Relatórios',
            },
            accountMenu: {
                label: '{{ loggedUser.userName }} em nome de {{ currentUser.userName }} {{{ currentUser.userEmail }}}',
            },
            myAccount: {
                label: 'Minha Conta',
            },
            loginAs: {
                label: 'Entrar Como',
                notification: 'Usuário atual alterado para {{ userName }} ({{ userEmail }})',
            },
            returnUser: {
                label: 'Voltar para {{ userName }}',
            },
        },
    },
    profile: {
        status: {
            pending: 'Pendente',
            recertify: 'Recertificar',
            approve: 'Aprovar',
            approved: 'Aprovado',
            approveAll: 'Aprovar Tudo',
            deny: 'Negar',
            denied: 'Negado',
            denyAll: 'Negar Tudo',
        },
        disableAutoUpdate: {
            disabled: 'Auto Update Desativado de {{ source }}. Clique para ativar',
            enabled: 'Auto Update Ativado de {{ source }}. Clique para desativar',
            confirm: 'Este perfil é atualizado automaticamente de {{ source }}.<br/>Você deseja desativar Auto Update e NEGAR permanentemente o perfil?',
        },
        profileStatus: 'Status',
        profileName: 'Perfil',
        allProfiles: 'Todos os Perfis',
        viewDetails: 'Detalhes do Perfil',
        viewApprovers: 'Aprovadores de Perfil',
        approverName: ' Por',
        statusModifiedDate: 'Em',
    },
    'account-information-scopes': {
        confirm: {
            yes: 'Sim, Prosseguir',
            no: 'Não, Cancelar',
            denyProfile: {
                header: 'Negar PERFIL',
                message: 'Deseja negar o PERFIL?',
                confirm: 'Por favor, confirme que deseja NEGAR o último perfil ativo e desativar permanentemente a conta',
            },
            denyProfiles: {
                header: 'Negar PERFIL',
                message: 'Você gostaria de tomar ação em múltiplos perfis de uma vez?',
            },
            approveProfiles: {
                header: 'Aprovar PERFIS',
                message: 'Você gostaria de tomar ação em múltiplos perfis de uma vez?',
            },
            denyScope: {
                header: 'Negar ESCOPO',
                message: 'Você gostaria de tomar ação em múltiplos escopos de uma vez?',
            },
            approveScope: {
                header: 'Aprovar ESCOPO',
                message: 'Você gostaria de tomar ação em múltiplos escopos de uma vez?',
            },
            denyAll: {
                header: 'Negar Todos os PERFIS e ESCOPOS',
                message: 'Você gostaria de tomar ação em todos os perfis e todos os escopos de uma vez?',
            },
            approveAll: {
                header: 'Aprovar Todos os PERFIS e ESCOPOS',
                message: 'Você gostaria de tomar ação em todos os perfis e todos os escopos de uma vez?',
            },
        },
    },
    'account-information': {
        header: 'Conta de Usuário',
        canSyncB2C: 'Sincronizar B2C',
        userStatus: 'Status',
        userRoles: 'Selecionar Função',
        roleName: 'Função',
        roleStatus: 'Status',
        roleAttributes: 'Atributos da Função',
        roleTimespan: 'Período da Função',
        roleIdbApps: 'Aplicativos IDB',
        missingAccountActivation: 'Reenviar Link de Ativação da Conta',
        addRoleButton: {
            label: 'Adicionar Nova Função',
        },
        addRequestRoleButton: {
            label: 'Solicitar Novo Função',
        },
        editRoleButton: {
            label: 'Editar Função',
        },
        deleteRoleButton: {
            label: 'Excluir Função',
            title: 'O função {{ roleName }} será permanentemente excluído. Se este for o único função ativo, a conta será desativada, resultando na perda de acesso a algumas aplicações do BID. Tem certeza de que deseja continuar?',
        },
        viewIdbAppsButton: {
            showMore: 'Mostrar Mais...',
            showLess: 'Mostrar Menos...',
        },
        role: {
            scopes: {
                scopesProfiles: 'Escopos e Autorizações de Perfil',
                affiliationsProfiles: 'Afiliações e Autorizações de Perfil',
                profiles: 'Autorizações de Perfil',
            },
        },
    },
    'context-role-scope-picker': {
        cancelButtonLabel: 'Cancelar',
        scope: {
            header: 'Adicionar Escopo',
            addButtonLabel: 'Adicionar Novo Escopo',
        },
        affiliation: {
            header: 'Adicionar Afiliação',
            addButtonLabel: 'Adicionar Nova Afiliação',
        },
        exception: {
            header: 'Adicionar Exceção',
            addButtonLabel: 'Adicionar Nova Exceção',
        },
    },
    'context-role-scope-picker-field': {
        all: 'Todos',
        remove: 'Remover',
        allSelected: {
            placeHolder: 'Todos os {{ value }} selecionados',
        },
        selectAdditional: {
            placeHolder: 'Selecionar {{ value }} adicionais',
        },
        selectingNotAllowed: {
            placeHolder: 'Seleção de {{ value }} não permitida',
        },
        selectThe: {
            placeHolder: 'Selecionar o {{ value }}',
        },
    },
    'external-account-request': {
        title: 'Solicitação de Conta de Usuário Externo',
        nextButton: {
            label: 'Próximo',
        },
    },
    'external-account-request-confirm': {
        header: 'Termos e Condições da Extranet',
        acceptCheckbox: {
            label: 'Aceitar termos e condições',
        },
        acceptButton: {
            label: 'Aceitar',
        },
        declineButton: {
            label: 'Voltar',
        },
    },
    'external-account-request-created': {
        title: 'Solicitação de Conta de Usuário Externo',
        sendVerification: {
            label: 'Enviar E-mail de Verificação para AAA@BBB.CCC',
        },
        changeEmail: {
            label: 'Alterar Endereço de E-mail',
        },
    },
    'external-account-request-resend-link': {
        header: 'Reenviar Link de Verificação',
        userEmail: {
            label: 'Email',
        },
        sendButton: {
            label: 'Enviar Email',
        },
    },
    'external-account-request-role-details-editor': {
        and: 'e',
        preferredLanguage: {
            label: 'Idioma Preferido',
            placeholder: 'Selecionar o Idioma Preferido',
        },
        relationship: {
            label: 'Meu Relacionamento Com o Banco É',
            placeholder: 'Selecionar o Relacionamento',
        },
        scopesTableLabel: 'Selecionar {{ value }}',
    },
    'external-verify-email': {
        title: 'Solicitação de Conta de Usuário Externo',
        footer: 'Obrigado, Equipe do BID.',
        responseEmpty: 'Desculpe, sua ação gerou um erro e o suporte do sistema foi notificado. Por favor, tente novamente mais tarde.',
        referenceNum: 'Referência:',
    },
    'user-context-role-editor': {
        title: {
            roleName: 'Editar o Função {{ value }}',
            addNewRole: 'Adicionar Novo Função',
            addNewInternalUser: 'Adicionar Novo Usuário IDB',
            addNewExternalUser: 'Adicionar Novo Usuário Externo',
        },
        noCreateUserPermission: 'Sem permissões para criar um novo usuário',
    },
    'user-context-role-details-editor': {
        preferredLanguage: {
            label: 'Idioma Preferido',
            placeholder: 'Selecione o Idioma Preferido',
        },
        role: {
            title: 'Informações do Função',
            label: 'Função',
            placeholder: 'Selecione o Função',
            grantedFrom: 'Função Concedido de',
            grantedTo: 'Função Concedido até',
            canCreateAccount: 'Permitir ao Usuário Criar Contas (Champion)',
            canRecertify: 'O Usuário Pode Receber Notificações Relacionadas a Aprovações',
        },
        internal: {
            delete: {
                header: 'Excluir Escopos Existente',
                button: 'Remover Escopo',
                undoButton: 'Desfazer Remoção do Escopo',
                warning: 'Apenas escopos opcionais com todos os perfis negados estão disponíveis para exclusão',
            },
            add: {
                header: 'Editar Escopo',
                headerProfile: 'Editar Autorização de Escopo e Perfil',
                warning: 'Por favor, aprove ao menos um perfil para criar uma nova conta',
            },
            addScope: {
                header: 'Editar Escopo',
                headerProfile: 'Editar Autorização de Escopo e Perfil',
                button: 'Adicionar Escopo',
                warning: 'Por favor, aprove ao menos um perfil para criar uma nova conta',
            },
        },
        external: {
            delete: {
                header: 'Excluir Afiliações Existente',
                button: 'Remover Afiliação',
                undoButton: 'Desfazer Remoção da Afiliação',
                warning: 'Apenas afiliações opcionais com todos os perfis negados estão disponíveis para exclusão',
            },
            add: {
                header: 'Editar Afiliação',
                headerProfile: 'Editar Autorização de Afiliação e Perfil',
                warning: 'Por favor, aprove ao menos um perfil para criar uma nova conta',
            },
            addScope: {
                header: 'Editar Afiliação',
                headerProfile: 'Editar Autorização de Afiliação e Perfil',
                button: 'Adicionar Afiliação',
                warning: 'Por favor, aprove ao menos um perfil para criar uma nova conta',
            },
        },
    },
    'user-context-role-external-account-editor': {
        title: 'Informações da Conta',
        email: 'Email',
        retypeEmail: 'Confirmar Email',
        secondaryEmail: 'Email Secundário',
    },
    'user-context-role-external-info-editor': {
        personalTitle: 'Informações Pessoais',
        contactTitle: 'Informações de Contato',
        firstName: 'Primeiro Nome',
        middleName: 'Nome do Meio',
        lastName: 'Sobrenome',
        phone: 'Telefone',
        mobile: 'Celular',
        fax: 'Fax',
    },
    'user-context-role-internal-account-editor': {
        title: 'Informações da Conta',
        searchUser: 'Buscar Usuário',
    },
    'user-context-role-internal-info-editor': {
        title: 'Informações da Conta IDB',
        email: 'E-mail',
        name: 'Nome',
    },
    'my-work': {
        myWorkFilter: {
            label: 'Selecionar um filtro de trabalho',
        },
        filterText: {
            label: 'Texto de Busca',
        },
        clearAllFilters: {
            label: 'Limpar todos os Filtros',
        },
        columnsTypeMore: {
            label: 'Mostrar Mais',
        },
        columnsTypeLess: {
            label: 'Mostrar Menos',
        },
        export: {
            label: 'Exportar',
            xslx: {
                label: 'Exportar para XLSX',
            },
            csv: {
                label: 'Exportar para CSV',
            },
        },
        columns: {
            userEmail: {
                header: 'Email',
                details: {
                    tooltip: 'Detalhes da Conta',
                },
            },
            userRoleName: {
                header: 'Função',
                details: {
                    tooltip: 'Detalhes da Função',
                },
            },
            userProfileName: {
                header: 'Perfil',
                details: {
                    tooltip: 'Detalhes do Perfil',
                },
                approvers: {
                    tooltip: 'Aprovadores do Perfil',
                },
                history: {
                    tooltip: 'Histórico do Perfil',
                },
            },
            userApprovalStatus: {
                header: 'Status de Aprovação',
                approve: {
                    tooltip: 'Aprovar',
                },
                deny: {
                    tooltip: 'Negar',
                },
            },
            userFullName: {
                header: 'Nome',
            },
            userStatus: {
                header: 'Status do Usuário',
            },
            userContextName: {
                header: 'Contexto',
            },
            userRoleStatus: {
                header: 'Status da Função',
            },
            userAssociationValues: {
                header: 'Escopo',
            },
            userAssociationType: {
                header: 'Tipo de Escopo',
            },
            aging: {
                header: 'Envelhecimento',
            },
            approverEmail: {
                header: 'Aprovador/Designado Para',
            },
            approverRoleName: {
                header: 'Função do Aprovador/Designado Para',
            },
            userApprovalActionDt: {
                header: 'Data de Aprovação',
            },
            userType: {
                header: 'Tipo de Usuário',
            },
            requestDt: {
                header: 'Data da Solicitação',
            },
            requestId: {
                header: 'Nº da Solicitação',
            },
        },
    },
    'user-directory': {
        filterText: {
            label: 'Nome ou Email',
        },
        clearAllFilters: {
            label: 'Limpar todos os Filtros',
        },
        columnsTypeMore: {
            label: 'Mostrar Mais',
        },
        columnsTypeLess: {
            label: 'Mostrar Menos',
        },
        export: {
            label: 'Exportar',
            xslx: {
                label: 'Exportar para XLSX',
            },
            csv: {
                label: 'Exportar para CSV',
            },
        },
        addNewUser: {
            label: 'Adicionar Novo Usuário',
            internal: {
                label: 'Novo Usuário Interno',
            },
            external: {
                label: 'Novo Usuário Externo',
            },
            externalForm: {
                label: 'Solicitação de Nova Conta de Usuário Externo',
            },
        },
        columns: {
            userEmail: {
                header: 'Email',
                details: {
                    tooltip: 'Detalhes da Conta',
                },
            },
            userFullName: {
                header: 'Nome',
            },
            userStatus: {
                header: 'Status do Usuário',
            },
            userRoleNames: {
                header: 'Funções Ativas',
            },
            userPendingEmailValidationRoleNames: {
                header: 'Funções Pendentes de Validação por Email',
            },
            userScopeValues: {
                header: 'Escopo',
            },
            userScopeType: {
                header: 'Tipo de Escopo',
            },
            userActiveProfileNames: {
                header: 'Perfis Ativos',
            },
            userPendingProfileNames: {
                header: 'Perfis Pendentes',
            },
            userDeniedProfileNames: {
                header: 'Perfis Negados',
            },
            userLastUpdatedDt: {
                header: 'Última Data de Atualização',
            },
            userIdbApps: {
                header: 'Apps IDB',
            },
            userType: {
                header: 'Tipo de Usuário',
            },
            userIsReadOnly: {
                header: 'Somente Leitura',
            },
            userIsApprover: {
                header: 'É Aprovador',
            },
            requestDt: {
                header: 'Data da Solicitação',
            },
            requestId: {
                header: 'Nº da Solicitação',
            },
            canEdit: {
                header: 'Pode Criar Contas',
            },
        },
    },
    reports: {
        reportFilter: {
            label: 'Selecionar Relatório',
        },
        roles: {
            label: 'Funções',
        },
        roleApprovers: {
            label: 'Aprovadores de Função, Perfis Elegíveis e Aplicativos',
        },
        contexts: {
            label: 'Contextos',
        },
        userRpleDataSets: {
            label: 'Conjuntos de Dados do Função do Usuário',
        },
    },
    'user-role-datasets': {
        userRoleDatasetsFilter: {
            label: 'Selecionar um Função',
        },
    },
    'user-approver-roles': {
        columns: {
            roleName: {
                header: 'Função',
            },
            contextName: {
                header: 'Contexto',
            },
            profileName: {
                header: 'Perfil',
            },
            approverRoleName: {
                header: 'Nome da Função do Aprovador',
            },
            approverRoleLevel: {
                header: 'Nível de Aprovação',
            },
            idbApps: {
                header: 'Apps IDB',
            },
        },
    },
    'user-contexts': {
        columns: {
            contextCode: {
                header: 'Código do Contexto',
            },
            contextName: {
                header: 'Nome do Contexto',
            },
            contextDescription: {
                header: 'Descrição do Contexto',
            },
            contextProfiles: {
                header: 'Perfis do Contexto',
            },
            requestReason: {
                header: 'Motivo da Solicitação',
            },
            requestExpirationDays: {
                header: 'Dias de Expiração da Solicitação',
            },
            redirectToApplicationName: {
                header: 'Redirecionar para o Nome da Aplicação',
            },
            redirectUrl: {
                header: 'URL de Redirecionamento',
            },
            allowManualUserCreation: {
                header: 'Permitir Criação Manual de Usuário',
            },
        },
    },
    'user-roles': {
        columns: {
            roleName: {
                header: 'Função',
            },
            contextName: {
                header: 'Contexto',
            },
            requiredFields: {
                header: 'Campos Obrigatórios',
            },
            optionalFields: {
                header: 'Campos Opcionais',
            },
            isReadOnly: {
                header: 'É Somente Leitura',
            },
            isApprover: {
                header: 'É Aprovador',
            },
            isInternal: {
                header: 'É Interno',
            },
            allowMultipleScopes: {
                header: 'Permitir Múltiplos Escopos ou Afiliações',
            },
        },
    },
    'user-history-view-dialog': {
        header: 'Histórico do Usuário',
        columns: {
            action: {
                header: 'Ação',
            },
            actionPerformedBy: {
                header: 'Ação Realizada Por',
            },
            actionPerformedDt: {
                header: 'Ação Realizada Em',
            },
        },
    },
    'user-context-role-profile-access-details-dialog': {
        header: 'Detalhes de Acesso',
    },
    'user-context-role-scope-profile': {
        approved: {
            permissions: 'Permissões',
        },
        denied: {
            reason: 'Motivo da Negativa',
            enableUpdares: 'Permitir que o perfil seja atualizado automaticamente por {{ processName }}',
            disableUpdares: 'Desativar atualizações automáticas por {{ processName }} e manter o perfil NEGADO',
        },
        pending: {
            keepPendingTill: 'Manter na minha cesta de trabalho até',
            pendingAssignedTo: '{{ toName }} {{ toEmail }} se comprometeu a aprovar esta solicitação pendente de {{ from }}',
            isAssignedToMe: 'Reatribuir a aprovação para mim',
        },
    },
    'login-as-user': {
        header: 'Entrar Como',
        searchUser: 'Por Favor Selecione um Usuário Ativo para Entrar Como',
        loginButton: 'Entrar',
    },
};
