export const environment = {
    production: false,
    // baseurl: 'https://api-np-d-eaps2.azurewebsites.net',
    baseurl: 'https://apim-np-d-idb-integration.iadb.org/ext/api/eaps/v2',
    redirecturl: 'https://eaps.myiadboperations-d.iadb.org/',
    postlogouturl: 'https://eaps.myiadboperations-d.iadb.org/',
    Name: 'eaps-a-np-d',
    tenant: 'idbgextt',
    tenantId: '1f87f52a-35d7-4344-9c79-095847954509',
    clientId: '8d8d727c-3f4a-456e-a818-b5a896a824a8',
    clientScopesId: 'c29b00d6-b77c-4772-9802-bdc5b120a615',
    subscriptionKey: '1fda76a316944f2d94a31752940cc04e',
    isDarkMode: false,
};
