import { PerformedByRequestDTO } from './performed-by-request-dto';
import { TypeScopeDTO } from './type-scope-dto';

export interface SaveUserContextRoleRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    secondaryEmail?: string;
    preferredLanguageCd?: string;
    primaryPhone?: string;
    mobilePhone?: string;
    fax?: string;
    comments?: string;
    objectId?: string;
    roleCd?: string;
    contextCd?: string;
    fromDt?: Date;
    toDt?: Date;
    canCreateAccounts?: boolean;
    canReceiveApprovalNotifications?: boolean;
    isManuallyManaged?: boolean;
    managedByProcessName?: string;
    scopes?: TypeScopeDTO[];
    isInternal?: boolean;
}
