import { ContextRoleScopeComponentItemDTO } from './context-role-scope-component-item-dto';

export const CRSC_CT_DropDown = 'dropdown';
export const CRSC_CT_MultiChoice = 'multichoice';
export const CRSC_CT_Table = 'table';

export interface FieldInfoDTO {
    fieldLabel?: string;
    fieldName?: string;
}

export interface ContextRoleScopeComponentDTO {
    componentNumber?: number;
    componentLabel?: string;
    componentType?: string;
    isRequired?: boolean;
    tableFields?: FieldInfoDTO[];
    dropdownAllOptionAvailable?: boolean;
    currentComponentValue?: string;
    currentComponentDisplayValue?: string;
    isReadOnly?: boolean;
    items?: ContextRoleScopeComponentItemDTO[];
}
