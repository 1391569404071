import { PagePerformedByRequestDTO } from './page-performed-by-request-dto';

export interface ReadContextRoleProfileDatasetRequestDTO extends PagePerformedByRequestDTO {
    contextCd?: string;
    roleCd?: string;
    contextProfileCd?: string;
    languageCd?: string;
    field1?: string;
    field2?: string;
    field3?: string;
    field4?: string;
    field5?: string;
    field6?: string;
}
