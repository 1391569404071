import { PerformedByRequestDTO } from './performed-by-request-dto';

export interface ReadMetaRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
    roleCd?: string;
    contextCd?: string;
    profileCd?: string;
    categories?: string;
    languageCd?: string;
    isInternal?: boolean;
}

export interface ReadAccessPermissionsRequestDTO extends PerformedByRequestDTO {}
