export interface UserApproverDTO {
    approverEmail?: string;
    approverName?: string;
    approverRoleCd?: string;
    approverRoleName?: string;
    approverContextCd?: string;
    approverContextName?: string;
    approverContextRoleName?: string;
    approverUserRoleContextId?: string;
    approvalLevel?: string;
    userRoleCd?: string;
    userContextCd?: string;
    userContextProfileCd?: string;
    userScopeField1Value?: string;
    userScopeField2Value?: string;
    userScopeField3Value?: string;
    userScopeField4Value?: string;
    userEmail?: string;
    userStatus?: string;
    userRoleContextId?: string;
    userRoleContextProfileStatus?: string;
}
