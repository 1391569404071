import { OptionDTO } from '../dtos';
import { isAssigned } from '../utils';

export type AnyKey = keyof any;

export interface ColumnViewDef {
    name: string;
    header?: string;
    displayHeader?: () => string;
    displayValue?: (item: any) => any;
    tooltip?: string;
    displayTooltip?: (item: any) => any;
    icon?: (item: any) => string;
    isFrozen?: boolean;
    isSorted?: boolean;
    isFilter?: number;
    filterOptions?: () => OptionDTO[];
    filterSearchOnMoreThan?: number;
    filterValue?: (filterValue: any, filterDestiny: any) => void;
    isValidKey?: (key: string) => boolean;
    width?: string;
    data?: any;
}

export class ColumnsViewDef {
    private _columns: ColumnViewDef[] = [];
    private _frozenColumns: ColumnViewDef[] = [];
    private _unfrozenColumns: ColumnViewDef[] = [];

    constructor(columns: ColumnViewDef[]) {
        const filterColumns: string[] = [];

        columns.forEach((csrc) => {
            const cdst: ColumnViewDef = {
                name: csrc.name,
                header: csrc.header,
                displayHeader: csrc.displayHeader,
                displayValue: csrc.displayValue,
                tooltip: csrc.tooltip,
                displayTooltip: csrc.displayTooltip,
                icon: csrc.icon,
                isFrozen: csrc.isFrozen,
                isSorted: csrc.isSorted,
                filterSearchOnMoreThan: 10,
                filterValue: csrc.filterValue,
                isValidKey: csrc.isValidKey,
                width: csrc.width,
                data: csrc.data,
            };

            if (!isAssigned(cdst.displayHeader)) {
                cdst.displayHeader = () => cdst.header ?? '';
            }

            if (!isAssigned(cdst.displayValue)) {
                cdst.displayValue = (element) => element[cdst.name as AnyKey];
            }

            if (isAssigned(cdst.tooltip) && cdst.tooltip !== '') {
                cdst.displayTooltip = (element) => cdst.tooltip ?? '';
            } else if (!isAssigned(cdst.displayTooltip)) {
                cdst.displayTooltip = (element) => undefined;
            }

            if (isAssigned(csrc.isFilter) && csrc.isFilter! >= 0) {
                filterColumns.push(cdst.name);
                cdst.isFilter = csrc.isFilter;
                cdst.filterOptions = csrc.filterOptions;

                if (isAssigned(csrc.filterSearchOnMoreThan) && csrc.isFilter! === 2) {
                    const m = csrc.filterSearchOnMoreThan ?? -1;
                    cdst.filterSearchOnMoreThan = m > 0 ? (m > 10 ? m : 10) : undefined;
                }
            }

            this._columns.push(cdst);

            if (cdst.isFrozen === true) {
                this._frozenColumns.push(cdst);
            } else {
                this._unfrozenColumns.push(cdst);
            }
        });

        this._filterColumns = filterColumns;

        this._hasFilters = filterColumns.length > 0;
    }

    get columns(): ColumnViewDef[] {
        return this._columns;
    }

    get frozenColumns(): ColumnViewDef[] {
        return this._frozenColumns;
    }

    get unfrozenColumns(): ColumnViewDef[] {
        return this._unfrozenColumns;
    }

    private _hasFilters = false;
    get hasFilters(): boolean {
        return this._hasFilters;
    }

    private _filterColumns: string[] = [];
    get filterColumns(): string[] {
        if (this._hasFilters) {
            return this._filterColumns;
        }

        return [];
    }

    setFilterValues(filters: any, values: any) {
        this._filterColumns.forEach((column) => {
            let col = this.columns.find((col) => col.name === column);
            let filter = filters[column as AnyKey];

            if (isAssigned(filter) && isAssigned(filter.value)) {
                if (isAssigned(col) && isAssigned(col!.filterValue)) {
                    col!.filterValue!(filter.value, values);
                } else {
                    values[column as AnyKey] = filter.value;
                }
            } else {
                delete values[column as AnyKey];
            }
        });
    }

    hasTooltip(col: ColumnViewDef): boolean {
        return isAssigned(col) && ((isAssigned(col.tooltip) && col.tooltip !== '') || isAssigned(col.displayTooltip));
    }

    displayTooltip(col: ColumnViewDef, item: any): any {
        if (isAssigned(col) && isAssigned(col.displayTooltip)) {
            const tooltip = col.displayTooltip!(item);
            return tooltip;
            // return col.displayTooltip!(item);
        }
        return undefined;
    }
}
