import { ReadContextRoleScopePickerRequestBaseDTO } from './read-context-role-scope-picker-request-base-dto';
import { TypeScopeDTO } from './type-scope-dto';

export interface ReadContextRoleScopePickerRequestDTO extends ReadContextRoleScopePickerRequestBaseDTO {
    component1Filter?: string;
    component2Filter?: string;
    component3Filter?: string;
    component4Filter?: string;
    existingScope?: TypeScopeDTO[];
}
