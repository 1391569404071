// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .idb-table-actions {
  min-width: 3.5rem !important;
  width: 3.5rem !important;
}
::ng-deep .idb-scope-deleted {
  color: rgba(128, 128, 128, 0.7) !important;
  background-color: rgba(255, 0, 0, 0.25) !important;
}
::ng-deep idb-switch {
  margin: 0.15rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/account-information/account-information-scopes.component.scss"],"names":[],"mappings":"AACI;EACI,4BAAA;EACA,wBAAA;AAAR;AAGI;EACI,0CAAA;EACA,kDAAA;AADR;AAII;EACI,0BAAA;AAFR","sourcesContent":["::ng-deep {\r\n    .idb-table-actions {\r\n        min-width: 3.5rem !important;\r\n        width: 3.5rem !important;\r\n    }\r\n\r\n    .idb-scope-deleted {\r\n        color: rgba(gray, 0.7) !important;\r\n        background-color: rgba(red, 0.25) !important;\r\n    }\r\n\r\n    idb-switch {\r\n        margin: 0.15rem !important;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
