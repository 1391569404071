import { FilterDataRequestDTO } from './filter-data-request-dto';

export interface UserDirectoryRequestDTO extends FilterDataRequestDTO {
    userEmail?: string;
    userFullName?: string;
    userStatus?: string;
    userType?: string;
    userRoleNames?: string;
    userContextNames?: string;
    userActiveProfileNames?: string;
    userPendingProfileNames?: string;
    userLastUpdatedFromDt?: Date;
    userLastUpdatedToDt?: Date;
    userIdbApps?: string;
    userIsReadOnly?: string;
    userIsApprover?: string;
    requestId?: string;
    requestFromDt?: Date;
    requestToDt?: Date;
    canEdit?: boolean;
    userScope?: string;
    userScopeType?: string;
    languageCd?: string;
}
