import { PerformedByRequestDTO } from './performed-by-request-dto';

export interface ReadUserContextRoleExternalRequestRequestDTO extends PerformedByRequestDTO {
    requestKey?: string;
}

export interface ReadUserContextRoleInternalRequestRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
    userRoleContextId?: number;
    languageCd?: string;
}
