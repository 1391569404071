import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { MsalService } from '@azure/msal-angular';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ReadWorkResponseDTO, ReadWorkRequestDTO } from '../dtos';

import { BaseService } from './base.service';
import { isAssigned, toISODateString } from '../utils';
import { GlobalsService } from './globals.service';

@Injectable()
export class WorkService extends BaseService {
    private _workUrl: string;

    constructor(private readonly _http: HttpClient, globalsSrv: GlobalsService, authSrv: MsalService) {
        super(globalsSrv, authSrv);

        this._workUrl = `${this.apiUrl}/Work`;
    }

    readWork(request: ReadWorkRequestDTO): Observable<ReadWorkResponseDTO> {
        const obj: any = { ...request };

        if (isAssigned(request.userApprovalActionFromDt)) obj.userApprovalActionFromDt = toISODateString(request.userApprovalActionFromDt);
        if (isAssigned(request.userApprovalActionToDt)) obj.userApprovalActionToDt = toISODateString(request.userApprovalActionToDt);

        if (isAssigned(request.requestFromDt)) obj.requestFromDt = toISODateString(request.requestFromDt);
        if (isAssigned(request.requestToDt)) obj.requestToDt = toISODateString(request.requestToDt);

        const options = {
            params: this.getHttpParams(obj),
            headers: this.getHttpHeaders(),
        };

        return this._http.get<ReadWorkResponseDTO>(this._workUrl, options).pipe(catchError(this.onHttpError));
    }

    exportWorks(format: string, request: ReadWorkRequestDTO): Observable<HttpEvent<Blob>> {
        const obj: any = { ...request };

        if (isAssigned(request.userApprovalActionFromDt)) obj.userApprovalActionFromDt = toISODateString(request.userApprovalActionFromDt);
        if (isAssigned(request.userApprovalActionToDt)) obj.userApprovalActionToDt = toISODateString(request.userApprovalActionToDt);

        if (isAssigned(request.requestFromDt)) obj.requestFromDt = toISODateString(request.requestFromDt);
        if (isAssigned(request.requestToDt)) obj.requestToDt = toISODateString(request.requestToDt);

        const params = this.getHttpParams(obj);
        const headers = this.getHttpHeaders();

        return this._http
            .get(`${this._workUrl}/export/${format}`, { params: params, reportProgress: true, observe: 'events', responseType: 'blob', headers: headers })
            .pipe(catchError(this.onHttpError));
    }
}
