import { PerformedByRequestDTO } from './performed-by-request-dto';
import { UserContextRoleScopeProfileIdDTO } from './user-context-role-scope-profile-id-dto';

export interface SaveUserContextRoleScopeProfileRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
    userContextRoleId?: number;
    profileStatus?: string;
    deactivationReason?: string;
    isInternal?: boolean;
    scopes?: UserContextRoleScopeProfileIdDTO[];
}
