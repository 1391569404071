import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '../environments/environment';

// this checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

//B2C policies and user flows
export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1A_SUSI_IDBG',
        resetPassword: 'B2C_1A_SSPR_IDBG',
        MFA: 'B2C_1A_SUSI_IDBGMFA',
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://' + environment.tenant + '.b2clogin.com/' + environment.tenant + '.onmicrosoft.com/B2C_1A_SUSI_IDBG',
        },
        resetPassword: {
            authority: 'https://' + environment.tenant + '.b2clogin.com/' + environment.tenant + '.onmicrosoft.com/B2C_1A_SSPR_IDBG',
        },
        editPreferred: {
            authority: 'https://' + environment.tenant + '.b2clogin.com/' + environment.tenant + '.onmicrosoft.com/B2C_1A_PE_IDBG',
        },
    },
    authorityDomain: environment.tenant + '.b2clogin.com',
};

// Authentication Configuration
export const msalConfig: Configuration = {
    auth: {
        clientId: environment.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: environment.redirecturl,
        postLogoutRedirectUri: environment.postlogouturl,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
        iframeHashTimeout: 9000,
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string, containsPii: boolean) {
                if (containsPii) {
                    return;
                }
                switch (logLevel) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
    scopes: ['openid', 'profile', 'email'],
};
