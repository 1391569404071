export interface OptionDTO {
    category?: string;
    val: any;
    label: string;
    sortOrder?: number;
}

export interface OptionsMapDTO {
    [key: string]: OptionDTO[];
}
