import { HttpErrorResponse, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

import { blobToString, filterAssignedData, isAssigned, isEmpty } from '../utils';

import { environment } from '../../../environments/environment';
import { PerformedByRequestDTO, RequestDTO } from '../dtos';
import { GlobalsService } from './globals.service';

export const UnknownErrorCallingAPIMessage = 'Unknow error calling backend API';

export class BaseService {
    private _apiUrl: string;

    constructor(private readonly _globalsSrv: GlobalsService, private readonly _authSrv: MsalService, apiUrl?: string) {
        this._apiUrl = apiUrl ?? environment.baseurl;
    }

    protected get globalsSrv(): GlobalsService {
        return this._globalsSrv;
    }

    protected get authSrv(): MsalService {
        return this._authSrv;
    }

    protected get apiUrl(): string {
        return this._apiUrl;
    }

    protected onHttpError(error: HttpErrorResponse) {
        let message = error.message?.toLowerCase()?.startsWith('http failure response') !== true ? error.message : undefined;

        if (isEmpty(message)) {
            switch (error.status) {
                case 400:
                    message = 'Bad Request';
                    break;
                case 401:
                    message = 'Unauthorized';
                    break;
                case 403:
                    message = 'Forbidden';
                    break;
                case 404:
                    message = 'Not Found';
                    break;
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 503:
                    message = 'Service Unavailable';
                    break;
                default:
                    message = error.statusText;
                    break;
            }
        }

        return throwError(() => new Error(message));
    }

    protected getHttpParams<TRequest extends RequestDTO>(request?: TRequest): HttpParams {
        if (isAssigned(request)) {
            return new HttpParams({
                fromObject: this.fillRequestData(request) as any,
                encoder: new HttpUrlEncodingCodec(),
            });
        }

        return new HttpParams({
            encoder: new HttpUrlEncodingCodec(),
        });
    }

    protected getHttpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
            'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
        });
    }

    protected fillRequestData<TRequest extends PerformedByRequestDTO>(request?: TRequest): TRequest | undefined {
        if ((request as any).dontSendOnBehalf === true) {
            (request as any).dontSendOnBehalf = undefined;
        } else if (isEmpty(request!.onBehalf)) {
            const currentUser = this._globalsSrv.currentUser;
            const loggedUser = this._globalsSrv.loggedUser;

            if (!isEmpty(currentUser?.userEmail) && currentUser?.userEmail !== loggedUser?.userEmail) {
                request!.onBehalf = currentUser!.userEmail;
            } else {
                request!.onBehalf = undefined;
            }
        }

        return filterAssignedData(request);
    }
}
