export interface ActionDefBase {
    label?: string;
    icon?: string;
    styleClass?: string;
}

export interface ActionDef extends ActionDefBase {
    isVisible?: () => boolean;
    run: () => void;
    tooltip?: () => any;
    getIcon?: () => string;
}

export interface ActionItemDef extends ActionDefBase {
    isVisible?: (item: any) => boolean;
    run: (item: any) => void;
    tooltip?: (item: any) => any;
    getIcon?: (item: any) => string;
}
