import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-save-cancel-buttons',
    templateUrl: './save-cancel-buttons.component.html',
    styleUrls: ['./save-cancel-buttons.component.scss'],
    host: {
        class: 'flex flex-column align-items-end',
    },
})
export class SaveCancelButtonsComponent {
    @Input()
    loading = false;

    @Input()
    saveVisible = true;

    @Input()
    saveEnabled = false;

    @Input()
    saveLabel = 'save';

    @Input()
    cancelVisible = true;

    @Input()
    cancelEnabled = true;

    @Input()
    cancelLabel = 'cancel';

    @Output()
    onSave = new EventEmitter();

    @Output()
    onCancel = new EventEmitter();

    constructor() {}

    doSave() {
        this.onSave.emit();
    }

    doCancel() {
        this.onCancel.emit();
    }
}
