import { DynamicTableBaseDTO, DynamicTableFieldDTO, DynamicTableValueDTO } from './dynamic-table-dto';
import { ResponseDTO } from './response-dto';

export interface UserContextRoleProfileAccessDetailsFieldDTO extends DynamicTableFieldDTO {}

export interface UserContextRoleProfileAccessDetailsValueDTO extends DynamicTableValueDTO {}

export interface UserContextRoleProfileAccessDetailsDTO
    extends DynamicTableBaseDTO<UserContextRoleProfileAccessDetailsFieldDTO, UserContextRoleProfileAccessDetailsValueDTO> {}

export interface ReadUserContextRoleProfileAccessDetailsResponseDTO extends ResponseDTO {
    accessDetails?: UserContextRoleProfileAccessDetailsDTO;
}
