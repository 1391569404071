import { PageInformationDTO } from './page-information-dto';
import { ResponseDTO } from './response-dto';

export interface PageResponseDTO extends ResponseDTO {
    pageInformation?: PageInformationDTO;
}

export interface PageRowsResponseDTO<TItem> extends PageResponseDTO {
    totalCount?: number;
    lastPage?: number;
    data?: TItem[];
}
