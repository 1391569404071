import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ColumnsViewDef, ContextRoleService, MetaService, SaveUserContextRoleProfileRequestDTO, UserContextRoleProfileStatusPermissionDTO } from '../../data';
import { UserContextRoleScopeProfileBase } from './user-context-role-scope-profile-base';

@Component({
    selector: 'app-user-context-role-scope-profile-approved',
    templateUrl: './user-context-role-scope-profile-approved.component.html',
    styleUrls: ['./user-context-role-scope-profile-approved.component.scss'],
    providers: [ConfirmationService, DynamicDialogRef],
})
export class UserContextRoleScopeProfileApprovedComponent extends UserContextRoleScopeProfileBase {
    columns: ColumnsViewDef = new ColumnsViewDef([
        {
            name: 'permissionCd',
            header: '',
            width: '3rem',
        },
        {
            name: 'permissionName',
            header: '',
        },
    ]);

    permissions: UserContextRoleProfileStatusPermissionDTO[] = [];
    prevPermissions?: string;
    currPermissions?: string;

    constructor(
        confirmSrv: ConfirmationService,
        dialogSrv: DialogService,
        contextRoleSrv: ContextRoleService,
        dialogRef: DynamicDialogRef,
        dialogCfg: DynamicDialogConfig,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(confirmSrv, dialogSrv, contextRoleSrv, dialogRef, dialogCfg, translateSrv, toastr, metaSrv, location);

        this._translateKey = `${this._translateKey}.approved`;
    }

    get isApproveValidated(): boolean {
        return this.prevPermissions !== this.currPermissions;
    }

    override onReadProfilePermissions(): void {
        this.permissions = this.profile.permissions ?? [];

        this.prevPermissions = this.permissionsToString();
        this.currPermissions = this.prevPermissions;
    }

    get defaultPermissionSelected(): string {
        if (this.permissions.length > 0) {
            return this.permissions[0].permissionCd ?? '';
        }

        return '';
    }

    get allowMultiplePermissions(): boolean {
        return this.profile.allowMultiplePermissions === true;
    }

    getPermissionButtonIcon(item: UserContextRoleProfileStatusPermissionDTO): string {
        if (item.isSelected === true) {
            return 'pi pi-check';
        }
        return 'pi';
    }

    getPermissionButtonClass(item: UserContextRoleProfileStatusPermissionDTO): string {
        let res = 'p-button p-button-outlined idb-action-button';

        if (this.profile.canEdit !== true) {
            res = res + ' idb-not-allowed';
        }

        return res;
    }

    onPermissionButtonClick(item: UserContextRoleProfileStatusPermissionDTO) {
        if (this.allowMultiplePermissions) {
            item.isSelected = !item.isSelected;
        } else {
            this.permissions.forEach((p) => {
                if (p !== item) {
                    p.isSelected = false;
                }
            });
            item.isSelected = true;
        }

        this.currPermissions = this.permissionsToString();
    }

    permissionsToString(): string {
        const result = this.permissions
            .filter((p) => p.isSelected === true)
            .map((p) => p.permissionCd)
            .join(',');

        return result;
    }

    onSave() {
        const permissions = this.permissionsToString();

        const request: SaveUserContextRoleProfileRequestDTO = {
            profileStatus: 'approved',
            profilePermissionsList: permissions,
        };

        this.saveProfilePermissions(request);
    }
}
