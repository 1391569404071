import { UserContextRoleIdDTO } from './user-context-role-id-dto';
import { UserContextRoleInformationDTO } from './user-context-role-information-dto';
import { UserContextRoleScopeDTO } from './user-context-role-scope-dto';
import { UserContextRoleScopeFieldDTO } from './user-context-role-scope-field-dto';

export interface UserContextRoleDTO extends UserContextRoleIdDTO {
    showAllProfiles?: boolean;
    information?: UserContextRoleInformationDTO;
    scopeFields?: UserContextRoleScopeFieldDTO[];
    scopes?: UserContextRoleScopeDTO[];
}
