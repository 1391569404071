import { ResponseDTO } from './response-dto';
import { UserAccountInformationDTO } from './user-account-information-dto';
import { UserContextRoleDTO } from './user-context-role-dto';

export interface ReadUserResponseDTO extends ResponseDTO {
    accountInformation?: UserAccountInformationDTO;
    contextRoles?: UserContextRoleDTO[];
}

export interface ResendActivationEmailResponseDTO extends ResponseDTO {}
