import { Component, Input, Output, EventEmitter } from '@angular/core';
import { isAssigned } from '../../utils';

const MinDate: Date = new Date(1900, 0);
const MaxDate: Date = new Date(9999, 11, 31);

export interface DateRange {
    from?: Date;
    to?: Date;
}

@Component({
    selector: 'idb-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent {
    private _dates: DateRange = {};
    get dates() {
        return this._dates;
    }
    @Input('dates') set dates(dates: DateRange) {
        this._dates = isAssigned(dates) ? dates : {};
        this.minDate = MinDate;
        this.maxDate = MaxDate;
    }
    @Output() datesChange = new EventEmitter<DateRange>();

    @Input() readonly: boolean = false;
    @Input() disabled: boolean = false;

    @Input() fromPlaceholder: string = 'From';
    @Input() toPlaceholder: string = 'To';

    minDate: Date = MinDate;
    maxDate: Date = MaxDate;

    onFromChange(from: Date | undefined) {
        this._dates.from = from;
        this.minDate = from ?? MinDate;

        this.datesChange.emit(this._dates);
    }

    onToChange(to: Date | undefined) {
        this._dates.to = to;
        this.maxDate = to ?? MaxDate;

        this.datesChange.emit(this._dates);
    }
}
