export interface UserDirectoryDTO {
    userEmail?: string;
    userFullName?: string;
    userStatus?: string;
    userType?: string;
    userRoleNames?: string;
    userContextNames?: string;
    userActiveProfileNames?: string;
    userPendingProfileNames?: string;
    userPendingEmailValidationRoleNames?: string;
    UserDeniedProfileNames?: string;
    userScopeValues?: string;
    userScopeType?: string;
    userScopeDisplayValues?: string;
    userLastUpdatedDt?: Date;
    userIdbApps?: string;
    userIsReadOnly?: string;
    userIsApprover?: string;
    canEdit?: boolean;
}
