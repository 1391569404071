import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { isEmpty } from './data';

const AuthScopes = ['openid', 'profile', 'email'];

@Injectable()
export class AppAuthInterceptor implements HttpInterceptor {
    constructor(private msalService: MsalService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return new Observable((observer) => {
            const activeAccount = this.msalService.instance.getActiveAccount();

            if (!activeAccount) {
                const accounts = this.msalService.instance.getAllAccounts();
                if (accounts.length > 0) {
                    this.msalService.instance.setActiveAccount(accounts[0]);
                }
            }

            const account = this.msalService.instance.getActiveAccount();
            if (account) {
                this.msalService.instance
                    .acquireTokenSilent({ scopes: AuthScopes, account: account })
                    .then((response: AuthenticationResult) => {
                        const token = !isEmpty(response.accessToken) ? response.accessToken : response.idToken;

                        const authReq = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        next.handle(authReq).subscribe(
                            (event) => observer.next(event),
                            (err) => observer.error(err),
                            () => observer.complete()
                        );
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            this.msalService.instance.acquireTokenRedirect({ scopes: AuthScopes });
                        } else {
                            observer.error(error);
                        }
                    });
            } else {
                observer.error('No active account found');
            }
        });
    }
}
