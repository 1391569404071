// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep idb-data-field > .idb-label {
  padding-bottom: 0;
}
::ng-deep idb-data-field > .idb-label > .grid {
  padding-top: 0;
  padding-bottom: 0;
}
::ng-deep idb-data-field > .idb-value {
  padding-top: 0;
}
::ng-deep idb-data-field > .idb-value > .grid {
  padding-top: 0;
  padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/data/components/data-field/data-field.component.scss"],"names":[],"mappings":"AAEQ;EACI,iBAAA;AADZ;AAGY;EACI,cAAA;EACA,iBAAA;AADhB;AAIQ;EACI,cAAA;AAFZ;AAIY;EACI,cAAA;EACA,iBAAA;AAFhB","sourcesContent":["::ng-deep {\r\n    idb-data-field {\r\n        > .idb-label {\r\n            padding-bottom: 0;\r\n\r\n            > .grid {\r\n                padding-top: 0;\r\n                padding-bottom: 0;\r\n            }\r\n        }\r\n        > .idb-value {\r\n            padding-top: 0;\r\n\r\n            > .grid {\r\n                padding-top: 0;\r\n                padding-bottom: 0;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
