import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { MetaService, UsersService, isAssigned, isEmailValid } from '../../data';
import { UserContextRoleAccountEditorBase } from './user-context-role-account-editor-base.component';

const ComponentName = 'user-context-role-external-account-editor';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: `./user-context-role-external-account-editor.component.html`,
    styleUrls: [`./user-context-role-external-account-editor.component.scss`],
    host: {
        class: 'grid gap-0',
    },
})
export class UserContextRoleExternalAccountEditorComponent extends UserContextRoleAccountEditorBase {
    private _retypeUserEmailTimeout: any = null;

    retypeUserEmail: string | undefined;

    @Input()
    checkIfEmailExists = true;

    constructor(private readonly _userSrv: UsersService, translateSrv: TranslateService, toastr: ToastrService, metaSrv: MetaService, location: Location) {
        super(translateSrv, toastr, metaSrv, location);

        this._translateKey = ComponentName;

        this._isInternal = false;
    }

    override get isEmailValid(): boolean {
        return super.isEmailValid && this.isRetypeEmailValid;
    }

    override get isValid(): boolean {
        return this.isEmailValidated && this.isRetypeEmailValid && this.isSecondaryEmailValid;
    }

    get isRetypeEmailValid(): boolean {
        return this.isNewUser
            ? isAssigned(this.retypeUserEmail) &&
                  this.retypeUserEmail !== '' &&
                  this.retypeUserEmail === this.contextRole.information?.userEmail &&
                  isEmailValid(this.retypeUserEmail!)
            : true;
    }

    get isEmailValidated(): boolean {
        return this.isNewUser
            ? this.isEmailValid && this.isRetypeEmailValid && (this.checkIfEmailExists === false || this.userEmailExists === false)
            : this.isEmailValid;
    }

    get isSecondaryEmailValid(): boolean {
        return true;
    }

    get emailPattern(): string {
        return '^\\S(.*\\S)?$';
    }

    onRetypeUserEmailChanged($e: any) {
        clearTimeout(this._retypeUserEmailTimeout);

        if (this.checkIfEmailExists) {
            this._retypeUserEmailTimeout = setTimeout(() => {
                this.checkUserEmail(this.toastr, this._userSrv);
            }, 1000);
        } else {
            this.userEmailExists = undefined;
            this.emitOnChanged();
        }
    }
}
