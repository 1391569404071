import { NgModule } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { ToastrModule } from 'ngx-toastr';

import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { PagesModule } from './pages/pages.module';

// MSAL Configuration
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
} from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './app-msal-config';

import { IdbSharedModule } from './shared/shared.module';
import { AppAuthInterceptor } from './app-auth.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppMenuItemComponent } from './app-menu-item.component';
import { AppWithMenuComponent } from './app-with-menu.component';
import { AppWithoutMenuComponent } from './app-without-menu.component';
import { AppComponent } from './app.component';

// Configuration parameters to create an MSAL instance.
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest,
    };
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppMenuItemComponent, AppWithMenuComponent, AppWithoutMenuComponent, AppComponent],
    imports: [
        BrowserModule,
        MsalModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({ timeOut: 20000, progressBar: true, positionClass: 'toast-bottom-right' }),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        TabMenuModule,
        MenubarModule,
        IdbSharedModule,
        AppRoutingModule,
    ],
    providers: [
        DatePipe,
        DecimalPipe,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        MsalService,
        MsalBroadcastService,
        MsalGuard,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
