import { OptionsMapDTO } from './option-dto';
import { ResponseDTO } from './response-dto';

export interface ReadMetaResponseDTO extends ResponseDTO {
    meta: OptionsMapDTO;
}

export interface ReadAccessPermissionsResponseDTO extends ResponseDTO {
    permissions: string[];
}
