import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { GlobalsService, isAssigned } from './data';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {
        class: 'flex flex-column overflow-hidden h-full',
    },
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    private _langSubs?: Subscription;

    constructor(
        private readonly _globalsSrv: GlobalsService,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this._langSubs = this._globalsSrv.languageChange$.subscribe((lang) => {
            this._translateService.use(lang);
        });        
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        if (isAssigned(this._langSubs)) {
            this._langSubs?.unsubscribe();
            this._langSubs = undefined;
        }
    }
}
