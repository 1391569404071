import { DatePipe, Location } from '@angular/common';
import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
    BaseComponent,
    ColumnViewDef,
    ColumnsViewDef,
    ContextRoleService,
    MetaService,
    OptionsMapDTO,
    ReadUserContextRoleProfileAccessDetailsRequestDTO,
    UnknownErrorCallingAPIMessage,
    UserContextRoleProfileAccessDetailsDTO,
    UserContextRoleProfileAccessDetailsValueDTO,
    isAssigned,
} from '../../data';

const ComponentName = 'user-context-role-profile-access-details-dialog';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: './user-context-role-profile-access-details-dialog.component.html',
    styleUrls: ['./user-context-role-profile-access-details-dialog.component.scss'],
    providers: [ConfirmationService],
    host: {
        class: 'flex flex-column',
        style: 'height: 50rem; max-height: 50rem;',
    },
})
export class UserContextRoleProfileAccessDetailsDialogComponent extends BaseComponent {
    columns = new ColumnsViewDef([]);

    requestSubs?: Subscription;
    isLoadingAccessDetails = false;
    userRoleContextProfileId?: number;
    accessDetails: UserContextRoleProfileAccessDetailsDTO = {};

    constructor(
        private readonly _contextRoleSrv: ContextRoleService,
        private readonly _dialogCfg: DynamicDialogConfig,
        private readonly _dialogRef: DynamicDialogRef,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(toastr, metaSrv, location, translateSrv);
        this._translateKey = ComponentName;

        this.metaOptions = this._dialogCfg.data?.metaOptions;
        this.userRoleContextProfileId = this._dialogCfg.data?.userRoleContextProfileId;
    }

    get values(): UserContextRoleProfileAccessDetailsValueDTO[] {
        return this.accessDetails.values ?? [];
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this.isLoadingAccessDetails;
    }

    private generateColumns() {
        const columns: ColumnViewDef[] = [];
        this.accessDetails.fields?.forEach((sf) => {
            if (isAssigned(sf.fieldMapping)) {
                const c: ColumnViewDef = { name: sf.fieldMapping ?? '', header: sf.fieldName, data: sf, width: sf.fieldType === 'text' ? '15rem' : '20rem' };

                columns.push(c);
            }
        });

        this.columns = new ColumnsViewDef(columns);
    }

    protected override onDestroy(): void {
        if (isAssigned(this.requestSubs)) {
            this.requestSubs!.unsubscribe();
        }

        super.onDestroy();
    }

    protected override onLangChanged(): void {
        this.readAccessDetails();
    }

    onCancel() {
        this._dialogRef.close();
    }

    readAccessDetails() {
        if (isAssigned(this.requestSubs)) {
            return;
        }

        this.isLoadingAccessDetails = true;
        this.emitOnLoading();

        this.accessDetails = {};

        const request: ReadUserContextRoleProfileAccessDetailsRequestDTO = {
            userRoleContextProfileId: this.userRoleContextProfileId,
            languageCd: this.translateSrv.currentLang,
        };

        this.requestSubs = this._contextRoleSrv.readUserContextRoleProfileAccessDetails(request).subscribe({
            next: (response) => {
                this.isLoadingAccessDetails = false;
                this.emitOnLoading();

                this.showErrorMessage(response);

                this.accessDetails = response.accessDetails ?? {};
                this.generateColumns();

                this.requestSubs = undefined;
            },
            error: (error) => {
                this.isLoadingAccessDetails = false;
                this.emitOnLoading();

                this.toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
            },
        });
    }
}

export function showProfileAccessDetails(dialogSrv: DialogService, metaOptions: OptionsMapDTO, userRoleContextProfileId: number) {
    dialogSrv.open(UserContextRoleProfileAccessDetailsDialogComponent, {
        showHeader: false,
        width: '90%',
        contentStyle: { overflow: 'hidden' },
        data: {
            metaOptions: metaOptions,
            userRoleContextProfileId: userRoleContextProfileId,
        },
    });
}
