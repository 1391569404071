import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { isAssigned, isEmpty } from './data';
import { environment } from 'src/environments/environment';

function getScopeUrl(scopeName: string): string {
    return `https://${environment.tenant}.onmicrosoft.com/${environment.clientScopesId}/${scopeName}`;
}

const LoginAuthScopes = ['openid', 'profile', 'email'];
const AccessAuthScopes = [
    getScopeUrl('Approvals.Read'),
    getScopeUrl('Users.Read'),
    getScopeUrl('User.Read'),
    getScopeUrl('User.Update'),
    getScopeUrl('Reports.Read'),
];

const excludedBrowserUrls = [`${environment.redirecturl}/external/`];

@Injectable()
export class AppAuthInterceptor implements HttpInterceptor {
    constructor(private msalService: MsalService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentBrowserUrl = window.location.href;
        if (excludedBrowserUrls.some((url) => currentBrowserUrl.startsWith(url))) {
            return next.handle(req);
        }

        return new Observable((observer) => {
            const activeAccount = this.msalService.instance.getActiveAccount();

            if (!isAssigned(activeAccount)) {
                const accounts = this.msalService.instance.getAllAccounts();
                if (accounts.length > 0) {
                    this.msalService.instance.setActiveAccount(accounts[0]);
                }
            }

            const account = this.msalService.instance.getActiveAccount();
            if (account) {
                this.msalService.instance
                    .acquireTokenSilent({ scopes: AccessAuthScopes, account: account })
                    .then((response: AuthenticationResult) => {
                        const token = !isEmpty(response.accessToken) ? response.accessToken : response.idToken;
                        const authReq = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        next.handle(authReq).subscribe(
                            (event) => observer.next(event),
                            (err) => observer.error(err),
                            () => observer.complete()
                        );
                    })
                    .catch((error) => {
                        console.log('Error Getting Token', error);
                        if (error instanceof InteractionRequiredAuthError) {
                            this.msalService.instance.acquireTokenRedirect({ scopes: LoginAuthScopes });
                        } else {
                            observer.error(error);
                        }
                    });
            } else {
                observer.error('No active account found');
            }
        });
    }
}
