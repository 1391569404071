export interface UserRolesDTO {
    roleName?: string;
    contextName?: string;
    requiredFields?: string;
    optionalFields?: string;
    isReadOnly?: boolean;
    isApprover?: boolean;
    isInternal?: boolean;
    canCreateAccounts?: boolean;
    allowMultipleScopes?: boolean;
    userActiveCount?: number;
}
